import React from "react";
import { useSelector } from "react-redux";

import RecommendationIcon from "../../Recommendation/RecommendationIcon";
import NotesNotif from '../NotesNotif';
import { TutorialSpot } from "../../Tutorial/index";
import {
    formatData, formatDataWithPlusSign, withCurrency, percent, getPaceRate, isNullOrUndefined,
    getPaceTitle, getAggregateCellStatus, getAggregatePaceValue, getAggregatePaceAdrValue
} from "../../../utils";
import { isNullish } from "../../../utils/data";
import useHelperFunctions from "store/actions/actions";



const AggregateCell = ({ type, data, index, ADRState, title, onClick, activeCell }) => {

    const state = useSelector((state) => state.mainReducer);
    const { calendar_data, currency, tutorial } = state;
    const { setNotesModalState } = useHelperFunctions();
    const isWeek = (type === "week");
    const isGroup = state.isGroup;

    const users = useSelector((state) => state.users);
    const userPrefs = users.authUser.prefs;
    const usePercentsForOccs = isGroup && !isNullOrUndefined(userPrefs) && (userPrefs.usePercentsForOccs ?? true);
    const isActive = activeCell && activeCell === data.key;

    let forecastColor = getAggregateCellStatus(data.color);
    let paceValue = getAggregatePaceValue(calendar_data.pace_string, data, usePercentsForOccs);
    let paceAdrValue = getAggregatePaceAdrValue(calendar_data.pace_string, data);

    let cardType = isWeek ? "weeklyCard" : "monthlyCard";
    let bottomMargin = isWeek ? "382px" : "364px";


    const tutorialSpot = (cardType, margin) => {
        if (index === 0 && tutorial[cardType].showSpot) {
            return <TutorialSpot section="overviewCalendar" type={cardType} left="-1px" bottom={margin} />
        }
    }

    const dataDiv = (style, data, usePercent = false) => {
        return <>
            <div className={style}>
                <div>
                    <span>{(isGroup && usePercent) ? `${data}%` : data}</span>
                </div>
            </div>
        </>
    }

    const rowDiv = (label, data) => {
        return <div className="aggregated-cell-row">{label}{data}</div>
    }



    return (
        <div
            className={`aggregated-cell ${!isActive? "darkened": ""} ${index === 0 && `${cardType}Tutorial`}`}
            key={index}
            onClick={onClick}
        >

            {/* Tutorial Spot */}
            {tutorialSpot(cardType, bottomMargin)}

            {/* Header with Recommendation Icon */}
            <div className="header">
                <div>{title}</div>
                {isWeek && <span className='week-header'>{`Week ${data.week}`}</span>}
                <div style={{ height: "18px" }}>
                    { calendar_data.segments.includes("ALL SEGMENTS") &&  
                        <RecommendationIcon data={data} /> 
                    }
                </div>
            </div>

            {/* Card Data */}
            <div className="aggregated-cell-column">

                {/* Pickup */}
                <div className="block">
                    {rowDiv(
                        dataDiv("hidden-label", "Pickup"),
                        dataDiv(
                            `main-data ${data.individualColor.pick_up}`,
                            formatDataWithPlusSign(usePercentsForOccs ? Math.round(data.pct_pickup) : data.pickup),
                            usePercentsForOccs
                        )
                    )}
                    {ADRState && (rowDiv(
                        dataDiv("hidden-label", "Pickup ADR"),
                        dataDiv("sub-data", withCurrency(formatData(data.pickup_adr), currency ?? data.currency))
                    ))}
                </div>

                {/* Pace */}
                <div className="block">
                    {rowDiv(
                        dataDiv("hidden-label", "Pace"),
                        dataDiv(`main-data ${data.individualColor.pace}`, paceValue, usePercentsForOccs)
                    )}
                    {ADRState && (rowDiv(
                        dataDiv("hidden-label", "Pace ADR"),
                        dataDiv("sub-data", withCurrency(paceAdrValue, currency ?? data.currency))
                    ))}
                </div>

                {/* Price */}
                <div className="block">
                    {rowDiv(
                        dataDiv("hidden-label", "Price"),
                        dataDiv(`main-data ${data.individualColor.sell_rate}`, withCurrency(formatData(
                            isNullish(data.rate) ? null : Math.round(data.rate))
                            , currency ?? data.currency))
                    )}
                    {rowDiv(
                        dataDiv("hidden-label", ` - ${calendar_data.pick_up.substring(1)} days`),
                        dataDiv("sub-data", withCurrency(formatData(
                            isNullish(data.pickup_rate) ? null : Math.round(data.pickup_rate))
                            , currency ?? data.currency))
                    )}
                    {rowDiv(
                        dataDiv("hidden-label", ` - ${getPaceTitle(calendar_data.pace_string)}`),
                        dataDiv("sub-data", getPaceRate(calendar_data.pace_string, data, currency ?? data.currency))
                    )}
                </div>

                {/* OTB */}
                <div className="block">
                    {ADRState && (rowDiv(
                        dataDiv("hidden-label", "ADR"),
                        dataDiv("sub-data", withCurrency(formatData(data.adr), currency ?? data.currency))
                    ))}
                    {rowDiv(
                        dataDiv("hidden-label", "OCC%"),
                        dataDiv("sub-data", percent(Math.round(data.pct_occ)))
                    )}
                    {rowDiv(
                        dataDiv("hidden-label", "Total Rev"),
                        dataDiv("sub-data", withCurrency(formatData(data.total_rev, 1000, true), currency ?? data.currency))
                    )}
                </div>
                
            </div>
            <div className='notes-icon'>
                { data?.note_type && <NotesNotif type={ data.note_type } style={{ marginRight: '-2px' }} onClick={(e) => {
                    e.stopPropagation();
                    setNotesModalState({ 
                        visible: true,
                        startDate: data.dateRange[0],
                        endDate: data.dateRange[1]
                    });
                }} />}
            </div>

            {/* Forecast Color */}
            <div className={`status-bar ${forecastColor}`}></div>
        </div>
    )
};

export default AggregateCell;