import React, { useEffect, useRef, useState, useMemo } from 'react';
import { AddFilled } from "Icons";
import Button from "../../Button/Button";
import DataGrid from '../../DataGrid';
import useAdminConsoleActions from "../../../store/actions/adminConsoleActions";
import FormModal from "../../FormModal/FormModal";
import usePermissions from 'hooks/usePermissions';
import IconButton from 'elements/Button/Icon';
import './IntegrationPartners.scss';

const IntegrationPartners = () => {
    const { 
        canAccess: canAccessPartner,
        canCreate: canCreatePartner,
        canUpdate: canUpdatePartner,
        canDelete: canDeletePartner
    } = usePermissions('admin_console', 'integration_partner');
    const _mode = { ADD: 'Add', EDIT: 'Edit', DELETE: 'Delete' };
    const channelsGridRef = useRef(), authGridRef = useRef(), messagesGridRef = useRef(), customFieldsGridRef = useRef(), bookingSourcesGridRef = useRef();
    const integrationPartnerModalController = FormModal.newController();
    const { errors, inputValues, onChangeHandler, onBlurHandler, validateFields } = integrationPartnerModalController;
    const [gridIPData, setGridIPData] = useState({ authentication: [], messages: [], customFields: [], bookingSources: [] });
    const [bookingSources, setBookingSources] = useState([]);
    const [gridSelections, setGridSelections] = useState({
        grid: null,
        deleteSelectedRowIdx: null
    });
    const [integrationPartnerState, setIntegrationPartnerState] = useState({
        visible: false,
        mode: _mode.ADD,
        selectedRowIdx: null
    });
    const { getChannels, getChannel, addChannel, updateChannel, deleteChannel, getBookingSources } = useAdminConsoleActions();


    const showModal = (visible, mode, rowIdx) => {
        setIntegrationPartnerState({
            ...integrationPartnerState,
            visible: visible,
            selectedRowIdx: rowIdx,
            mode: mode
        });
    }
    const closeModal = () => {
        setIntegrationPartnerState({ ...integrationPartnerState, visible: false });
    }
    const loadIntegrationPartnersGrid = () => {
        channelsGridRef.current.setLoading(true);
        getChannels().then(response => {
            const data = [];
            if (Array.isArray(response.data?.data)) {
                response.data.data.forEach(channel => {
                    data.push({
                        channel_code: channel._id,
                        channel_name: channel.name,
                        channel_status: channel.enabled
                    });
                });
            }
            channelsGridRef.current.loadData(data);
            channelsGridRef.current.setLoading(false);
        });
    }

    const submit = (e) => {
        e.preventDefault();

        if (Object.keys(errors).length > 0 || Object.keys(validateFields()).length > 0 ||
            (authGridRef.current && authGridRef.current.errors.length > 0) ||
            (messagesGridRef.current && messagesGridRef.current.errors.length > 0) ||
            (customFieldsGridRef.current && customFieldsGridRef.current.errors.length > 0) ||
            (bookingSourcesGridRef.current && bookingSourcesGridRef.current.errors.length > 0)
        ) return;

        const getAuthenticationObjs = (arrAuthentication) => {
            const authentication = {};
            if (Array.isArray(arrAuthentication)) {
                arrAuthentication.forEach(auth => {
                    switch (auth.direction) {
                        case 'INBOUND':
                        case 'OUTBOUND':
                            authentication[auth.direction.toLowerCase()] = {
                                username: auth.username,
                                password: auth.password,
                                api_key: auth.api_key
                            }
                    }
                });
            }
            return authentication;
        }

        const getEndpoints = (arrEndpoints) => {
            if (!Array.isArray(arrEndpoints)) return [];
            return arrEndpoints.map(message => ({
                direction: message.direction,
                type: message.message_type,
                operation: message.operation,
                url: message.url
            }))
        }

        let data = {};

        if (integrationPartnerState.mode != _mode.DELETE) {
            data = {
                _id: inputValues.channel_code,
                name: inputValues.channel_name,
                enabled: inputValues.enabled,
                authentication: getAuthenticationObjs(authGridRef.current.getGridData()),
                endpoints: getEndpoints(messagesGridRef.current.getGridData()),
                custom_fields: customFieldsGridRef.current.getGridData(),
                booking_sources: bookingSourcesGridRef.current.getGridData()
            }
        }

        const doneSubmit = (result, type) => {
            if (result.type == type) {
                integrationPartnerModalController.show(false);
                loadIntegrationPartnersGrid();
            }
        }
        switch (integrationPartnerState.mode) {
            case _mode.ADD:
                addChannel(data).
                    then(result => doneSubmit(result, 'ADD_CHANNEL_SUCCESS'));
                break;
            case _mode.EDIT:
                updateChannel(channelsGridRef.current.getGridData(integrationPartnerState.selectedRowIdx).channel_code, data).
                    then(result => doneSubmit(result, 'UPDATE_CHANNEL_SUCCESS'));
                break;
            case _mode.DELETE:
                deleteChannel(channelsGridRef.current.getGridData(integrationPartnerState.selectedRowIdx).channel_code).
                    then(result => doneSubmit(result, 'DELETE_CHANNEL_SUCCESS'));
                break;
        }
    }

    useEffect(() => {
        const getAuthenticationArr = (objs) => {
            if (typeof objs !== 'object') return [];
            return Object.keys(objs).map(key => ({ ...objs[key], direction: key.toUpperCase() }));
        }
        if (integrationPartnerState.visible === true) {
            setGridIPData({ authentication: [], messages: [], customFields: [], bookingSources: [] });
            if ([_mode.EDIT, _mode.DELETE].includes(integrationPartnerState.mode)) {
                let integrationPartner = channelsGridRef.current.getGridData(integrationPartnerState.selectedRowIdx);
                getChannel(integrationPartner.channel_code).then(result => {
                    const info = result.data.data;
                    integrationPartnerModalController.show(true, {
                        channel_code: info._id,
                        channel_name: info.name,
                        enabled: info.enabled
                    });
                    if (_mode.EDIT == integrationPartnerState.mode) {
                        setGridIPData({
                            authentication: getAuthenticationArr(info.authentication),
                            messages: info.endpoints,
                            customFields: info.custom_fields,
                            bookingSources: info.booking_sources
                        });
                    }
                });
            } else {
                integrationPartnerModalController.show(true);
            }
        } else {
            integrationPartnerModalController.show(false);
        }
    }, [integrationPartnerState]);

    useEffect(() => {
        if (gridSelections.deleteSelectedRowIdx > -1) {
            switch (gridSelections.grid) {
                case 'authentication':
                    authGridRef.current.loadData(authGridRef.current.getGridData()
                        .filter((row, index) => index != gridSelections.deleteSelectedRowIdx)
                    );
                    break;
                case 'messages':
                    messagesGridRef.current.loadData(messagesGridRef.current.getGridData()
                        .filter((row, index) => index != gridSelections.deleteSelectedRowIdx)
                    );
                    break;
                case 'custom_fields':
                    customFieldsGridRef.current.loadData(customFieldsGridRef.current.getGridData()
                        .filter((row, index) => index != gridSelections.deleteSelectedRowIdx)
                    );
                    break;
                case 'booking_source':
                    bookingSourcesGridRef.current.loadData(bookingSourcesGridRef.current.getGridData()
                        .filter((row, index) => index != gridSelections.deleteSelectedRowIdx)
                    );
                    break;
            }
        }
    }, [gridSelections]);

    useEffect(() => {
        loadIntegrationPartnersGrid();
        getBookingSources().then(result => {
            if (Array.isArray(result.data?.data)) {
                setBookingSources(result.data.data.map(source => ({
                    id: source._id,
                    displayText: source.source
                })));
            }
        });
    }, []);

    const showSubmit = useMemo(() => {
        if (integrationPartnerState.mode === _mode.ADD && canCreatePartner()) return true;
        if (integrationPartnerState.mode === _mode.EDIT && canUpdatePartner()) return true;
        if (integrationPartnerState.mode === _mode.DELETE && canDeletePartner()) return true;
        return false;
    }, [integrationPartnerState.mode]);

    return (
        <div className="admin-integration-partners">
            <div className="add-channel" style={{ display: 'flex', marginBottom: '10px' }}>
                <h4 style={{ color: 'white', marginRight: '10px' }}>Add Channel</h4>
                <IconButton type='add' hasAccess={canCreatePartner()} action={() => showModal(true, _mode.ADD)} width='25px' />
            </div>
            <DataGrid
                ref={channelsGridRef}
                columns={[{
                    id: 'channel_name',
                    header: 'CHANNEL NAME',
                    width: 'minmax(220px, auto)'
                }, {
                    id: 'channel_code',
                    header: 'CHANNEL CODE',
                    width: 'minmax(220px, auto)'
                }, {
                    id: 'channel_status',
                    header: 'CHANNEL STATUS',
                    width: 'minmax(220px, auto)',
                    render: value => value === true ? 'Active' : 'Inactive'
                }, {
                    id: 'actions',
                    header: 'ACTIONS',
                    type: 'custom_actions',
                    actions: [{
                        render: rowIdx => <IconButton type='edit' hasAccess={canAccessPartner()} action={() => {
                            showModal(true, _mode.EDIT, rowIdx);
                        }} />
                    }, {
                        render: rowIdx => <IconButton type='delete' hasAccess={canDeletePartner()} action={() => {
                            showModal(true, _mode.DELETE, rowIdx);
                        }} />
                    }],
                    width: '54px'
                }]}
                width="800px"
            />
            <FormModal title={`${integrationPartnerState.mode} Channel`} onSubmit={submit}
                width={integrationPartnerState.mode === _mode.DELETE ? '530px' : '700px'} maskClosable={false}
                onClose={() => { closeModal(); }}
                controller={integrationPartnerModalController}
            >
                {(integrationPartnerState.mode === _mode.ADD || integrationPartnerState.mode === _mode.EDIT) && (<>
                    <div className="single-row-input">
                        <div className="light-input-container" style={{ width: '320px' }}>
                            <FormModal.Field type="textbox" label="Channel Name"
                                name="channel_name" value={inputValues.channel_name} error={errors.channel_name}
                                validations={{ required: true, characters: 'alphanumeric_with_space' }}
                                onChange={onChangeHandler} onBlur={onBlurHandler} />
                        </div>
                        <div className="light-input-container">
                            <FormModal.Field type="textbox" label="Channel Code"
                                name="channel_code" value={inputValues.channel_code} error={errors.channel_code} disabled={integrationPartnerState.mode === _mode.EDIT}
                                validations={{ required: true, characters: 'alphanumeric' }}
                                onChange={onChangeHandler} onBlur={onBlurHandler} />
                        </div>
                        <div className="light-input-container" style={{ display: 'flex', height: '59px', alignItems: 'flex-end', paddingBottom: '6px' }}>
                            <div className="form-modal-field-status">
                                <FormModal.Field type="switchonly" label="Status"
                                    name="enabled" value={inputValues.enabled} error={errors.enabled}
                                    onChange={onChangeHandler} onBlur={onBlurHandler} />
                            </div>
                        </div>
                    </div>
                    <div className="admin-console-channel-modal-grid">
                        <span className="grid-header">AUTHENTICATION</span>
                        <DataGrid
                            ref={authGridRef}
                            headerHeight="36px"
                            emptyRowContent={null}
                            dividerHeight="4px"
                            columns={[{
                                id: 'direction',
                                header: 'DIRECTION',
                                type: 'dropdown',
                                items: [{
                                    id: 'INBOUND',
                                    displayText: 'Inbound'
                                }, {
                                    id: 'OUTBOUND',
                                    displayText: 'Outbound'
                                }],
                                useDisplayField: false,
                                validations: [{
                                    type: 'required',
                                    value: true
                                }]
                            }, {
                                id: 'username',
                                header: 'USERNAME',
                                type: 'text',
                                useDisplayField: false,
                                validations: [{
                                    type: 'required',
                                    value: true
                                }, {
                                    type: 'no_whitespace'
                                }]
                            }, {
                                id: 'password',
                                header: 'PASSWORD',
                                type: 'text',
                                useDisplayField: false,
                                validations: [{
                                    type: 'required',
                                    value: true
                                }, {
                                    type: 'no_whitespace'
                                }]
                            }, {
                                id: 'api_key',
                                header: 'API KEY',
                                type: 'text',
                                useDisplayField: false,
                                validations: [{
                                    type: 'no_whitespace'
                                }]
                            }, {
                                id: 'actions',
                                type: 'action_delete',
                                width: '20px',
                                actionFn: {
                                    onDelete: (rowIdx) => {
                                        setGridSelections({ grid: 'authentication', deleteSelectedRowIdx: rowIdx });
                                    }
                                }
                            }]}
                            data={gridIPData.authentication} />
                        <div className="add-authentication" style={{ display: 'flex', marginBottom: '10px' }}>
                            <span style={{ color: '#1e2147', marginRight: '6px' }}>Add authentication</span>
                            <AddFilled
                                onClick={() => { authGridRef.current.addNewData([]) }}
                                width="20px"
                                height="20px"
                                className="pointer"
                            />
                        </div>
                    </div>
                    <div className="admin-console-channel-modal-grid">
                        <span className="grid-header">MESSAGES</span>
                        <DataGrid
                            ref={messagesGridRef}
                            headerHeight="36px"
                            emptyRowContent={null}
                            dividerHeight="4px"
                            columns={[{
                                id: 'message_type',
                                header: 'MESSAGE TYPE',
                                dataField: 'type',
                                type: 'dropdown',
                                items: [{
                                    id: 'RES',
                                    displayText: 'Reservations'
                                }, {
                                    id: 'BAR',
                                    displayText: 'BAR'
                                }, {
                                    id: 'OCC',
                                    displayText: 'Availability'
                                }, {
                                    id: 'RATE',
                                    displayText: 'Rates'
                                }],
                                useDisplayField: false,
                                validations: [{
                                    type: 'required',
                                    value: true
                                }]
                            }, {
                                id: 'operation',
                                header: 'OPERATION',
                                type: 'dropdown',
                                items: [{
                                    id: 'PULL',
                                    displayText: 'Pull'
                                }, {
                                    id: 'PUSH',
                                    displayText: 'Push'
                                }],
                                useDisplayField: false,
                                validations: [{
                                    type: 'required',
                                    value: true
                                }]
                            }, {
                                id: 'direction',
                                header: 'DIRECTION',
                                type: 'dropdown',
                                items: [{
                                    id: 'INBOUND',
                                    displayText: 'Inbound'
                                }, {
                                    id: 'OUTBOUND',
                                    displayText: 'Outbound'
                                }],
                                useDisplayField: false,
                                validations: [{
                                    type: 'required',
                                    value: true
                                }]
                            }, {
                                id: 'url',
                                header: 'URL',
                                type: 'text',
                                useDisplayField: false,
                                validations: [{
                                    type: 'required',
                                    value: true
                                }, {
                                    type: 'url'
                                }]
                            }, {
                                id: 'actions',
                                type: 'action_delete',
                                width: '20px',
                                actionFn: {
                                    onDelete: (rowIdx) => {
                                        setGridSelections({ grid: 'messages', deleteSelectedRowIdx: rowIdx });
                                    }
                                }
                            }]}
                            data={gridIPData.messages} />
                        <div className="add-message-type" style={{ display: 'flex', marginBottom: '10px' }}>
                            <span style={{ color: '#1e2147', marginRight: '6px' }}>Add message type</span>
                            <AddFilled
                                onClick={() => { messagesGridRef.current.addNewData({}); }}
                                width="20px"
                                height="20px"
                                className="pointer"
                            />
                        </div>
                    </div>
                    <div className="admin-console-channel-modal-grid">
                        <span className="grid-header">CUSTOM FIELDS</span>
                        <DataGrid
                            ref={customFieldsGridRef}
                            headerHeight="36px"
                            emptyRowContent={null}
                            dividerHeight="4px"
                            columns={[{
                                id: 'name',
                                header: 'DATA LABEL',
                                type: 'text',
                                useDisplayField: false,
                                validations: [{
                                    type: 'required',
                                    value: true
                                }]
                            }, {
                                id: 'value',
                                header: 'VALUE',
                                type: 'text',
                                useDisplayField: false,
                                validations: [{
                                    type: 'required',
                                    value: true
                                }]
                            }, {
                                id: 'actions',
                                type: 'action_delete',
                                width: '20px',
                                actionFn: {
                                    onDelete: (rowIdx) => {
                                        setGridSelections({ grid: 'custom_fields', deleteSelectedRowIdx: rowIdx });
                                    }
                                }
                            }]}
                            data={gridIPData.customFields} />
                        <div className="add-custom-field" style={{ display: 'flex', marginBottom: '10px' }}>
                            <span style={{ color: '#1e2147', marginRight: '6px' }}>Add custom field</span>
                            <AddFilled
                                onClick={() => { customFieldsGridRef.current.addNewData({}); }}
                                width="20px"
                                height="20px"
                                className="pointer"
                            />
                        </div>
                    </div>
                    <div className="admin-console-channel-modal-grid">
                        <span className="grid-header">BOOKING SOURCES</span>
                        <DataGrid
                            ref={bookingSourcesGridRef}
                            headerHeight="36px"
                            emptyRowContent={null}
                            dividerHeight="4px"
                            columns={[{
                                id: 'booking_source_id',
                                header: 'BOOKING SOURCE',
                                type: 'dropdown',
                                useDisplayField: false,
                                items: bookingSources,
                                validations: [{
                                    type: 'required',
                                    value: true
                                }]
                            }, {
                                id: 'channel_source_id',
                                header: 'BOOKING SOURCE CODE',
                                type: 'text',
                                useDisplayField: false,
                                validations: [{
                                    type: 'required',
                                    value: true
                                }]
                            }, {
                                id: 'actions',
                                type: 'action_delete',
                                width: '20px',
                                actionFn: {
                                    onDelete: (rowIdx) => {
                                        setGridSelections({ grid: 'booking_source', deleteSelectedRowIdx: rowIdx });
                                    }
                                }
                            }]}
                            data={gridIPData.bookingSources} />
                        <div className="add-booking-source" style={{ display: 'flex', marginBottom: '10px' }}>
                            <span style={{ color: '#1e2147', marginRight: '6px' }}>Add booking source</span>
                            <AddFilled
                                onClick={() => { bookingSourcesGridRef.current.addNewData({}); }}
                                width="20px"
                                height="20px"
                                className="pointer"
                            />
                        </div>
                    </div>
                </>)}
                {integrationPartnerState.mode === _mode.DELETE && (<>
                    <div className="light-form delete-segment">
                        <center>
                            <p>Deleting this channel will remove the item from the predefined list for channel and remove all mappings associated with it.</p>
                            <p>Are you sure you want to delete <b>{channelsGridRef.current.getGridData([integrationPartnerState.selectedRowIdx])?.channel_name}</b>?</p>
                        </center>
                    </div>
                </>)}
                <div className="light-form-buttons" >
                    <Button type="bg-light" style={{ marginRight: '8px' }} onClick={() => { closeModal(); }}>{showSubmit? 'Cancel': 'Close'}</Button>
                    {showSubmit && <Button btnType="submit" type="primary" onClick={(e) => submit(e)}>
                        {integrationPartnerState.mode === _mode.DELETE ? 'DELETE' : 'Submit'}
                    </Button>}
                </div>
            </FormModal>
        </div>
    )
}

export default React.memo(IntegrationPartners);