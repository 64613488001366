import React, { useEffect, useState, useRef } from 'react';
// import moment from 'moment';
import dayjs from 'dayjs';
import DataGrid from '../../DataGrid';
import { Select, TimePicker, DatePicker } from 'antd';
import { messageTypeList, messageStatusList } from './items';
import useAdminConsoleActions from "../../../store/actions/adminConsoleActions";
import usePropertiesActions from "../../../store/actions/propertiesActions";
import './MessageLogs.scss';
import FormModal from '../../FormModal/FormModal';
import CustomTabs from '../../CustomTabs/CustomTabs';
import usePermissions from 'hooks/usePermissions';

const MessageLogs = () => {
    const { canAccess: canAccessMessageLog } = usePermissions('admin_console', 'message_log');
    const messagesGridRef = useRef();
    const endDateTime = dayjs();
    const startDateTime = dayjs(endDateTime).subtract(1, 'hour');
    const messageViewerController = FormModal.newController();
    const { rawData: formRawData } = messageViewerController;

    const { getChannels, getMessageLogs, getMessageLog } = useAdminConsoleActions();
    const { getProperties } = usePropertiesActions();
    const [integrationPartnerList, setIntegrationPartnerList] = useState([]);
    const [propertyList, setPropertyList] = useState([]);
    const [messageViewerModalState, setMessageViewerModalState] = useState({
        
    });
    const [filterPanelValues, setFilterPanelValues] = useState({
        page: 1,
        sort: '-received_on',
        start_date_time: startDateTime,
        end_date_time: endDateTime,
    });
    const showModal = (visible, rowIdx) => {
        setMessageViewerModalState({...messageViewerModalState, 
            visible: visible,
            selectedRowIdx: rowIdx
        });
    }
    const closeModal = () => {
        setMessageViewerModalState({...messageViewerModalState, 
            visible: false
        });
    }
    useEffect(() => {
        getChannels().then(result => {
            const data = result.data.data;
            if (Array.isArray(data)) {
                setIntegrationPartnerList(data.map(integrationPartner => ({
                    id: integrationPartner._id, 
                    displayText: integrationPartner.name
                })));
            }
        });
        getProperties().then(result => {
            const data = result.response.data.data;
            if (Array.isArray(data)) {
                setPropertyList(data.map(property => ({
                    id: property._id, 
                    displayText: property.name
                })));
            }
        });
        loadMessageLogsGrid();
    }, []);
    useEffect(() => {
        if (messageViewerModalState.visible === true) {
            let data = messagesGridRef.current.getGridData(messageViewerModalState.selectedRowIdx);
            if (data.message_id) {
                getMessageLog(data.message_id).then(result => {
                    const data = result.data.data;
                    // console.log(JSON.stringify(JSON.parse(data.body), null, 4));
                    messageViewerController.show(true, data);
                });
            }
        } else {
            messageViewerController.show(false);
        }
    }, [messageViewerModalState])
    const loadMessageLogsGrid = (increment = false) => {
        if (!increment) messagesGridRef.current.setLoading(true);
        getMessageLogs(getMessageLogsParams(increment)).then(result => {
            if (!Array.isArray(result.data.data)) {
                if (!increment) messagesGridRef.current.loadData([]);
            } else {
                const data = result.data.data.map(log => {
                    log.type = getMessageTypeName(log.type);
                    return log;
                });
                if (increment) messagesGridRef.current.loadData([...messagesGridRef.current.getRawData(), ...data]);                
                else messagesGridRef.current.loadData(data);
            }
            messagesGridRef.current.setLoading(false);
        });
    }
    const getMessageLogsParams = (increment) => {
        let page = increment? filterPanelValues.page + 1 : 1; 

        setFilterPanelValues({...filterPanelValues, page: page});
        return {
            start_date_time: typeof filterPanelValues.start_date_time == 'object'? filterPanelValues.start_date_time.format(): undefined,
            end_date_time: typeof filterPanelValues.end_date_time == 'object'? filterPanelValues.end_date_time.format(): undefined,
            status: filterPanelValues.message_status || undefined,
            type: filterPanelValues.message_type || undefined,
            property_id: filterPanelValues.property_id || undefined,
            channel_code: filterPanelValues.channel_code || undefined,
            sort: filterPanelValues.sort || undefined,
            page: page,
            limit: 20
        };
    }
    const onChange = (key, value) => {
        setFilterPanelValues({...filterPanelValues, [key]: value});
    }
    const submit = (e) => {
        e.preventDefault();
        loadMessageLogsGrid();
    }
    const loadMoreLogs = () => {
        loadMessageLogsGrid(true);
    }
    const getMessageTypeName = (type_id) => {
        let typeIndex = messageTypeList.findIndex(type => type_id == type.id);
        if (typeIndex > -1) return messageTypeList[typeIndex].displayText;
        return '';
    }
    const StaticField = ({label, value}) => {
        return <>
            <div>{label}:</div><div style={{fontWeight: '800'}}>{value}</div>
        </>;
    }
    return (<div className="admin-message-logs">
        <div style={{display: 'grid', gridTemplateRows: '70px calc(100vh - 310px) 40px', borderBottom: '1px solid white'}}>
            <form className="message-logs-filter-panel" onSubmit={submit}>
                <span>Property name</span>
                <Select style={{color: "#51b68a"}} placeholder="ALL" name="property_id" value={filterPanelValues.property_id} onChange={(val) => onChange("property_id", val)} 
                    optionFilterProp="children" size="small" allowClear showSearch >
                    {propertyList.map(item => {
                        return <Select.Option key={item.id}>{item.displayText}</Select.Option>
                    })}
                </Select>

                <span>Message type</span>
                <Select style={{color: "#51b68a"}} placeholder="ALL" name="message_type" value={filterPanelValues.message_type} onChange={(val) => onChange("message_type", val)} 
                    optionFilterProp="children" size="small" allowClear >
                    {messageTypeList.map(item => {
                        return <Select.Option key={item.id}>{item.displayText}</Select.Option>
                    })}
                </Select>

                <span>Start date</span>
                <DatePicker className="message-logs-filter-start-date" value={filterPanelValues.start_date_time} onChange={(val) => onChange("start_date_time", val)} 
                    format="MMMM DD, YYYY" size="small" suffixIcon={<></>} allowClear={false} disabledDate={currentDate => {
                        return filterPanelValues.end_date_time < currentDate;
                    }} />

                <span>Start time</span>
                <TimePicker className="message-logs-filter-start-time" value={filterPanelValues.start_date_time} onChange={(val) => onChange("start_date_time", val)} 
                    suffixIcon={<></>} allowClear={false} />
                
                <div style={{gridRow: 'span 2', alignSelf: 'center'}}><button className="apply-filters-btn">APPLY FILTERS</button></div>
                
                <span>Channel name</span>
                <Select style={{color: "#51b68a"}} placeholder="ALL" name="channel_code" value={filterPanelValues.channel_code} onChange={(val) => onChange("channel_code", val)} 
                    optionFilterProp="children" size="small" allowClear showSearch >
                    {integrationPartnerList.map(item => {
                        return <Select.Option key={item.id}>{item.displayText}</Select.Option>
                    })}
                </Select>
                
                <span>Message status</span>
                <Select style={{color: "#51b68a"}} placeholder="ALL" name="message_status" value={filterPanelValues.message_status} onChange={(val) => onChange("message_status", val)} 
                    optionFilterProp="children" size="small" allowClear >
                    {messageStatusList.map(item => {
                        return <Select.Option key={item.id}>{item.displayText}</Select.Option>
                    })}
                </Select>
                
                <span>End date</span>
                <DatePicker className="message-logs-filter-end-date" value={filterPanelValues.end_date_time} onChange={(val) => onChange("end_date_time", val)} 
                    format="MMMM DD, YYYY" size="small" suffixIcon={<></>} allowClear={false} disabledDate={currentDate => {
                        return filterPanelValues.start_date_time > currentDate;
                    }} />
                
                <span>End time</span>
                <TimePicker className="message-logs-filter-end-time" value={filterPanelValues.end_date_time} onChange={(val) => onChange("end_date_time", val)} 
                    suffixIcon={<></>} allowClear={false} />
            </form>
            <DataGrid 
                ref={messagesGridRef}
                style={{height: '100%', width: '100%', overflow: 'auto'}}
                columns={[{
                    id: 'message_id',
                    header: 'MESSAGE ID',
                    dataField: '_id',
                    width: 'minmax(240px, auto)',
                    defaultValue: 'N/A'
                }, {
                    id: 'channel_name',
                    header: 'CHANNEL NAME',
                    dataField: 'processor',
                    defaultValue: 'N/A'
                }, {
                    id: 'message_type',
                    header: 'MESSAGE TYPE',
                    dataField: 'type',
                    defaultValue: 'N/A'
                }, {
                    id: 'message_status',
                    header: 'MESSAGE STATUS',
                    type: 'link',
                    dataField: 'status',
                    linkFn: {
                        onClick: function(colIdx, rowIdx) {
                            canAccessMessageLog() && showModal(true, rowIdx);
                        }
                    },
                    defaultValue: 'N/A'
                }, {
                    id: 'property_name',
                    header: 'PROPERTY NAME',
                    defaultValue: 'N/A'
                }, {
                    id: 'message_timestamp',
                    header: 'MESSAGE TIMESTAMP',
                    dataField: 'received_on',
                    width: 'minmax(0, auto)',
                    defaultValue: 'N/A',
                    render: val => val ? dayjs(val).format("YYYY-MM-DD HH:mm:ssZ") : ''
                }]}
            />
            <button className="load-more-btn" onClick={loadMoreLogs}>LOAD MORE LOG ENTRIES</button>
        </div>
        <FormModal title="Message Viewer" onSubmit={submit} 
                width="1000px" maskClosable={true} 
                onClose={() => { closeModal(); }}
                controller={messageViewerController}
        >
            <div style={{display: 'grid', gridTemplateColumns: '140px 270px 100px 200px', gridTemplateRow: 'repeat(10px, 2) auto'}}>
                <StaticField label="Message ID" value={formRawData._id}></StaticField>
                <StaticField label="Channel name" value={formRawData.channel_name}></StaticField>
                <StaticField label="Message timestamp" value={formRawData.received_on}></StaticField>
                <StaticField label="Property name" value={formRawData.property_name}></StaticField>
                <StaticField label="Message type" value={getMessageTypeName(formRawData.type)}></StaticField>
            </div>
            <CustomTabs className="custom-tab-message-viewer" tabs={[
                { key: 'body', label: 'BODY' },
                { key: 'processing', label: 'PROCESSING' }
            ]}>
                <CustomTabs.Tab key="body">
                    <div style={{display: 'grid', gridTemplateRows: 'auto 20px', minHeight: '400px'}}>
                        <div className="message-viewer-body-content">
                            {formRawData.body}
                        </div>
                        <div style={{display: 'flex', justifyContent: 'flex-end', height: '20px'}}>
                            <button className="btn-copy-body" type="button" onClick={() => { navigator.clipboard.writeText(formRawData.body)}}>{"Copy"}</button>
                        </div>
                    </div>
                </CustomTabs.Tab>
                <CustomTabs.Tab key="processing">
                    <div className="message-viewer-body-content">
                        <table style={{height: '100%', width: '100%'}}>
                            <thead>
                                <tr>
                                    <th style={{width: '100px'}}>ATTEMPT</th>
                                    <th style={{width: '130px'}}>TIMESTAMP</th>
                                    <th style={{width: '100px'}}>STATUS</th>
                                    <th style={{width: '200px'}}>MESSAGE</th>
                                    <th style={{width: 'auto'}}>STACK</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(formRawData.processing) && formRawData.processing.map((row, rowIdx) => {
                                    if (Array.isArray(row.warnings)) {
                                        return <>
                                            <tr>
                                                <td rowSpan={row.warnings.length}>{rowIdx+1}</td>
                                                <td rowSpan={row.warnings.length}>{row.attempted_on}</td>
                                                <td rowSpan={row.warnings.length}>{row.status}</td>
                                                <td>{row.warnings[0]?.message}</td>
                                                <td>{row.warnings[0]?.stack}</td>
                                            </tr>
                                            {row.warnings.map((warning, index) => {
                                                if (index === 0) return <></>;
                                                return <tr>
                                                    <td>{warning.message}</td>
                                                    <td>{warning.stack}</td>
                                                </tr>
                                            })}
                                        </>
                                    }
                                    return <tr>
                                        <td>{rowIdx+1}</td>
                                        <td>{row.attempted_on}</td>
                                        <td>{row.status}</td>
                                        <td>{row.error?.message}</td>
                                        <td>{row.error?.stack}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </CustomTabs.Tab>
            </CustomTabs>
        </FormModal>
    </div>);

}

export default React.memo(MessageLogs);