import React from "react";
import PropTypes from "prop-types";
import { Collapse } from "antd";
import OutlinedIcon from "elements/Icon/OutlinedIcon";

const Accordion = ({
    items,
    defaultActiveKey,
    onChange,
    accordion = false,
    headerClassname,
    collapse,
    activeKey
}) => {

    const header = (customHeader) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h3 style={{ margin: 0 }} className={headerClassname}>{customHeader}</h3>
            <OutlinedIcon type='right'/>
        </div>
    );

    return (
        <div className="rms-accordion">
            <Collapse
                accordion={accordion}
                bordered={false}
                defaultActiveKey={collapse ? defaultActiveKey : ""}
                onChange={onChange}
                style={{ color: "#fff" }}
                activeKey={activeKey}
                items={items.filter(item => (item.visible?? true) === true).map((item) => {
                    return {
                        key: item.key,
                        showArrow: item.showArrow || false,
                        label: item.header? item.header: header(item.label || ""),
                        children: item.content(),
                        forceRender: true
                    }
                })}
            />
        </div>
    );
};

Accordion.proptype = {
    items: PropTypes.array.isRequired,
    defaultActiveKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    accordion: PropTypes.bool,
    headerClassname: PropTypes.string,
    collpase: PropTypes.bool,
};

export default Accordion;
