import { useSelector } from 'react-redux';
import { getPermissions } from 'utils/permissions';

const usePermissions = (...module) => {
    const { authUser } = useSelector(state => state.users);

    const permissions = getPermissions(authUser);

    const canAccess = (...resource) => {
        const params = [...module, ...resource];
        return permissions.canAccess(...params);
    };

    const canView = (...resource) => {
        const params = [...module, ...resource];
        return permissions.canView(...params);
    };

    const canCreate = (...resource) => {
        const params = [...module, ...resource];
        return permissions.canCreate(...params);
    };

    const canUpdate = (...resource) => {
        const params = [...module, ...resource];
        return permissions.canUpdate(...params);
    };

    const canDelete = (...resource) => {
        const params = [...module, ...resource];
        return permissions.canDelete(...params);
    };

    const isAllowed = (key, all = true) => {
        return permissions.isAllowed(key, all);
    };
    
    return {
        list: permissions.list,
        constants: permissions.constants,
        isAllowed,
        canAccess,
        canView,
        canCreate,
        canUpdate,
        canDelete
    }
}

export default usePermissions;