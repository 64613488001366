import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { ToastsStore } from "react-toasts";
import { Modal, Select } from 'antd';
import { Exit } from "Icons";
import Button from "../Button/Button";
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import usePropertiesActions from "store/actions/propertiesActions";
import { isNullOrUndefined } from "../../utils/index";
import useAdminConsoleActions from 'store/actions/adminConsoleActions';
import useModal from 'hooks/useModal';

const { Option } = Select;
// Can use useForm hook as replacement for useModal hook.
const SegmentModal = ({ visible, onClose, segmentState, segmentInput }) => {
    const { getSegments } = useAdminConsoleActions();
    const { getProperty, getPropertySegment, addPropertySegment, updatePropertySegment, deletePropertySegment, addPropertySegmentGroup } = usePropertiesActions();
    const property = useSelector(state => state.properties.property);
    const user = useSelector(state => state.users.authUser);

    const [segments, setSegments] = useState([]);
    const [loading, setLoading] = useState(false);

    const propertySegmentModal = useModal({
        global_code: { default: '', validations: { charLength: 10, required: true } },
        segment_code: { default: '', validations: { charLength: 10, required: true } },
        segment_name: { default: '', validations: { charLength: 50, required: true } },
        segment_group_code: { default: '', validations: { required: true } },
        description: { default: '' },
    }, ({ mode, values, editedValues }) => {

        const filter = {
            property_id: property._id,
            segment_code: segmentInput?.local?.code,
        }

        // const data = { 
        //     global_code: values.global_code,
        //     segment_name: values.segment_name,
        //     segment_code: values.segment_code,
        //     description: values.description,
        //     segment_group_code: values.segment_group_code
        // }

        const data = { ...editedValues };

        if (mode === 'ADD') {
            setLoading(true);
            addPropertySegment(filter.property_id, data).then(result => {
                if (result.type === 'ADD_PROPERTY_SEGMENT_SUCCESS') {
                    onClose();
                    ToastsStore.success(`Segment: ${data.segment_code} was successfuly added`);
                } else {
                    ToastsStore.error(result.error.response.data.message);
                }
            }).catch(error => {
                ToastsStore.error(error.response.data.message)
            }).finally(() => {
                getProperty(property._id);
                setLoading(false);
            });
        }

        if (mode === 'EDIT') {
            setLoading(true);
            updatePropertySegment(filter.property_id, filter.segment_code, data).then(result => {
                if (result.type === 'UPDATE_PROPERTY_SEGMENT_SUCCESS') {
                    onClose();
                    ToastsStore.success(`Segment: ${filter.segment_code} was successfuly updated`);
                } else {
                    ToastsStore.error(result.error.response.data.message);
                }
            }).catch(error => {
                ToastsStore.error(error.response.data.message)
            }).finally(() => {
                getProperty(property._id);
                setLoading(false);
            });
        }
    });

    const deletePropertySegmentModal = useModal({
        segment_code: { default: '', validations: { custom: ({ rawValues, touchedValues }) => {
            if (rawValues.segment_code !== touchedValues.segment_code) {
                return "Segment is not correct";
            }
            return true;
        }}}
    }, ({ values }) => {
        deletePropertySegment(property._id, values.segment_code).then(response => {
            if (!response.error) {
                onClose();
                ToastsStore.success("Success!");
            } else {
                ToastsStore.error("Unable to process request");
            }
        }).catch(error => {
            ToastsStore.error(error.response.data.message)
        }).finally(() => {
            getProperty(property._id);
            setLoading(false);
        });
    });

    const propertySegmentGroupModal = useModal({
        segment_group_code: { default: '', validations: { required: true } },
        segment_group_name: { default: '', validations: { required: true } }
    }, ({ mode, values, editedValues }) => {
        addPropertySegmentGroup(property._id, {
            segment_group_code: values['segment_group_code'],
            segment_group_name: values['segment_group_name']
        }).then(result => {
            if (result.type === 'ADD_PROPERTY_SEGMENT_GROUP_SUCCESS') {
                propertySegmentGroupModal.hide();
                ToastsStore.success(`Segment group: ${values['segment_group_code']} was successfuly added`);
            } else {
                ToastsStore.error("Unable to process request");
            }
        }).catch(error => {
            ToastsStore.error(error.response.data.message)
        }).finally(() => {
            getProperty(property._id);
            setLoading(false);
        });
    });
   
    const globalSegmentModal = useModal({
        segment_code: { default: '' },
        segment_name: { default: '' } 
    });

    useEffect(() => {
        if (visible === true) {
            // Load RevAndYou Segments
            getSegments().then((response) => setSegments(response.data.data));
            if (segmentState === 'add') {
                propertySegmentModal.show({ mode: 'ADD' });
            }
            if (segmentState === 'edit' || segmentState === 'view') {
                setLoading(true);
                propertySegmentModal.show({ mode: segmentState === 'edit'? 'EDIT': 'VIEW' });
                propertySegmentModal.clearData();
                getPropertySegment(property._id, segmentInput?.local?.code?? "").then(result => {
                    if (result.type === 'GET_PROPERTY_SEGMENT_SUCCESS') {
                        const segment = result.response.data.data;
                        propertySegmentModal.setRawValues({
                            segment_code: segment.local.code,
                            segment_name: segment.local.name,
                            global_code: segment.global,
                            segment_group_code: segment.local.gc,
                            description: segment.local.description
                        });
                    } else {
                        onClose();
                        ToastsStore.error(result.error.response.data.message);
                    }
                }).catch(error => {
                    ToastsStore.error(error.response.data.message);
                }).finally(() => setLoading(false));
            }
            if (segmentState === 'delete') {
                setLoading(true);
                deletePropertySegmentModal.show({ mode: 'EDIT', data: {} });
                getPropertySegment(property._id, segmentInput?.local?.code?? "").then(result => {
                    if (result.type === 'GET_PROPERTY_SEGMENT_SUCCESS') {
                        const segment = result.response.data.data;
                        deletePropertySegmentModal.setRawValues({
                            segment_code: segment.local.code,
                            segment_name: segment.local.name,
                            global_code: segment.global,
                            segment_group_code: segment.local.gc,
                            description: segment.local.description
                        });
                    } else {
                        onClose();
                        ToastsStore.error(result.error.response.data.message);
                    }
                }).catch(error => {
                    ToastsStore.error(error.response.data.message);
                }).finally(() => setLoading(false));
            }
        } else {
            if (['add', 'view', 'edit'].includes(segmentState)) {
                propertySegmentModal.hide();
            } else {
                deletePropertySegmentModal.hide();
            }
        }
    }, [visible, segmentState]);

    const renderPropertySegmentModal = () => {
        const showModal = propertySegmentModal.visible && propertySegmentModal.mode !== 'DELETE';
        return <Modal
            open={showModal} width={500} maskClosable={false} footer={null}
            closeIcon={<Exit width="25px" height="25px" className="pointer" />}
            onCancel={() => onClose() }
        >
            <div className="rms-modal-content">
                {globalSegmentModal.visible && <div className="rms-mask"></div>}
                <div className="rms-modal-header">
                    {propertySegmentModal.mode === 'ADD' && <h3>Add Segment</h3>}
                    {propertySegmentModal.mode === 'VIEW' && <h3>Segment Details</h3>}
                    {propertySegmentModal.mode === 'EDIT' && <h3>Edit Segment</h3>}
                    {propertySegmentModal.mode === 'DELETE' && <h3>Segment Details</h3>} 
                </div>
                <form className="light-form">
                    <div className="single-row-input">
                        <div style={{ flexGrow: 2, marginRight: '8px' }} className="light-input-container">
                            <div className="light-label">
                                Segment Name
                            </div>
                            <input className={['light-input', ...[propertySegmentModal.mode === 'VIEW' && 'disabled']].join(' ')} disabled={propertySegmentModal.mode === 'VIEW'}
                                name="name" type="text" value={propertySegmentModal.values['segment_name']?? ""}
                                onChange={(e) => propertySegmentModal.handleValueChanged('segment_name', e.target.value)} 
                                onBlur={(e) => propertySegmentModal.handleValueChanged('segment_name', e.target.value)} />
                            {propertySegmentModal.errors['segment_name'] && <div className="light-input-error">
                                {propertySegmentModal.errors['segment_name']}
                            </div>}
                        </div>

                        <div className="light-input-container">
                            <div className="light-label">
                                Segment Code
                            </div>
                            <input className="light-input disabled" disabled={propertySegmentModal.mode === 'VIEW'}
                                name="code" type="text" value={propertySegmentModal.values['segment_code']?? ""}
                                onChange={(e) => propertySegmentModal.handleValueChanged('segment_code', e.target.value)} 
                                onBlur={(e) => propertySegmentModal.handleValueChanged('segment_code', e.target.value)} />
                            {propertySegmentModal.errors['segment_code'] && <div className="light-input-error">
                                {propertySegmentModal.errors['segment_code']}
                            </div>}
                        </div>
                    </div>

                    <div className="light-input-container">
                        <div className="light-label">
                            Map to rev&you segments
                        </div>

                        <Select className="light-select" disabled={propertySegmentModal.mode === 'VIEW'}
                            value={propertySegmentModal.values['global_code']} onChange={(value) => propertySegmentModal.handleValueChanged('global_code', value)}
                        >
                            {segments.length > 0 && segments.map(segment =>
                                <Option key={segment._id} className="light-option"
                                    value={segment._id}
                                >
                                    {segment.name} ({segment._id})
                                </Option>
                            )}
                            {/* <Option className="add-segment-option" value="addSegment">
                                Add Segment
                            </Option> */}
                        </Select>

                        {propertySegmentModal.errors['global_code'] && <div className="light-input-error">
                            {propertySegmentModal.errors['global_code']}
                        </div>}
                    </div>

                    <div className="light-input-container">
                        <div className="light-label">
                            Add to segment group
                        </div>

                        <Select className="light-select" disabled={propertySegmentModal.mode === 'VIEW'}
                            value={propertySegmentModal.values['segment_group_code']}
                            onChange={(value) => {
                                if (value === 'addNewSegmentGroup') return;
                                propertySegmentModal.handleValueChanged('segment_group_code', value)
                            }}
                            onSelect={(value) => {
                                if (value === 'addNewSegmentGroup') {
                                    propertySegmentGroupModal.show({ mode: 'ADD' });
                                }
                            }}
                        >
                            {!isNullOrUndefined(property) && property.segment_groups.length > 0
                                && property.segment_groups.map((item) => {
                                    if (!isNullOrUndefined(item) && !isNullOrUndefined(item.gc)) {
                                        return <Option key={item.gc} className="light-option" value={item.gc}>
                                            {item.g} ({item.gc})
                                        </Option>
                                    }
                                })
                            }
                            <Option className="add-segment-option" value="addNewSegmentGroup">
                                Add Segment Group
                            </Option>
                        </Select>

                        {propertySegmentModal.errors['segment_group_code'] && <div className="light-input-error">
                            {propertySegmentModal.errors['segment_group_code']}
                        </div>}
                    </div>

                    <div className="light-input-container">
                        <div className="light-label">
                            Description
                        </div>

                        <textarea className="light-textarea" disabled={propertySegmentModal.mode === 'VIEW'}
                            rows="4" name="description" value={propertySegmentModal.values['description']?? ""}
                            onChange={(e) => propertySegmentModal.handleValueChanged('description', e.target.value)} 
                            onBlur={(e) => propertySegmentModal.handleValueChanged('description', e.target.value)} />

                        {propertySegmentModal.errors['description'] && <div className="light-input-error">
                            {propertySegmentModal.errors['segment_group_code']}
                        </div>}
                    </div>
                    <div>
                        <div className="light-form-buttons" style={{ gap: '20px' }}>
                            <Button type="bg-light"onClick={() => {onClose()}}>
                                {propertySegmentModal.mode === 'VIEW' ? 'Close': 'Cancel'}
                            </Button>
                            {propertySegmentModal.mode !== 'VIEW' && <Button btnType="submit" type="primary"
                                disabled={loading} onClick={(e) => {
                                    e.preventDefault();
                                    propertySegmentModal.handleSubmit()
                                }}
                            >
                                {segmentState === 'add' ? 'Add' : 'Edit'}
                            </Button>}
                        </div>
                    </div>
                </form>

                {loading && <>
                    <div style={{
                        width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.1)',
                        position: 'absolute', top: 0, left: 0, zIndex: 10
                    }} />
                    <LoadingSpinner />
                </>}
            </div>
        </Modal>
    }

    const renderAddPropertySegmentGroupModal = () => {
        return <Modal open={propertySegmentGroupModal.visible}
            closeIcon={<Exit width="25px" height="25px" className="pointer" />}
            onCancel={() => {
                propertySegmentModal.handleValueChanged('segment_group_code', null);
                propertySegmentGroupModal.hide() 
            } }
            width={500}
            footer={null}>
            <div className="rms-modal-content">
                <div className="rms-modal-header">
                    <h3>Add New Segment Group</h3>
                </div>

                <form className="light-form">
                    <div className="light-input-container">
                        <div className="light-label">
                            Segment Group Code
                        </div>

                        <input className="light-input"
                            name="segment_group_code" type="text" value={propertySegmentGroupModal.values['segment_group_code']}
                            onChange={(e) => propertySegmentGroupModal.handleValueChanged('segment_group_code', e.target.value)} onBlur={(e) => propertySegmentGroupModal.handleValueChanged('segment_group_code', e.target.value)} />

                        {propertySegmentGroupModal.errors['segment_group_code'] && <div className="light-input-error">
                            {propertySegmentGroupModal.errors['segment_group_code']}
                        </div>}
                    </div>

                    <div className="light-input-container">
                        <div className="light-label">
                            Segment Group Name
                        </div>

                        <input className="light-input"
                            name="segment_group" type="text" value={propertySegmentGroupModal.values['segment_group_name']}
                            onChange={(e) => propertySegmentGroupModal.handleValueChanged('segment_group_name', e.target.value)} onBlur={(e) => propertySegmentGroupModal.handleValueChanged('segment_group_name', e.target.value)} />

                        {propertySegmentGroupModal.errors['segment_group_name'] && <div className="light-input-error">
                            {propertySegmentGroupModal.errors['segment_group_name']}
                        </div>}
                    </div>

                    <div className="light-form-buttons">
                        <Button btnType="submit" type="primary" style={{ marginRight: '8px' }}
                            disabled={loading} onClick={(e) => {
                                e.preventDefault();
                                propertySegmentGroupModal.handleSubmit()
                            } }
                        >
                            ADD
                        </Button>
                        <Button type="bg-light" onClick={() => propertySegmentGroupModal.hide() }>
                            Cancel
                        </Button>
                    </div>
                </form>
            </div>
        </Modal>
    }

    const renderDeletePropertySegmentModal = () => {
        return <Modal open={deletePropertySegmentModal.visible} width={500} footer={null}
            closeIcon={<Exit width="25px" height="25px" className="pointer" />}
            onCancel={() => onClose()}
        >
            <div className="rms-modal-content">
                <div className="rms-modal-header">
                    <h3>Delete Segment Mapping</h3>
                </div>

                <div className="light-form delete-segment">
                    <p>Mapping for <span className="segment-span">
                        {deletePropertySegmentModal.rawValues.segment_code}
                    </span> will be removed from this property and all it's corresponding data will become unsegmented.
                    </p>
                    <p>Are you sure you want to delete mapping for this segment?</p>

                    <div className="light-input-container">
                        <p>Please enter the segment code to delete</p>

                        <input className="light-input"
                            name="delete" type="text" value={deletePropertySegmentModal.fields['segment_code'].isTouched? deletePropertySegmentModal.values['segment_code']: ''}
                            onChange={(e) => deletePropertySegmentModal.handleValueChanged('segment_code', e.target.value)} 
                            onBlur={(e) => deletePropertySegmentModal.handleValueChanged('segment_code', e.target.value)} />

                        {deletePropertySegmentModal.errors['segment_code'] && <div className="light-input-error">
                            {deletePropertySegmentModal.errors['segment_code']}
                        </div>}
                    </div>

                    <div className="light-form-buttons">
                        <Button type="primary" style={{ marginRight: '8px' }} onClick={() => deletePropertySegmentModal.handleSubmit() }> 
                            DELETE
                        </Button>
                        <Button type="bg-light" onClick={() => onClose()}>
                            Cancel
                        </Button>
                    </div>
                </div>
                {loading && <>
                    <div style={{
                        width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.1)',
                        position: 'absolute', top: 0, left: 0, zIndex: 10
                    }} />
                    <LoadingSpinner />
                </>}
            </div>
        </Modal>
    }

    const renderAddInternalSegmentModal = () => {
        return <Modal open={globalSegmentModal.visible} width={500} footer={null}
            closeIcon={<Exit width="25px" height="25px" className="pointer" />}
            onCancel={() => setAddSegmentModal(false)}
        >
            <div className="rms-modal-content">
                <div className="rms-modal-header">
                    <h3>Add New Segment</h3>
                </div>

                <form onSubmit={(e) => handleSubmitForm(e, 'segment')} className="light-form">
                    <div className="light-input-container">
                        <div className="light-label">
                            Rev&you Segment Code
                        </div>

                        <input className="light-input"
                            name="segment_code" type="text" value={globalSegmentModal.values['segment_code']}
                            onChange={(e) => globalSegmentModal.handleValueChanged('segment_code', e.target.value)} 
                            onBlur={(e) => globalSegmentModal.handleValueChanged('segment_code', e.target.value)} />

                        {globalSegmentModal.errors['segment_code'] && <div className="light-input-error">
                            {globalSegmentModal.errors['segment_code']}
                        </div>}
                    </div>

                    <div className="light-input-container">
                        <div className="light-label">
                            Rev&you Segment Name
                        </div>

                        <input className="light-input"
                            name="segment" type="text" value={globalSegmentModal.values['segment_name']}
                            onChange={(e) => globalSegmentModal.handleValueChanged('segment_name', e.target.value)} 
                            onBlur={(e) => globalSegmentModal.handleValueChanged('segment_name', e.target.value)} />

                        {globalSegmentModal.errors['segment_name'] && <div className="light-input-error">
                            {globalSegmentModal.errors['segment_name']}
                        </div>}
                    </div>

                    <div className="light-form-buttons">
                        <Button btnType="submit" type="primary" style={{ marginRight: '8px' }}
                            disabled={loading}
                        >
                            ADD
                        </Button>
                        <Button type="bg-light" onClick={() => setAddSegmentModal(false)}>
                            Cancel
                        </Button>
                    </div>
                </form>
            </div>
        </Modal>
    }

    return <>
        {renderPropertySegmentModal()}
        {renderAddPropertySegmentGroupModal()}
        {renderDeletePropertySegmentModal()}
        {/* {renderAddInternalSegmentModal()} */}
    </>

}

export default SegmentModal;
