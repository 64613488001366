import { ToastsStore } from "react-toasts";

import constants from "../../constants";
import userPermissions from "../../session/userPermissions";
import { createError } from "../../utils/index";


const authUser = JSON.parse(localStorage.getItem("authUser")) || null;
const role = (authUser && authUser.type) || null;
const initialState = {
    authUser,
    permissions: userPermissions(role),
    user: null,
    loading: true,
    loadingUser: true,
    bgLoading: false,
    users: [],
    totalCount: 0,
    currentSearchTerm: "",
    errors: "",
};



function users(state = initialState, action) {
    switch (action.type) {
        case constants.GET_AUTH_USER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case constants.GET_AUTH_USER_SUCCESS:
            const authUser = action.response.data.data;
            const authUserString = JSON.stringify(authUser);
            localStorage.setItem("authUser", authUserString);
            localStorage.setItem("userId", authUser._id);
            return {
                ...state,
                loading: false,
                authUser,
                permissions: userPermissions(authUser.type),
            };
        case constants.GET_AUTH_USER_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case constants.GET_USERS_REQUEST:
            return {
                ...state,
                loading: true,
                currentSearchTerm: action.searchTerm,
            };
        case constants.GET_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                users: action.response.data.data,
                totalCount: action.response.data.totalCount,
            };
        case constants.GET_USERS_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case constants.GET_USER_REQUEST:
            return {
                ...state,
                loadingUser: true,
            };
        case constants.GET_USER_SUCCESS:
            return {
                ...state,
                loadingUser: false,
                user: action.response.data.data,
            };
        case constants.GET_USER_FAILURE:
            return {
                ...state,
                loadingUser: false,
            };
        case "CLEAR_USER":
            return {
                ...state,
                user: null,
                loadingUser: true,
            };
        case constants.CREATE_USER_REQUEST:
            return {
                ...state,
                bgLoading: true,
            };
        case constants.CREATE_USER_SUCCESS:
            ToastsStore.success("Successfully created");
            return {
                ...state,
                bgLoading: false,
            };
        case constants.CREATE_USER_FAILURE:
            return {
                ...state,
                bgLoading: false,
                error: createError(action.error)
            };
        case constants.UPDATE_USER_REQUEST:
            return {
                ...state,
                bgLoading: true,
            };
        case constants.UPDATE_USER_SUCCESS:
            ToastsStore.success("Successfully updated");
            return {
                ...state,
                bgLoading: false,
            };
        case constants.UPDATE_USER_FAILURE:
            return {
                ...state,
                bgLoading: false,
                error: createError(action.error)
            };
        case constants.UPDATE_USER_PREFERENCES_REQUEST:
            return {
                ...state,
                bgLoading: true,
            };
        case constants.UPDATE_USER_PREFERENCES_SUCCESS:
            ToastsStore.success("Successfully updated");
            localStorage.setItem("authUser", JSON.stringify({ ...state.authUser, prefs: action.response.data.data }));
            return {
                ...state,
                bgLoading: false,
                authUser: {
                    ...state.authUser,
                    prefs: action.response.data.data
                }
            };
        case constants.UPDATE_USER_PREFERENCES_FAILURE:
            return {
                ...state,
                bgLoading: false,
                error: createError(action.error)
            };
        case constants.UPDATE_USER_PROPERTY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case constants.UPDATE_USER_PROPERTY_SUCCESS:
            const updatedUser = [action.response.data.data];
            const newUsers = state.users.map(
                (user) => updatedUser.find((o) => o._id === user._id) || user
            );
            ToastsStore.success("Successfully updated");
            return {
                ...state,
                loading: false,
                users: newUsers,
            };
        case constants.UPDATE_USER_PROPERTY_FAILURE:
            return {
                ...state,
                loading: false,
                error: createError(action.error),
            };
        case constants.UPDATE_USER_PROFILE_REQUEST:
            return {
                ...state,
                loading: true,
                bgLoading: true,
            };
        case constants.UPDATE_USER_PROFILE_SUCCESS:
        case constants.UPDATE_USER_PASSWORD_SUCCESS:
            localStorage.setItem("authUser", JSON.stringify(action.response.data));
            if (action.response.data.tutorial === state.authUser.tutorial) {
                ToastsStore.success("Successfully updated");
            }
            return {
                ...state,
                loading: false,
                bgLoading: false,
                authUser: action.response.data,
            };
        case constants.UPDATE_USER_PROFILE_FAILURE:
            return {
                ...state,
                loading: false,
                bgLoading: false,
                error: createError(action.error),
            };
        case constants.SEND_RESET_PASSWORD_EMAIL_REQUEST:
            return {
                ...state,
                bgLoading: true,
            };
        case constants.SEND_RESET_PASSWORD_EMAIL_SUCCESS:
            ToastsStore.success("Password reset link sent");
            return {
                ...state,
                bgLoading: false,
            };
        case constants.SEND_RESET_PASSWORD_EMAIL_FAILURE:
            return {
                ...state,
                bgLoading: false,
                error: createError(action.error),
            };
        case constants.UPDATE_USER_TUTORIAL:
            const { user } = action;
            localStorage.setItem("authUser", JSON.stringify(user));
            return {
                ...state,
                loading: false,
                bgLoading: false,
                authUser: user,
            };
        case constants.UPDATE_USER_CUSTOM_FILTERS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case constants.UPDATE_USER_CUSTOM_FILTERS_SUCCESS:
            ToastsStore.success("User custom filters successfully updated");
            return {
                ...state,
                loading: false,
            };
        case constants.UPDATE_USER_CUSTOM_FILTERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: createError(action.error),
            };
        case constants.ADD_USER_CUSTOM_FILTERS_REQUEST:
        case constants.DELETE_USER_CUSTOM_FILTERS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case constants.ADD_USER_CUSTOM_FILTERS_SUCCESS:
        case constants.DELETE_USER_CUSTOM_FILTERS_SUCCESS:
            ToastsStore.success("User custom filters successfully updated");
            return {
                ...state,
                loading: false,
            };
        case constants.ADD_USER_CUSTOM_FILTERS_FAILURE:
        case constants.DELETE_USER_CUSTOM_FILTERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: createError(action.error),
            };
        default:
            return state;
    }
}

export default users;
