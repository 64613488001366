import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { ToastsStore } from "react-toasts";

import ListHeader from "elements/Text/ListHeader";
import IconButton from "elements/Button/Icon";

import useProperties from "../../store/actions/propertiesActions";
import constants from "../../constants";
import { sortArray } from "utils/data";
import usePermissions from "hooks/usePermissions";

/**
 * Segment mappings list component.
 *
 * @param {[Object]} segments List of segments data object.
 * @param {Object} user Rev&You user data object.
 * @param {Function} onAdd Event action after clicking the Add Segment Mapping button.
 * @param {Function} onView Event action after clicking the View Segment Mapping button.
 * @param {Function} onEdit Event action after clicking the Edit Segment Mapping button.
 * @param {Function} onDelete Event action after clicking the Delete Segment Mapping button.
 * @return {Component} Segment mappings list component.
*/
const SegmentMappingsList = ({ segments, onAdd, onView, onEdit, onDelete }) => {
    const { 
        canAccess: canAccessSegmentMapping, 
        canCreate: canCreateSegmentMapping, 
        canUpdate: canUpdateSegmentMapping, 
        canDelete: canDeleteSegmentMapping
    } = usePermissions('property', 'segment', 'mapping');

    const dispatch = useDispatch();
    let hasSegments = segments && Array.isArray(segments) && segments.length > 0;
    const updateListItemInView = (mapping, s) => {
        dispatch({
            type: constants.UPDATE_LIST_ITEM_IN_VIEW,
            value: {
                mapping: mapping,
                segment: s
            }
        });
    }

    const handleViewSegmentMapping = (mapping, s) => {
        updateListItemInView(mapping, s);
        onView();
    }

    const handleAddSegmentMapping = (mapping, s) => {
        updateListItemInView(mapping, s);
        onAdd();
    }

    const handleEditSegmentMapping = (mapping, s) => {
        updateListItemInView(mapping, s);
        onEdit();
    }

    const handleDeleteSegmentMapping = (mapping, s) => {
        updateListItemInView(mapping, s);
        onDelete();
    }

    return (
        <div>
            <ListHeader headerCopy="Add Segment Mapping" component={
                <IconButton type="add" hasAccess={canCreateSegmentMapping()} action={() => handleAddSegmentMapping(null, null)} width="25px" />
            } />

            <table className="forms-table">
                <thead>
                    <tr key={'header-key'} style={{ marginBottom: 8 }}>
                        <th>Segment Code</th>
                        <th>Segment Name</th>
                        <th>Channel</th>
                        <th style={{ marginLeft: 100 }}>Source Code</th>
                        <th style={{ marginLeft: 100 }}>Source Name</th>
                        <th style={{ textAlign: "right" }}>
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {hasSegments && sortArray(segments, "segment").map((s, index) => {
                        return s.local && <>
                            <tr key={`${s.local.code}-${s.local.name}-${index}`} style={{ marginTop: 8 }}>
                                <td>{s.local.code}</td>
                                <td style={{ marginLeft: -50, maxWidth: "16ch", wordWrap: "break-word" }}>
                                    {s.local.name}
                                </td>
                                <td style={{ display: "grid", gridTemplateColumns: "95% 5%", marginLeft: 10 }}>
                                    {(s.local.code !== "UNS") && <>
                                        <strong style={{ width: "max-content" }}>
                                            Add another segment mapping
                                        </strong>
                                        <div style={{ marginTop: 2 }}>
                                            <IconButton type="plus" hasAccess={canCreateSegmentMapping()} width="18px"
                                                action={() => handleAddSegmentMapping(null, s)} />
                                        </div>
                                    </>}
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            {sortArray(s.sources, "partner_code").map((mapping, index) => {
                                return (mapping.partner_code !== "REVANDYOU") && (
                                    <tr key={`${s.local.code}-${mapping.partner_code}-${mapping.source_code}-${index}`}>
                                        <td></td>
                                        <td></td>
                                        <td>{mapping.partner_code}</td>
                                        <td style={{ marginLeft: 100 }}>{mapping.source_code}</td>
                                        <td style={{ marginLeft: 100 }}>{mapping.source}</td>
                                        <td style={{
                                            display: "flex",
                                            justifyContent: 'flex-end',
                                            gap: '5px'
                                        }}>
                                            <span></span>
                                            <span></span>
                                            <IconButton type="edit" hasAccess={canAccessSegmentMapping() && canUpdateSegmentMapping()} action={() => handleEditSegmentMapping(mapping, s)} />
                                            <IconButton type="view" hasAccess={canAccessSegmentMapping() && !canUpdateSegmentMapping()} action={() => handleViewSegmentMapping(mapping, s)} />
                                            <IconButton type="delete" hasAccess={canDeleteSegmentMapping()}
                                                action={() => handleDeleteSegmentMapping(mapping, s)} />
                                        </td>
                                    </tr>
                                )
                            })}
                        </>
                    }
                    )}

                    {!hasSegments && <tr>
                        <td>No segments mappings found</td>
                    </tr>}
                </tbody>
            </table>
        </div>
    )
};

export default SegmentMappingsList;