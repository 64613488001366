import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { useState, useMemo, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { renderToString } from 'react-dom/server';
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { formatData, percent, withCurrency, formatDataWithPlusSign } from '../../utils';
import OutlinedIcon from "elements/Icon/OutlinedIcon";
import './Graph.scss';
dayjs.extend(isBetween);

const CalendarGraph = ({ type, data, currency, isLoading, loadMore }) => {
    const { ADRState } = useSelector((state) => state.mainReducer);
    const isDaily = type === 'DAILY';
    const isWeekly = type === 'WEEKLY';
    const isMonthly = type === 'MONTHLY';

    const getLabel = (data) => {
        if (isDaily) return dayjs(data.start_date).format('MMMM DD, YYYY');
        if (isWeekly) return `${dayjs(data.start_date).format('MMM DD')} - ${dayjs(data.end_date).format('MMM DD')}`;
        if (isMonthly) return dayjs(data.start_date).format('MMMM');
    }
    
    const xChartLabel = () => {
        if (isDaily) return 'Days';
        if (isWeekly) return 'Weeks';
        if (isMonthly) return 'Months';
    }

    const tooltipOccupancy = (data) => {
        return <div className='calendar-graph-tooltip-content'>
            <div className='calendar-graph-tooltip-title'>{getLabel(data).toUpperCase()}</div>
            <div className='calendar-graph-tooltip-body'>
                { ADRState && <>
                    <span>ADR</span>
                    <span>{withCurrency(formatData(data['adr'], 10000, true), currency)}</span>
                </> }
                <span>Revenue</span>
                <span>{withCurrency(formatData(data['revenue'], 10000, true), currency)}</span>
                <span>OTB</span>
                <span>{data['occupancy']} ({percent(Math.round(data['percent_occupancy']))})</span>
                <span>Inventory</span>
                <span>{data['inventory']}</span>
                <span>Pickup</span>
                <span>{formatDataWithPlusSign(data['pickup'], false)}</span>
                <span>Pace</span>
                <span>{formatDataWithPlusSign(data['pace'], false)}</span>
            </div>
        </div>
    };

    const tooltipPropPrice = (data) => {
        const style = {};
        if (data['recommended_value']) style.paddingBottom = 'unset';

        return <div className='calendar-graph-tooltip-content'>
            <div className='calendar-graph-tooltip-title' style={style}>{getLabel(data).toUpperCase()}</div>
            <div style={{ display: 'flex', justifyContent: 'center', gridColumn: 'span 2' }}>
                {data['recommended_value'] > 0 && <OutlinedIcon type='up' style={{ color: '#51b68a' }} />}
                {data['recommended_value'] > 1 && <OutlinedIcon type='up' style={{ color: '#51b68a' }} />}
                {data['recommended_value'] > 2 && <OutlinedIcon type='up' style={{ color: '#51b68a' }} />}

                {data['recommended_value'] < 0 && <OutlinedIcon type='down' style={{ color: '#ed474b' }} />}
                {data['recommended_value'] < -1 && <OutlinedIcon type='down' style={{ color: '#ed474b' }} />}
                {data['recommended_value'] < -2 && <OutlinedIcon type='down' style={{ color: '#ed474b' }} />}
            </div>
            
            <div className='calendar-graph-tooltip-body'>
                <span style={{ textAlign: 'center' }}>Property Price</span>
                <span>{withCurrency(formatData(data['property_price'], 10000, true), currency)}</span>
            </div>
        </div>
    }

    const tooltipCompPrice = (data) => {        
        return <div className='calendar-graph-tooltip-content'>
            <div className ='calendar-graph-tooltip-title'>{getLabel(data).toUpperCase()}</div>
            <div className ='calendar-graph-tooltip-body'>
                <span>Competitor Price</span>
                <span>{withCurrency(formatData(data['competitors_price'], 10000, true), currency)}</span>
            </div>
        </div>;
    }

    const getGraphData = () => {
        const labels = [];
        const occDataSet = {
            type: 'bar',
            label: 'Occupancy Percent',
            yAxisID: 'yOccupancy',
            backgroundColor: [],
            barPercentage: 1,
            categoryPercentage: .6,
            pointStyle: 'circle',
            data: []
        };
        const arrowDataSet = {
            type: 'line',
            label: 'Pricing Recommendation',
            yAxisID: 'yPricing',
            showLine: false,
            hidden: true,
            data: [],
        }
        const priceDataSet = {
            type: 'line',
            label: 'Property Pricing',
            yAxisID: 'yPricing',
            backgroundColor: '#226076',
            borderColor: '#226076',
            borderWidth: 4,

            pointHitRadius: 6,
            pointBorderWidth: 4,
            pointStyle: [],
            pointRadius: [],
            pointBackgroundColor: [],
            pointHoverBorderWidth: 4,
            pointHoverRadius: [],

            data: []
        }
        const compDataSet = {
            type: 'line',
            label: 'Competitor Pricing',
            yAxisID: 'yPricing',
            backgroundColor: '#7d7e85',
            borderColor: '#7d7e85',
            borderWidth: 4,

            pointHitRadius: 6,
            pointBorderWidth: 4,
            pointRadius: 2,
            pointHoverBorderWidth: 4,
            // borderWidth: 4,
            data: []
        }

        if (Array.isArray(data)) {
            for(const item of data) {
                const color = item.demand_color;

                if (isDaily) {
                    labels.push(dayjs(item.start_date).format('DD'))
                } else {
                    labels.push(getLabel(item).toUpperCase());
                }

                if (color === 'grey') occDataSet.backgroundColor.push('#d8d8d8');
                if (color === 'green') occDataSet.backgroundColor.push('#51b68a');
                if (color === 'yellow') occDataSet.backgroundColor.push('#f28f3c');
                if (color === 'red') occDataSet.backgroundColor.push('#ed474b');

                occDataSet.data.push(item?.percent_occupancy);
                priceDataSet.data.push(item?.property_price);
                arrowDataSet.data.push(item?.property_price);
                compDataSet.data.push(item?.competitors_price);

                if (!item?.recommended_value) {
                    priceDataSet.pointBackgroundColor.push('#226076');
                    priceDataSet.pointRadius.push(2)
                    priceDataSet.pointHoverRadius.push(4);
                    // priceDataSet.pointStyle.push(false)
                } else {
                    priceDataSet.pointBackgroundColor.push(item?.recommended_value > 0? '#41ce91': '#ed474b');
                    priceDataSet.pointRadius.push(5);
                    priceDataSet.pointHoverRadius.push(8);
                    // priceDataSet.pointStyle.push(image);
                }

            }
        }

        return {
            labels: labels, 
            datasets: [
                arrowDataSet,
                priceDataSet,
                compDataSet,
                occDataSet,
            ]
        };
    }

    const graphData = useMemo(() => {
        return getGraphData()
    }, [data]);

    const loading = !(!isLoading && Array.isArray(data));

    return <div style={{ marginTop:'16px' }}>
        {/* { loading && <LoadingSpinner />} */}
        <div style={{ backgroundColor: 'white', position: 'relative', height: '450px', width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Bar
                data={graphData}
                options={{
                    tension: .2, // line chart
                    spanGaps: true, // line chart
                    responsive: true,
                    maintainAspectRatio: false,
                    categoryPercentage: 0.8,
                    barPercentage: 1.0,
                    layout: {
                        padding: { top: 40, bottom: 20, left: 20, right: 20 }
                    }, 
                    plugins: { 
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            enabled: false,
                            external: ({ chart, tooltip }) => {
                                if (!Array.isArray(tooltip?.dataPoints)) return;
                                const dataIndex = tooltip.dataPoints[0]?.dataIndex;
                                const currentDataSet = tooltip.dataPoints[0]?.dataset;
                                const element = tooltip.dataPoints[0]?.element;
                                const currentData = data[dataIndex];
                                
                                const getTooltip = () => {
                                    if (!chart.canvas.parentNode.querySelector('div')) {   
                                        const div = document.createElement('div');
                                        div.className = 'calendar-graph-tooltip-container';
                                        chart.canvas.parentNode.appendChild(div);
                                    }
                                    return chart.canvas.parentNode.querySelector('div');
                                }

                                const tooltipEl = getTooltip();
                                // Set tooltip content
                                if (currentDataSet.label === 'Occupancy Percent') {
                                    tooltipEl.innerHTML = renderToString(tooltipOccupancy(currentData));
                                }

                                if (currentDataSet.label === 'Property Pricing') {
                                    tooltipEl.innerHTML = renderToString(tooltipPropPrice(currentData));
                                }

                                if (currentDataSet.label === 'Competitor Pricing') {
                                    tooltipEl.innerHTML = renderToString(tooltipCompPrice(currentData));
                                }

                                // Tooltip visibility
                                tooltipEl.style.opacity = tooltip.opacity === 0? 0: 1;
                                
                                // Reposition tooltip
                                const positionX = tooltip.caretX;
                                const positionY = tooltip.caretY;
                                const elementEdgeX = ((element?.width ?? 0) / 2);
                                const outPositionX = (tooltipEl.offsetWidth + positionX + elementEdgeX) > chart.width;
                                const outPositionY = (tooltipEl.offsetHeight + positionY) > chart.height;
                                
                                tooltipEl.style.borderLeft = '0px';
                                tooltipEl.style.borderRight = '0px';
                                tooltipEl.style.boxShadow = '3px 3px 10px rgba(0, 0, 0, 0.399)';

                                // Apply side border
                                if (currentDataSet.label === 'Occupancy Percent') {
                                    if (outPositionX === true) tooltipEl.style.borderRight = '10px solid ' + currentDataSet.backgroundColor[dataIndex];
                                    else tooltipEl.style.borderLeft = '10px solid ' + currentDataSet.backgroundColor[dataIndex];
                                }
                                // Horizontal position
                                if (outPositionX) {
                                    tooltipEl.style.left = `${(positionX - elementEdgeX - 5) - tooltipEl.offsetWidth}px`;
                                    tooltipEl.style.boxShadow = '-3px 3px 10px rgba(0, 0, 0, 0.399)';
                                } else {
                                    tooltipEl.style.left = `${(positionX + elementEdgeX + 5)}px`;
                                }
                                // Vertical position
                                if (outPositionY) {
                                    tooltipEl.style.top = `${positionY - tooltipEl.offsetHeight}px`;
                                    tooltipEl.style.boxShadow = '3px -3px 10px rgba(0, 0, 0, 0.399)';
                                } else {
                                    tooltipEl.style.top = `${positionY}px`;
                                }
                                console.log({ positionX, positionY, outPositionX, outPositionY, width: tooltipEl.offsetWidth, maxWidth: chart.width, x: tooltipEl.offsetWidth + positionX })
                                return tooltipEl;
                            }
                        },
                    },
                    scales: {
                        yOccupancy: {
                            grid: { display: false },
                            position: "right",
                            ticks: {
                                callback: value => `${value}%`,
                                beginAtZero: false,
                                // color: "#2e2e2e",
                                font: {
                                    family: "'Titillium Web', sans-serif",
                                    size: 13,
                                    weight: "900",
                                }
                            },
                            title: {
                                display: true,
                                text: "Rooms Sold",
                                font: {
                                    family: "'Titillium Web', sans-serif",
                                    size: 16,
                                    weight: "900"
                                }
                            }
                        },
                        yPricing: {
                            position: "left",
                            gridLines: { display: false },
                            ticks: {
                                callback: (value) => {
                                    if (currency) value = `${currency} ${value}`
                                    return value;
                                },
                                beginAtZero: false,
                                color: "#2e2e2e",
                                font: {
                                    family: "'Titillium Web', sans-serif",
                                    size: 13,
                                    weight: "900",
                                }
                            },
                            title: {
                                display: true,
                                text: "Price",
                                font: {
                                    family: "'Titillium Web', sans-serif",
                                    size: 16,
                                    weight: "900"
                                }
                            }
                        },
                        x: {
                            gridLines: { display: false },
                            categoryPercentage: 0.8,
                            barPercentage: 1.0,
                            ticks: {
                                display: true,
                                color: "#2e2e2e",
                                font: {
                                    family: "'Titillium Web', sans-serif",
                                    size: 13,
                                    weight: "900",
                                }
                            },
                            title: {
                                display: true,
                                text: xChartLabel(),
                                font: {
                                    family: "'Titillium Web', sans-serif",
                                    size: 16,
                                    weight: "900"
                                }
                            }
                        },
                    }
                }}
            />
        </div>
        {loadMore && data.length > 0 && (
            <div data-html2canvas-ignore className="load-more-container">
                <div className='load-more-containter-navigator'>
                    <OutlinedIcon type='left' style={{ fontSize: '20px' }} onClick={() => loadMore("start")} />
                </div>
                
                <span className='load-more-container-text' style={{ textTransform: 'uppercase', fontSize: '18px', fontWeight: '600'}}>
                    Load More
                </span>
                <div className='load-more-containter-navigator'>
                    <OutlinedIcon type='right' style={{ fontSize: '20px' }} onClick={() => loadMore("end")} />
                </div>
            </div>
        )}
    </div>
}

// const image = new Image(15, 15);
// const svg2Up = `<svg fill="#000000" viewBox="0 0 24 24" id="double-up-sign-circle" data-name="Flat Line" xmlns="http://www.w3.org/2000/svg" class="icon flat-line"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><circle id="secondary" cx="12" cy="12" r="9" style="fill: #51b68a; stroke-width: 2;"></circle><polyline id="primary" points="15 10.5 12 7.5 9 10.5" style="fill: none; stroke: #000000; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;"></polyline><polyline id="primary-2" data-name="primary" points="15 15.5 12 12.5 9 15.5" style="fill: none; stroke: #000000; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;"></polyline><circle id="primary-3" data-name="primary" cx="12" cy="12" r="9" style="fill: none; stroke: #000000; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;"></circle></g></svg>`
// image.src = `data:image/svg+xml;utf8,${encodeURIComponent(svg3Up)}`

export default CalendarGraph;