import React from "react";
import { useSelector } from "react-redux";

import { Search } from "Icons";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { TutorialSpot } from "components/Tutorial/index";

const SearchInput = ({ isLoading = false,  handleSearch, placeholder }) => {
    const tutorial = useSelector((state) => state.mainReducer.tutorial);

    const [state, setState] = React.useState({
        searchTerm: "",
        typingTimeout: 0,
        active: false,
        touched: false,
    });

    const handleChange = (event) => {
        if (state.typingTimeout) {
            clearTimeout(state.typingTimeout);
        }

        setState({
            ...state,
            searchTerm: event.target.value,
            active: true,
        });
    };

    const handleFocus = (event) => {
        setState({ ...state, touched: true });
    };

    React.useEffect(() => {
        if (
            ((state.active && state.searchTerm.length >= 3) ||
                state.searchTerm.length === 0) &&
            state.touched
        )
            setState({
                ...state,
                typingTimeout: setTimeout(function () {
                    handleSearch(state.searchTerm);
                }, 500),
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.searchTerm]);

    return (
        <div className="search-input searchTutorial">
            {tutorial.search.showSpot && (
                <TutorialSpot section="dashboard" type="search" left="-46px" />
            )}
            <input
                type="text"
                onChange={handleChange}
                placeholder={placeholder}
                value={state.searchTerm}
                onFocus={handleFocus}
            />
            {isLoading 
                ? <LoadingSpinner /> 
                : <Search
                    width="30px"
                    height="30px"
                    className="pointer rms-table-icon"
                />
            }
            <div className="icon-container">
                
            </div>
        </div>
    );
};

export default SearchInput;
