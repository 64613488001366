import React, { cloneElement } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import { Menu, Collapse, Dropdown } from "antd";
import OutlinedIcon from 'elements/Icon/OutlinedIcon';
import { TutorialSpot } from "../Tutorial/index";
import usePermissions from 'hooks/usePermissions';

const PropertyNav = ({ showSpot, visible, selectedProperty, properties, eventHandler }) => {
    const { isAllowed } = usePermissions();
    const location = useLocation();
    const { Panel } = Collapse;

    const getPropertyMenu = () => {
        const hasIntegrationPartner = selectedProperty 
            && Array.isArray(selectedProperty.mappings) 
            && selectedProperty.mappings.some(item => item?.enabled === true);
        const items = [];
        
        if (location.pathname === "/dashboard") {
            items.push({
                key: 'manage_property',
                label: (
                    <span className={`ant-dropdown-menu-item ${!selectedProperty?.id ? "disabled-link" : ""}`} key="1">
                        <NavLink
                            exact
                            onClick={(e) => eventHandler({ ...e, action: "manage_property" })}
                            to={`/properties/${selectedProperty?.id}/edit`}
                        >
                            Manage Property Details
                        </NavLink>
                    </span>
                )
            });
        }

        if (location.pathname.includes("/properties")) {
            items.push({
                key: 'go_to_property_dashboard',
                label: (
                    <span className={`ant-dropdown-menu-item ${!selectedProperty?.id ? "disabled-link" : ""}`} key="1">
                        <NavLink
                            exact
                            onClick={(e) => eventHandler({ ...e, action: "go_to_property_dashboard" })}
                            to={`/dashboard`}
                        >
                            Go To Dashboard
                        </NavLink>
                    </span>
                )
            });
        }

        if (!hasIntegrationPartner && isAllowed('ACCESS_PROPERTY_UPLOAD', false)) {
            items.push({
                key: 'show_upload_modal',
                label: (
                    <span
                        className={`ant-dropdown-menu-item ${!selectedProperty?.id ? "disabled-link" : ""}`}
                        key="2"
                        onClick={(e) => {
                            eventHandler({ ...e, action: "show_upload_modal", property: selectedProperty });
                        }}
                    >
                        Upload Property Data
                    </span>
                )
            });
        }

        return items;
    }

    return (<>
        {showSpot && 
        <TutorialSpot
            section="overviewCalendar"
            type="hotelMenu"
            left="-42px"
            bottom="-11px"
        />}
        <Dropdown
            menu={{ items: getPropertyMenu() }}
            onOpenChange={ (val, info) => { 
                if (info.source === 'menu') return;
                eventHandler({ action: "toggle_dropdown", value: val })
            } }
            dropdownRender={(Menu) => {
                return <div style={{ backgroundColor: 'white' }}>
                    {cloneElement(Menu, { style: { boxShadow: 'none' }})}
                    <Collapse
                        className="property-menu-properties"
                        bordered={false}
                        expandIconPosition="end"
                    >
                        <Panel header="Change Property" key="1">
                            {properties.map((property) => (
                                <p
                                    className={selectedProperty?.name === property.name ? "active" : ""}
                                    style={{ paddingLeft: 35, paddingRight: 24 }}
                                    key={property.property_code}
                                    onClick={(e) => {
                                        eventHandler({ ...e, action: "select_property", property: property });
                                    }}
                                >{property.name}</p>
                            ))}
                        </Panel>
                    </Collapse>
                </div>;
            }}
            open={visible}
            placement="bottomRight"
            trigger={["click"]}
            overlayClassName="fixed property-menu"
            className="property-list"
        >
            <a className="ant-dropdown-link" >
                {selectedProperty?.name} <OutlinedIcon type="down" />
            </a>
        </Dropdown>
    </>)
}

export default PropertyNav;