import { useMemo, useState } from 'react';

const useLoader = () => {
    const [loadCount, setLoadCount] = useState(0);
    
    const loading = useMemo(() => {
        return loadCount > 0;
    }, [loadCount]);

    const startLoading = () => setLoadCount(prev => ++prev);
    const doneLoading = () => setLoadCount(prev => --prev);

    return {
        loading,
        startLoading,
        doneLoading
    }
}

export default useLoader;