import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { Select } from 'antd';
import Button from "../../Button/Button";
import useForm from 'hooks/useForm.js';
import useUserActions from 'store/actions/usersActions.js';
import usePropertiesActions from 'store/actions/propertiesActions.js';
import usePropertyGroupActions from 'store/actions/propertyGroupActions.js';
import useRoleActions from 'store/actions/roleActions.js';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner.js';
import MappingList from './MappingList';
import OutlinedIcon from 'elements/Icon/OutlinedIcon';
import { ToastsStore } from 'react-toasts';
import usePermissions from 'hooks/usePermissions';


const UserForm = () => {
    const { canUpdate } = usePermissions('user');
    const location = useLocation();
    const { getUser, addUser, updateUserProfile } = useUserActions();
    const { getRoles } = useRoleActions();
    const { getProperties } = usePropertiesActions();
    const { getPropertyGroups } = usePropertyGroupActions();
    const { id: userId } = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [mappingList, setMappingList] = useState([]);
    const [roleList, setRoleList] = useState([]);

    const isAddMode = location.pathname.includes('/add');
    const isEditMode = userId && location.pathname.includes('/edit');

    const { errors, values, setValue, loadFormData, submitForm, clearFormData } = useForm({
        formFields: [{
            key: 'id',
            type: 'string',
            defaultValue: userId,
            validations: {
                required: isEditMode
            }
        }, {
            key: 'name',
            type: 'string',
            defaultValue: '',
            validations: {
                required: true
            }
        }, {
            key: 'email',
            type: 'string',
            defaultValue: '',
            validations: { 
                required: true
            }
        }, {
            key: 'role_id',
            type: 'string',
            defaultValue: '',
            validations: {
                required: true
            }
        }, {
            key: 'properties',
            defaultValue: []
        }],
        onSubmit: (values) => {
            const getPropertyIds = (items, type) => {
                return items.filter(item => item.type === type).map(item => item.id)
            };

            setIsLoading(true);

            const data = {
                name: values['name'],
                email: values['email'],
                role_id: values['role_id'],
                properties: getPropertyIds(values['properties'], 'PROPERTY'),
                property_groups: getPropertyIds(values['properties'], 'PROPERTY_GROUP')
            };

            if (isAddMode) {
                addUser(data).then(result => {
                    if (result.type === 'CREATE_USER_SUCCESS') {
                        ToastsStore.success(`User was successfuly added`);
                        setIsLoading(false);
                        clearFormData();
                    }
                }).catch(error => {
                    ToastsStore.error(error);
                });
            }

            if (isEditMode) {
                updateUserProfile(userId, data).then(result => {
                    if (result.type === 'UPDATE_USER_SUCCESS') {
                        const user = result.response.data.data;
                        ToastsStore.success(`User was successfuly updated`);
                        loadFormData({
                            id: user._id,
                            name: user.name,
                            email: user.email,
                            role_id: user.role_id,
                            properties: [
                                ...user.properties.map(item => getPropertyItem(item, 'PROPERTY')),
                                ...user.property_groups.map(item => getPropertyItem(item, 'PROPERTY_GROUP'))
                            ]
                        });
                        setIsLoading(false);
                    } else {
                        ToastsStore.error(result.error.response.data.message);
                    }
                }).catch(error => {
                    console.error(error);
                    // ToastsStore.error(error);
                });
            }
        }
    });

    const getPageTitle = () => {
        let title = "RevAndYou";

        if (isAddMode) title = "Add User";
        if (isEditMode) title = "Edit User";

        return title;
    }

    const getPropertyItem = (data, type) => {
        return {
            id: data._id,
            name: data.name,
            code: type === 'PROPERTY'
                ? data.property_code
                : data.code,
            type: type
        }
    }

    useEffect(() => {

        Promise.all([
            getProperties(null, 'name', null).then(result => result?.response?.data?.data?? []),
            getPropertyGroups(null, 'name', null).then(result => result.data.data?? []),
            getRoles(null, 'name', null).then(result => result?.response?.data?.data?? [])
        ]).then(([properties, propertyGroups, roles]) => {
            setRoleList(roles);

            const newMappingList = [];
            Array.isArray(properties) && properties.forEach(item => newMappingList.push(getPropertyItem(item, 'PROPERTY')));
            Array.isArray(propertyGroups) && propertyGroups.forEach(item => newMappingList.push(getPropertyItem(item, 'PROPERTY_GROUP')));

            setMappingList(newMappingList);

            if (isEditMode) {
                getUser(userId).then(result => {
                    if (result.type === 'GET_USER_SUCCESS') {
                        const user = result.response.data.data;
                        loadFormData({
                            id: user._id,
                            name: user.name,
                            email: user.email,
                            role_id: user.role_id,
                            properties: [
                                ...user.properties.map(item => getPropertyItem(item, 'PROPERTY')),
                                ...user.property_groups.map(item => getPropertyItem(item, 'PROPERTY_GROUP'))
                            ]
                        });
                        setIsLoading(false);
                    }
                });
            } else {
                setIsLoading(false);
            }
        });
        
    }, []);

    return <div className="user-form forms page">
        {isLoading && <LoadingSpinner  mask={true} /> }
        <Helmet>{getPageTitle()}</Helmet>

        <div>
            <div className='container centered bg-white' style={{ padding: '16px 80px 0' }}>
                <h2>Manage User</h2>
            </div>
        </div>
        <div className='container centered' style={{ paddingTop: '24px' }}>

            <div className='row'>
                    <div className='column'>
                        <div className={`forms-field`}>
                            <p className="field__label"><span className={(errors['name'])? 'error': ''}>NAME OF USER</span></p>
                            <input
                                type="text"
                                value={values['name']}
                                onChange={(e) => setValue('name', e.target.value, true) }
                                className={`field__input`}
                            />
                        </div>
                        <div className={`forms-field`}>
                            <p className="field__label"><span className={(errors['email'])? 'error': ''}>EMAIL</span></p>
                            <input
                                type="text"
                                value={values['email']}
                                onChange={(e) => setValue('email', e.target.value, true) }
                                className={`field__input`}
                            />
                        </div>
                        <div className={`forms-field`}>
                            <p className="field__label"><span className={(errors['role_id'])? 'error': ''}>ROLE</span></p>
                            <Select
                                suffixIcon={<OutlinedIcon type='down' />}
                                showSearch={true}
                                value={roleList.find(role => role._id === values['role_id'])?._id}
                                onChange={(value) => setValue('role_id', value)}
                                style={{ maxWidth: '248px', width: '60%' }}
                                options={roleList.map(option => ({ 
                                    label: option.name,
                                    value: option._id
                                }))}
                                filterOption={(input, option) => {
                                    const inputLowerCase = input.toLowerCase();
                                    const nameLowerCase = option.label.toLowerCase();
                                    return nameLowerCase.includes(inputLowerCase);
                                }}
                            />
                        </div>
                    </div>
                    <div className='column'></div>
            </div>
            <div className='row'>
                <MappingList className='user-form-property-mapping' allItems={mappingList} selectedItems={values['properties']} eventHandler={(e) => {
                    const newSelectedIds = values['properties'].map(item => item.id);
                    if (e.action === 'add') {
                        e.value.forEach(item => newSelectedIds.push(item));
                        setValue('properties', mappingList.filter(item => newSelectedIds.includes(item.id)));
                    }
                    if (e.action === 'delete') {
                        setValue('properties', mappingList.filter(item => newSelectedIds.includes(item.id) && e.id !== item.id));
                    }
                }} />
            </div>
            <div className='row'>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'end', gap: '20px', padding: '20px 0' }}>
                    <Button type="bg-dark" onClick={() => { 
                        history.back();
                    }}>
                        Cancel
                    </Button>
                    {canUpdate() && <Button type="primary" onClick={() => {
                        submitForm();
                    }}>
                        Submit
                    </Button>}
                </div>
            </div>
        </div>
        
    </div>

}

export default UserForm;