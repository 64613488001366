import React from "react";
import Button from "components/Button/Button";


/**
 * A basic action button element.
 *
 * @param {String} type Button type, either submit or cancel.
 * @param {Function} action Action to happen after clicking the button.
 * @return {Component} A basic action button element.
*/
const ActionButton = ({ type, action = null, text = "", style = {} }) => {

    switch (type) {
        case "disabled":
            return <Button type="disabled" style={style} disabled>{text}</Button>;
        case "submit":
            return <Button type="primary" style={style} onClick={action}>{text}</Button>;
        case "cancel":
            return <Button type="bg-light" style={style} onClick={action}>{text}</Button>;
        default:
            return <div></div>;
    }
};

export default ActionButton;
