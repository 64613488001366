import constants from "../../constants";
import { ToastsStore } from "react-toasts";
import { createError } from "../../utils/index";



function properties(
    state = {
        loadingProperty: false,
        bgLoading: false,
        changingPropertyStatus: false,
        properties: [],
        inactiveProperties: [],
        error: "",
        currentSearchTerm: "",
        totalCount: 0,
        property: null,
        loading: true,
        notesSummary: [],
        notesLoading: false
    },
    action
) {
    let updatedProperty;
    let newProperties;
    let error;
    switch (action.type) {
        case constants.GET_PROPERTIES_REQUEST:
            return {
                ...state,
                loading: true,
                currentSearchTerm: action.searchTerm,
            };
        case constants.GET_PROPERTIES_SUCCESS:
            const newState = {
                ...state,
                loading: false
            };

            if (action.response.data.status === "active") {
                newState.properties = action.response.data.data;
            } else {
                newState.inactiveProperties = action.response.data.data;
            }

            return newState;
        case constants.GET_PROPERTIES_FAILURE:
            return {
                ...state,
                loading: false,
                error: createError(action.error),
            };
        case constants.GET_PROPERTY_REQUEST:
            return {
                ...state,
                loadingProperty: true,
                property: null,
            };
        case constants.GET_PROPERTY_SUCCESS:
            return {
                ...state,
                loadingProperty: false,
                property: action.response.data.data,
            };
        case constants.GET_PROPERTY_FAILURE:
            return {
                ...state,
                loadingProperty: false,
                error: createError(action.error),
            };
        case "CLEAR_PROPERTY":
            return {
                ...state,
                property: null,
                loadingProperty: false,
                error: "",
            };
        case constants.UPDATE_PROPERTY_REQUEST:
            return {
                ...state,
                bgLoading: true,
            };
        case constants.UPDATE_PROPERTY_SUCCESS:
            updatedProperty = [action.response.data.data];
            newProperties = state.properties.map(
                (property) =>
                    updatedProperty.find((o) => o._id === property._id) || property
            );
            ToastsStore.success("Successfully updated");
            return {
                ...state,
                bgLoading: false,
                properties: newProperties,
                property: updatedProperty[0]
            };
        case constants.UPDATE_PROPERTY_FAILURE:
            return {
                ...state,
                bgLoading: false,
                error: createError(action.error, "Update Property Failed"),
            };
        case constants.UPDATE_PROPERTY_STATUS_REQUEST:
            return {
                ...state,
                changingPropertyStatus: true,
            };
        case constants.UPDATE_PROPERTY_STATUS_SUCCESS:
            updatedProperty = [action.response.data.data];
            newProperties = state.properties.map(
                (property) =>
                    updatedProperty.find((o) => o._id === property._id) || property
            );
            ToastsStore.success("Successfully Updated Property");
            return {
                ...state,
                properties: newProperties,
                property: updatedProperty[0],
                changingPropertyStatus: false,
            };
        case constants.UPDATE_PROPERTY_STATUS_FAILURE:
            return {
                ...state,
                changingPropertyStatus: false,
                error: createError(action.error),
            };
        case constants.ADD_PROPERTIES_REQUEST:
            return {
                ...state,
                bgLoading: true,
            };
        case constants.ADD_PROPERTIES_SUCCESS:
            ToastsStore.success("Successfully added");
            return {
                ...state,
                bgLoading: false,
                properties: [...state.properties, action.response.data.data],
            };
        case constants.ADD_PROPERTIES_FAILURE:
            return {
                ...state,
                bgLoading: false,
                error: createError(action.error),
            };
        case constants.GET_NOTES_REQUEST:
            return {
                ...state,
                notesLoading: true,
                notesSummary: []
            };
        case constants.GET_NOTES_SUCCESS:
            return {
                ...state,
                notesLoading: false,
                notesSummary: action.response.data.data,
            };
        case constants.GET_NOTES_FAILURE:
            return {
                ...state,
                notesLoading: false,
                error: createError(action.error),
            };
        case constants.GET_HOTEL_BRANDS_FAILURE:
            return {
                ...state,
                error: createError(action.error),
            };
        case constants.CREATE_SEGMENTS_FAILURE:
            return {
                ...state,
                error: createError(action.error),
            };
        case constants.UPDATE_PROPERTY_SEGMENTS_SUCCESS:
            return {
                ...state,
                property: action.response.data.data,
            };
        case constants.UPDATE_PROPERTY_ROOM_TYPES_REQUEST:
            return {
                ...state,
                bgLoading: true,
            }
        case constants.UPDATE_PROPERTY_ROOM_TYPES_SUCCESS:
            switch (action.request_action) {
                case "add": ToastsStore.success("Successfully added property room type"); break;
                case "edit": ToastsStore.success("Successfully updated property room type"); break;
                case "delete": ToastsStore.success("Successfully deleted property room type"); break;
                default: ToastsStore.success("Successfully updated property room type");
            }
            return {
                ...state,
                bgLoading: false,
                property: action.response.data.data,
            }
        case constants.UPDATE_PROPERTY_ROOM_TYPES_FAILURE:
            error = action.error.response.data.message;
            if (action.error.response.status === 500)
                error = createError(error)
            else
                ToastsStore.error(error);
            return {
                ...state,
                bgLoading: false
            };
        case constants.UPDATE_PROPERTY_ROOM_TYPES_REQUEST:
            return {
                ...state,
                bgLoading: true,
            }
        case constants.UPDATE_PROPERTY_ROOM_TYPES_SUCCESS:
            switch (action.request_action) {
                case "add": ToastsStore.success("Successfully added property room type"); break;
                case "edit": ToastsStore.success("Successfully updated property room type"); break;
                case "delete": ToastsStore.success("Successfully deleted property room type"); break;
                default: ToastsStore.success("Successfully updated property room type");
            }
            return {
                ...state,
                bgLoading: false,
                property: action.response.data.data,
            }
        case constants.UPDATE_PROPERTY_ROOM_TYPES_FAILURE:
            error = action.error.response.data.message;
            if (action.error.response.status === 500)
                error = createError(error)
            else
                ToastsStore.error(error);
            return {
                ...state,
                bgLoading: false
            };
        case constants.UPDATE_PROPERTY_FORECAST_SETTINGS_REQUEST:
            return {
                ...state,
                bgLoading: true,
            }
        case constants.UPDATE_PROPERTY_FORECAST_SETTINGS_SUCCESS:
            switch (action.metric) {
                case "pickup": ToastsStore.success("Successfully updated property forecast settings: pickup"); break;
                case "pace": ToastsStore.success("Successfully updated property forecast settings: pace"); break;
                case "price": ToastsStore.success("Successfully updated property forecast settings: price"); break;
                case "finals": ToastsStore.success("Successfully updated property forecast settings: finals"); break;
                case "pricing_recommendation": ToastsStore.success("Successfully updated property forecast settings: pricing recommendation"); break;
                case "weights": ToastsStore.success("Successfully updated property forecast settings: weights"); break;
                default: ToastsStore.success("Successfully updated property forecast settings");
            }
            return {
                ...state,
                bgLoading: false,
                property: action.response.data.data,
            }
        case constants.UPDATE_PROPERTY_FORECAST_SETTINGS_FAILURE:
            let error = action.error.response.data.message;
            if (action.error.response.status === 500)
                error = createError(error)
            else
                ToastsStore.error(error);
            return {
                ...state,
                bgLoading: false
            };
        default:
            return state;
    }
}

export default properties;
