import { useState, useEffect, useMemo } from "react";
import SearchBar from "elements/Select/SearchBar";
import { Exit } from 'Icons';

const MappingList = ({ allItems = [], selectedItems = [], className = '', eventHandler }) => {
    
    const [searchBarValues, setSearchBarValues] = useState([]);

    const availableItems = useMemo(() => {
        const properties = [];
        const propertyGroups = [];
        // Get available items. Remove selected items from all items. 
        allItems.filter(property => {
            return !selectedItems.some(selectedItem => selectedItem.id == property.id);
        }).forEach(item => {
            const option = { id: item.id, name: item.name, code: item.code };
            if (item.type === 'PROPERTY') properties.push(option);
            if (item.type === 'PROPERTY_GROUP') propertyGroups.push(option);
        });

        return { properties, propertyGroups }

    }, [allItems, selectedItems]);

    const getSearchBarOptions = (items) => {
        const getOption = (item) => ({
            ...item, label: item.name, value: item.id
        });

        return [{
            label: 'Properties',
            options: items.properties.map(property => getOption(property))
        }, {
            label: 'Property Groups',
            options: items.propertyGroups.map(property => getOption(property))
        }];
    }
    
    return (
        <div className={`property-list ${className}`}>
            <h3 className='property-list-header'>Map Properties to User</h3>

            <SearchBar
                options={getSearchBarOptions(availableItems)}
                optionRender={({ data }) => <div>
                    <span>{data.name}</span>
                    <span>{data.code}</span>
                </div>}
                value={searchBarValues}
                placeholder={"Property or Group Code or Name"}
                notFoundContent={"No available properties to select"}
                handleFilter={(input, option) => {
                    if (!option.code) return; // Exclude group item.
                    const inputLowerCase = input.toLowerCase();
                    const nameLowerCase = option.label.toLowerCase();
                    const codeLowerCase = option.code.toLowerCase();
                    return (
                        nameLowerCase.includes(inputLowerCase) ||
                        codeLowerCase.includes(inputLowerCase)
                    );
                }}
                onAdd={() => setSearchBarValues([])}
                onChange={(values) => setSearchBarValues(values)}
                eventHandler={(e) => eventHandler(e)}
            />

            <table className="forms-table">
                <thead>
                    <tr key={1}>
                        <th>Name</th>
                        <th>Code</th>
                        <th>Type</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(selectedItems) && selectedItems.length ? (
                        selectedItems.map((item, index) => {
                            return (
                                <tr key={item.id}>
                                    <td>{item.name}</td>
                                    <td>{item.code}</td>
                                    <td>{item.type == "PROPERTY"? "Property": "Group"}</td>
                                    <td className="table-actions">
                                        <Exit
                                            onClick={() => {
                                                const event = { action: "delete", id: item.id };
                                                typeof eventHandler == "function" && eventHandler(event);
                                            }}
                                            width="16px"
                                            height="16px"
                                            className="pointer"
                                        />
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                            <tr>
                                <td>No Properties Found</td>
                            </tr>
                        )}
                </tbody>
            </table>
        </div>
    );
}

export default MappingList;