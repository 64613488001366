const {
    REACT_APP_API_URL,
    REACT_APP_AUTH0_DOMAIN,
    REACT_APP_AUTH0_APP_CLIENT_ID,
    REACT_APP_AUTH0_DB_CONNECTION
} = process.env;

const constants = {
    //BASE_URL:
    //"https://dev-ope-api.directwithhotels.com/api.php?XDEBUG_SESSION_START=ECLIPSE_DBGP&KEY=155737063478312",
    //"http://localhost:8000/api.php/?XDEBUG_SESSION_START=ECLIPSE_DBGP&KEY=155737063478312",
    BASE_URL: "https://api-dev.revandyou.com",
    BASE_URL_2: REACT_APP_API_URL,
    // BASE_URL_2: REACT_APP_API_URL,

    AUTH0_API: `https://${REACT_APP_AUTH0_DOMAIN}`,
    AUTH0_DB_CONNECTION: REACT_APP_AUTH0_DB_CONNECTION,
    AUTH0_APP_CLIENT_ID: REACT_APP_AUTH0_APP_CLIENT_ID,

    // dispatch types for chart view
    SET_CHART_DATA: "SET_CHART_DATA",
    UPDATE_CHART_DATA: "UPDATE_CHART_DATA",
    UPDATE_PLOT_TYPES: "UPDATE_PLOT_TYPES",
    UPDATE_DOW: "UPDATE_DOW",
    UPDATE_DAYS_OUT_VALUE: "UPDATE_DAYS_OUT_VALUE",
    ADD_NEW_DATE_RANGE: "ADD_NEW_DATE_RANGE",
    REMOVE_DATE_RANGE: "REMOVE_DATE_RANGE",
    ADD_SEGMENT: "ADD_SEGMENT",
    UPDATE_SEGMENTS: "UPDATE_SEGMENTS",
    ADD_SEGMENT_TO_GRAPH: "ADD_SEGMENT_TO_GRAPH",
    UPDATE_SEGMENT_TYPE: "UPDATE_SEGMENT_TYPE",
    RESET_CHART_FORM: "RESET_CHART_FORM",
    UPDATE_SEGMENTS_TOTALS: "UPDATE_SEGMENTS_TOTALS",
    UPDATE_SELECTED_CALENDAR_CELL: "UPDATE_SELECTED_CALENDAR_CELL",
    TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR",
    UPDATE_SIDEBAR_CONTENT: "UPDATE_SIDEBAR_CONTENT",
    UPDATE_COMPARISON_CONTENT: "UPDATE_COMPARISON_CONTENT",
    UPDATE_CALENDAR_SETTINGS: "UPDATE_CALENDAR_SETTINGS",
    UPDATE_CALENDAR_CACHE: "UPDATE_CALENDAR_CACHE",
    SET_SIDEBAR_CHART_DATA: "SET_SIDEBAR_CHART_DATA",
    SET_ISLOADING: "SET_ISLOADING",
    UPDATE_CALENDAR_DAILY: "UPDATE_CALENDAR_DAILY",
    UPDATE_CALENDAR_WEEKLY: "UPDATE_CALENDAR_WEEKLY",
    APPEND_END_CALENDAR_WEEKLY: "APPEND_END_CALENDAR_WEEKLY",
    APPEND_START_CALENDAR_WEEKLY: "APPEND_START_CALENDAR_WEEKLY",
    APPEND_START_CALENDAR_MONTHLY: "APPEND_START_CALENDAR_MONTHLY",
    UPDATE_CALENDAR_MONTHLY: "UPDATE_CALENDAR_MONTHLY",
    APPEND_CALENDAR_MONTHLY: "APPEND_CALENDAR_MONTHLY",
    LOADING_CALENDAR_DATA: "LOADING_CALENDAR_DATA",
    SET_CALENDAR_VIEW: "SET_CALENDAR_VIEW",
    SET_CALENDAR_LOADING: "SET_CALENDAR_LOADING",
    SET_CALENDAR_WITH_CHANGES: "SET_CALENDAR_WITH_CHANGES",
    TOGGLE_CALENDAR_TABLE_VIEW: "TOGGLE_CALENDAR_TABLE_VIEW",
    CHANGE_SHOWN_MAIN_DATA: "CHANGE_SHOWN_MAIN_DATA",
    STORE_DAILY_DATA: "STORE_DAILY_DATA",
    STORE_WEEKLY_DATA: "STORE_WEEKLY_DATA",
    STORE_MONTHLY_DATA: "STORE_MONTHLY_DATA",
    TOGGLE_ADR_STATE: "TOGGLE_ADR_STATE",
    UPDATE_ACTIVE_HOTEL: "UPDATE_ACTIVE_HOTEL",
    UPDATE_ACTIVE_HOTEL_CURRENCY: "UPDATE_ACTIVE_HOTEL_CURRENCY",
    UPDATE_SEGMENTS_IN_VIEW: "UPDATE_SEGMENTS_IN_VIEW",
    SET_UPLOAD_MODAL: "SET_UPLOAD_MODAL",

    // DASHBOARD METHODS
    SET_SOURCE: "SET_SOURCE",

    GET_DAILY_DATA_REQUEST: "GET_DAILY_DATA_REQUEST",
    GET_DAILY_DATA_SUCCESS: "GET_DAILY_DATA_SUCCESS",
    GET_DAILY_DATA_FAILURE: "GET_DAILY_DATA_FAILURE",

    GET_CUSTOM_DAILY_DATA_REQUEST: "GET_CUSTOM_DAILY_DATA_REQUEST",
    GET_CUSTOM_DAILY_DATA_SUCCESS: "GET_CUSTOM_DAILY_DATA_SUCCESS",
    GET_CUSTOM_DAILY_DATA_FAILURE: "GET_CUSTOM_DAILY_DATA_FAILURE",

    GET_WEEKLY_DATA_REQUEST: "GET_WEEKLY_DATA_REQUEST",
    GET_WEEKLY_DATA_SUCCESS: "GET_WEEKLY_DATA_SUCCESS",
    GET_WEEKLY_DATA_FAILURE: "GET_WEEKLY_DATA_FAILURE",

    GET_MONTHLY_DATA_REQUEST: "GET_MONTHLY_DATA_REQUEST",
    GET_MONTHLY_DATA_SUCCESS: "GET_MONTHLY_DATA_SUCCESS",
    GET_MONTHLY_DATA_FAILURE: "GET_MONTHLY_DATA_FAILURE",

    GET_DAILY_REQUEST: "GET_DAILY_REQUEST",
    GET_DAILY_SUCCESS: "GET_DAILY_SUCCESS",
    GET_DAILY_FAILURE: "GET_DAILY_FAILURE",

    GET_CURRENT_AVGS_DATA_REQUEST: "GET_CURRENT_AVGS_DATA_REQUEST",
    GET_CURRENT_AVGS_DATA_SUCCESS: "GET_CURRENT_AVGS_DATA_SUCCESS",
    GET_CURRENT_AVGS_DATA_FAILURE: "GET_CURRENT_AVGS_DATA_FAILURE",

    GET_FORECAST_AVGS_DATA_REQUEST: "GET_FORECAST_AVGS_DATA_REQUEST",
    GET_FORECAST_AVGS_DATA_SUCCESS: "GET_FORECAST_AVGS_DATA_SUCCESS",
    GET_FORECAST_AVGS_DATA_FAILURE: "GET_FORECAST_AVGS_DATA_FAILURE",

    GET_INSTANCES_AVGS_DATA_REQUEST: "GET_INSTANCES_AVGS_DATA_REQUEST",
    GET_INSTANCES_AVGS_DATA_SUCCESS: "GET_INSTANCES_AVGS_DATA_SUCCESS",
    GET_INSTANCES_AVGS_DATA_FAILURE: "GET_INSTANCES_AVGS_DATA_FAILURE",

    GET_SDLY_DATA_REQUEST: "GET_SDLY_DATA_REQUEST",
    GET_SDLY_DATA_SUCCESS: "GET_SDLY_DATA_SUCCESS",
    GET_SDLY_DATA_FAILURE: "GET_SDLY_DATA_FAILURE",

    GET_VARIANTS_DATA_REQUEST: "GET_VARIANTS_DATA_REQUEST",
    GET_VARIANTS_DATA_SUCCESS: "GET_VARIANTS_DATA_SUCCESS",
    GET_VARIANTS_DATA_FAILURE: "GET_VARIANTS_DATA_FAILURE",

    GET_INSTANCES_DATA_REQUEST: "GET_INSTANCES_DATA_REQUEST",
    GET_INSTANCES_DATA_SUCCESS: "GET_INSTANCES_DATA_SUCCESS",
    GET_INSTANCES_DATA_FAILURE: "GET_INSTANCES_DATA_FAILURE",

    GET_VARIANCES_BY_DAYSOUT_DATA_REQUEST: "GET_VARIANCES_BY_DAYSOUT_DATA_REQUEST",
    GET_VARIANCES_BY_DAYSOUT_DATA_SUCCESS: "GET_VARIANCES_BY_DAYSOUT_DATA_SUCCESS",
    GET_VARIANCES_BY_DAYSOUT_DATA_FAILURE: "GET_VARIANCES_BY_DAYSOUT_FAILURE",

    COMPUTE_FORECASTS_REQUEST: "COMPUTE_FORECASTS_REQUEST",
    COMPUTE_FORECASTS_SUCCESS: "COMPUTE_FORECASTS_SUCCESS",
    COMPUTE_FORECASTS_FAILURE: "COMPUTE_FORECASTS_FAILURE",

    GET_BOOKINGCHART_DATA_REQUEST: "GET_BOOKINGCHART_DATA_REQUEST",
    GET_BOOKINGCHART_DATA_SUCCESS: "GET_BOOKINGCHART_DATA_SUCCESS",
    GET_BOOKINGCHART_DATA_FAILURE: "GET_BOOKINGCHART_DATA_FAILURE",

    GET_COMPARISON_DATA_REQUEST: "GET_COMPARISON_DATA_REQUEST",
    GET_COMPARISON_DATA_SUCCESS: "GET_COMPARISON_DATA_SUCCESS",
    GET_COMPARISON_DATA_FAILURE: "GET_COMPARISON_DATA_FAILURE",


    //PROPERTIES METHODS
    GET_PROPERTIES_REQUEST: "GET_PROPERTIES_REQUEST",
    GET_PROPERTIES_SUCCESS: "GET_PROPERTIES_SUCCESS",
    GET_PROPERTIES_FAILURE: "GET_PROPERTIES_FAILURE",

    ADD_PROPERTIES_REQUEST: "ADD_PROPERTIES_REQUEST",
    ADD_PROPERTIES_SUCCESS: "ADD_PROPERTIES_SUCCESS",
    ADD_PROPERTIES_FAILURE: "ADD_PROPERTIES_FAILURE",
    GET_PROPERTY_REQUEST: "GET_PROPERTY_REQUEST",
    GET_PROPERTY_SUCCESS: "GET_PROPERTY_SUCCESS",
    GET_PROPERTY_FAILURE: "GET_PROPERTY_FAILURE",
    SEARCH_PROPERTIES_REQUEST: "SEARCH_PROPERTIES_REQUEST",
    SEARCH_PROPERTIES_SUCCESS: "SEARCH_PROPERTIES_SUCCESS",
    SEARCH_PROPERTIES_FAILURE: "SEARCH_PROPERTIES_FAILURE",
    UPDATE_PROPERTY_TABLE_SORTORDER: "UPDATE_PROPERTY_TABLE_SORTORDER",

    UPDATE_PROPERTY_REQUEST: "UPDATE_PROPERTY_REQUEST",
    UPDATE_PROPERTY_SUCCESS: "UPDATE_PROPERTY_SUCCESS",
    UPDATE_PROPERTY_FAILURE: "UPDATE_PROPERTY_FAILURE",

    UPDATE_PROPERTY_STATUS_REQUEST: "UPDATE_PROPERTY_STATUS_REQUEST",
    UPDATE_PROPERTY_STATUS_SUCCESS: "UPDATE_PROPERTY_STATUS_SUCCESS",
    UPDATE_PROPERTY_STATUS_FAILURE: "UPDATE_PROPERTY_STATUS_FAILURE",

    UPDATE_SEGMENT_STATUS_REQUEST: "UPDATE_SEGMENT_STATUS_REQUEST",
    UPDATE_SEGMENT_STATUS_SUCCESS: "UPDATE_SEGMENT_STATUS_SUCCESS",
    UPDATE_SEGMENT_STATUS_FAILURE: "UPDATE_SEGMENT_STATUS_FAILURE",

    DELETE_PROPERTIES: "DELETE_PROPERTIES",

    // PROPERTY GROUPS METHODS
    SET_BACKGROUND_LOADING: "SET_BACKGROUND_LOADING",
    NOTIFY_USER: "NOTIFY_USER",
    CLEAR_PROPERTY_GROUP: "CLEAR_PROPERTY_GROUP",
    CLEAR_PROPERTY_GROUPS: "CLEAR_PROPERTY_GROUPS",

    SET_PROPERTY_GROUPS: "SET_PROPERTY_GROUPS",
    SET_SELECTED_PROPERTY_GROUP: "SET_SELECTED_PROPERTY_GROUP",

    GET_PROPERTY_GROUPS_REQUEST: "GET_PROPERTY_GROUPS_REQUEST",
    GET_PROPERTY_GROUPS_SUCCESS: "GET_PROPERTY_GROUPS_SUCCESS",
    GET_PROPERTY_GROUPS_FAILURE: "GET_PROPERTY_GROUPS_FAILURE",

    GET_PROPERTY_GROUP_REQUEST: "GET_PROPERTY_GROUP_REQUEST",
    GET_PROPERTY_GROUP_SUCCESS: "GET_PROPERTY_GROUP_SUCCESS",
    GET_PROPERTY_GROUP_FAILURE: "GET_PROPERTY_GROUP_FAILURE",

    UPDATE_PROPERTY_GROUP_REQUEST: "UPDATE_PROPERTY_GROUP_REQUEST",
    UPDATE_PROPERTY_GROUP_SUCCESS: "UPDATE_PROPERTY_GROUP_SUCCESS",
    UPDATE_PROPERTY_GROUP_FAILURE: "UPDATE_PROPERTY_GROUP_FAILURE",

    GET_PROPERTIES_AND_GROUPS_REQUEST: "GET_PROPERTIES_AND_GROUPS_REQUEST",
    GET_PROPERTIES_AND_GROUPS_SUCCESS: "GET_PROPERTIES_AND_GROUPS_SUCCESS",
    GET_PROPERTIES_AND_GROUPS_FAILURE: "GET_PROPERTIES_AND_GROUPS_FAILURE",

    // NEW ACTIONS FOR REDUX
    // TODO: CLEAN UP CODES ABOVE
    //USER METHODS
    GET_AUTH_USER_REQUEST: "GET_AUTH_USER_REQUEST",
    GET_AUTH_USER_SUCCESS: "GET_AUTH_USER_SUCCESS",
    GET_AUTH_USER_FAILURE: "GET_AUTH_USER_FAILURE",

    DELETE_USERS: "DELETE_USERS",
    UPDATE_USERS: "UPDATE_USERS",
    GET_USERS_REQUEST: "GET_USERS_REQUEST",
    GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
    GET_USERS_FAILURE: "GET_USERS_FAILURE",

    GET_USER_REQUEST: "GET_USER_REQUEST",
    GET_USER_SUCCESS: "GET_USER_SUCCESS",
    GET_USER_FAILURE: "GET_USER_FAILURE",

    CREATE_USER_REQUEST: "CREATE_USER_REQUEST",
    CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
    CREATE_USER_FAILURE: "CREATE_USER_FAILURE",

    UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
    UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
    UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",

    UPDATE_USER_PREFERENCES_REQUEST: "UPDATE_USER_PREFERENCES_REQUEST",
    UPDATE_USER_PREFERENCES_SUCCESS: "UPDATE_USER_PREFERENCES_SUCCESS",
    UPDATE_USER_PREFERENCES_FAILURE: "UPDATE_USER_PREFERENCES_FAILURE",

    UPDATE_USER_PASSWORD_REQUEST: "UPDATE_USER_PASSWORD_REQUEST",
    UPDATE_USER_PASSWORD_SUCCESS: "UPDATE_USER_PASSWORD_SUCCESS",
    UPDATE_USER_PASSWORD_FAILURE: "UPDATE_USER_PASSWORD_FAILURE",

    UPDATE_USER_PROPERTY_REQUEST: "UPDATE_USER_PROPERTY_REQUEST",
    UPDATE_USER_PROPERTY_SUCCESS: "UPDATE_USER_PROPERTY_SUCCESS",
    UPDATE_USER_PROPERTY_FAILURE: "UPDATE_USER_PROPERTY_FAILURE",

    UPDATE_USER_PROFILE_REQUEST: "UPDATE_USER_PROFILE_REQUEST",
    UPDATE_USER_PROFILE_SUCCESS: "UPDATE_USER_PROFILE_SUCCESS",
    UPDATE_USER_PROFILE_FAILURE: "UPDATE_USER_PROFILE_FAILURE",

    GET_USER_CUSTOM_FILTERS_REQUEST: "GET_USER_CUSTOM_FILTERS_REQUEST",
    GET_USER_CUSTOM_FILTERS_SUCCESS: "GET_USER_CUSTOM_FILTERS_SUCCESS",
    GET_USER_CUSTOM_FILTERS_FAILURE: "GET_USER_CUSTOM_FILTERS_FAILURE",

    ADD_USER_CUSTOM_FILTERS_REQUEST: "ADD_USER_CUSTOM_FILTERS_REQUEST",
    ADD_USER_CUSTOM_FILTERS_SUCCESS: "ADD_USER_CUSTOM_FILTERS_SUCCESS",
    ADD_USER_CUSTOM_FILTERS_FAILURE: "ADD_USER_CUSTOM_FILTERS_FAILURE",

    DELETE_USER_CUSTOM_FILTERS_REQUEST: "DELETE_USER_CUSTOM_FILTERS_REQUEST",
    DELETE_USER_CUSTOM_FILTERS_SUCCESS: "DELETE_USER_CUSTOM_FILTERS_SUCCESS",
    DELETE_USER_CUSTOM_FILTERS_FAILURE: "DELETE_USER_CUSTOM_FILTERS_FAILURE",

    UPDATE_USER_CUSTOM_FILTERS_REQUEST: "UPDATE_USER_CUSTOM_FILTERS_REQUEST",
    UPDATE_USER_CUSTOM_FILTERS_SUCCESS: "UPDATE_USER_CUSTOM_FILTERS_SUCCESS",
    UPDATE_USER_CUSTOM_FILTERS_FAILURE: "UPDATE_USER_CUSTOM_FILTERS_FAILURE",

    SEND_RESET_PASSWORD_EMAIL_REQUEST: "SEND_RESET_PASSWORD_EMAIL_REQUEST",
    SEND_RESET_PASSWORD_EMAIL_SUCCESS: "SEND_RESET_PASSWORD_EMAIL_SUCCESS",
    SEND_RESET_PASSWORD_EMAIL_FAILURE: "SEND_RESET_PASSWORD_EMAIL_FAILURE",

    GET_HOTEL_CURRENCIES_REQUEST: "GET_HOTEL_CURRENCIES_REQUEST",
    GET_HOTEL_CURRENCIES_SUCCESS: "GET_HOTEL_CURRENCIES_SUCCESS",
    GET_HOTEL_CURRENCIES_FAILURE: "GET_HOTEL_CURRENCIES_FAILURE",

    GET_HOTEL_CURRENCY_REQUEST: "GET_HOTEL_CURRENCY_REQUEST",
    GET_HOTEL_CURRENCY_SUCCESS: "GET_HOTEL_CURRENCY_SUCCESS",
    GET_HOTEL_CURRENCY_FAILURE: "GET_HOTEL_CURRENCY_FAILURE",

    GET_HOTEL_BRANDS_REQUEST: "GET_HOTEL_BRANDS_REQUEST",
    GET_HOTEL_BRANDS_SUCCESS: "GET_HOTEL_BRANDS_SUCCESS",
    GET_HOTEL_BRANDS_FAILURE: "GET_HOTEL_BRANDS_FAILURE",

    ADD_HOTEL_BRAND_REQUEST: "ADD_HOTEL_BRAND_REQUEST",
    ADD_HOTEL_BRAND_SUCCESS: "ADD_HOTEL_BRAND_SUCCESS",
    ADD_HOTEL_BRAND_FAILURE: "ADD_HOTEL_BRAND_FAILURE",

    GET_HOTEL_COUNTRIES_REQUEST: "GET_HOTEL_COUNTRIES_REQUEST",
    GET_HOTEL_COUNTRIES_SUCCESS: "GET_HOTEL_COUNTRIES_SUCCESS",
    GET_HOTEL_COUNTRIES_FAILURE: "GET_HOTEL_COUNTRIES_FAILURE",

    GET_HOTEL_PROVINCES_REQUEST: "GET_HOTEL_PROVINCES_REQUEST",
    GET_HOTEL_PROVINCES_SUCCESS: "GET_HOTEL_PROVINCES_SUCCESS",
    GET_HOTEL_PROVINCES_FAILURE: "GET_HOTEL_PROVINCES_FAILURE",

    GET_HOTEL_PROVINCE_REQUEST: "GET_HOTEL_PROVINCE_REQUEST",
    GET_HOTEL_PROVINCE_SUCCESS: "GET_HOTEL_PROVINCE_SUCCESS",
    GET_HOTEL_PROVINCE_FAILURE: "GET_HOTEL_PROVINCE_FAILURE",

    GET_NOTES_REQUEST: "GET_NOTES_REQUEST",
    GET_NOTES_SUCCESS: "GET_NOTES_SUCCESS",
    GET_NOTES_FAILURE: "GET_NOTES_FAILURE",

    ADD_NOTE_REQUEST: "ADD_NOTE_REQUEST",
    ADD_NOTE_SUCCESS: "ADD_NOTE_SUCCESS",
    ADD_NOTE_FAILURE: "ADD_NOTE_FAILURE",

    UPDATE_NOTE_REQUEST: "UPDATE_NOTE_REQUEST",
    UPDATE_NOTE_SUCCESS: "UPDATE_NOTE_SUCCESS",
    UPDATE_NOTE_FAILURE: "UPDATE_NOTE_FAILURE",

    DELETE_NOTE_REQUEST: "DELETE_NOTE_REQUEST",
    DELETE_NOTE_SUCCESS: "DELETE_NOTE_SUCCESS",
    DELETE_NOTE_FAILURE: "DELETE_NOTE_FAILURE",

    ALLSEGMENTS: "ALL SEGMENTS",
    UNSEGMENTED: "UNSEGMENTED",
    COMBINED: "COMBINED",
    SEGMENTGROUPS: "SEGMENT-GROUPS",
    INDIVSEGMENTS: "SEGMENTS",

    ALLROOMTYPES: "ALL ROOM TYPES",

    UPDATE_PROPERTY_DATA: "UPDATE_PROPERTY_DATA",
    UPDATE_PROPERTY_IN_VIEW: "UPDATE_PROPERTY_IN_VIEW",

    UPDATE_PROPERTY_ROOM_TYPES_REQUEST: "UPDATE_PROPERTY_ROOM_TYPES_REQUEST",
    UPDATE_PROPERTY_ROOM_TYPES_SUCCESS: "UPDATE_PROPERTY_ROOM_TYPES_SUCCESS",
    UPDATE_PROPERTY_ROOM_TYPES_FAILURE: "UPDATE_PROPERTY_ROOM_TYPES_FAILURE",

    UPDATE_PROPERTY_ROOM_TYPES_BAR_REQUEST: "UPDATE_PROPERTY_ROOM_TYPES_BAR_REQUEST",
    UPDATE_PROPERTY_ROOM_TYPES_BAR_SUCCESS: "UPDATE_PROPERTY_ROOM_TYPES_BAR_SUCCESS",
    UPDATE_PROPERTY_ROOM_TYPES_BAR_FAILURE: "UPDATE_PROPERTY_ROOM_TYPES_BAR_FAILURE",

    UPDATE_PROPERTY_FORECAST_SETTINGS_REQUEST: "UPDATE_PROPERTY_FORECAST_SETTINGS_REQUEST",
    UPDATE_PROPERTY_FORECAST_SETTINGS_SUCCESS: "UPDATE_PROPERTY_FORECAST_SETTINGS_SUCCESS",
    UPDATE_PROPERTY_FORECAST_SETTINGS_FAILURE: "UPDATE_PROPERTY_FORECAST_SETTINGS_FAILURE",

    UPDATE_PROPERTY_SEGMENTS_REQUEST: "UPDATE_PROPERTY_SEGMENTS_REQUEST",
    UPDATE_PROPERTY_SEGMENTS_SUCCESS: "UPDATE_PROPERTY_SEGMENTS_SUCCESS",
    UPDATE_PROPERTY_SEGMENTS_FAILURE: "UPDATE_PROPERTY_SEGMENTS_FAILURE",

    GET_PROPERTY_PARTNER_MAPPINGS_REQUEST: "GET_PROPERTY_PARTNER_MAPPINGS_REQUEST",
    GET_PROPERTY_PARTNER_MAPPINGS_SUCCESS: "GET_PROPERTY_PARTNER_MAPPINGS_SUCCESS",
    GET_PROPERTY_PARTNER_MAPPINGS_FAILURE: "GET_PROPERTY_PARTNER_MAPPINGS_FAILURE",

    UPDATE_PROPERTY_PARTNER_MAPPINGS_REQUEST: "UPDATE_PROPERTY_PARTNER_MAPPINGS_REQUEST",
    UPDATE_PROPERTY_PARTNER_MAPPINGS_SUCCESS: "UPDATE_PROPERTY_PARTNER_MAPPINGS_SUCCESS",
    UPDATE_PROPERTY_PARTNER_MAPPINGS_FAILURE: "UPDATE_PROPERTY_PARTNER_MAPPINGS_FAILURE",

    GET_PROPERTY_ROOM_MAPPINGS_REQUEST: "GET_PROPERTY_ROOM_MAPPINGS_REQUEST",
    GET_PROPERTY_ROOM_MAPPINGS_SUCCESS: "GET_PROPERTY_ROOM_MAPPINGS_SUCCESS",
    GET_PROPERTY_ROOM_MAPPINGS_FAILURE: "GET_PROPERTY_ROOM_MAPPINGS_FAILURE",

    UPDATE_PROPERTY_ROOM_MAPPINGS_REQUEST: "UPDATE_PROPERTY_ROOM_MAPPINGS_REQUEST",
    UPDATE_PROPERTY_ROOM_MAPPINGS_SUCCESS: "UPDATE_PROPERTY_ROOM_MAPPINGS_SUCCESS",
    UPDATE_PROPERTY_ROOM_MAPPINGS_FAILURE: "UPDATE_PROPERTY_ROOM_MAPPINGS_FAILURE",

    GET_PROPERTY_SEGMENT_MAPPINGS_REQUEST: "GET_PROPERTY_SEGMENT_MAPPINGS_REQUEST",
    GET_PROPERTY_SEGMENT_MAPPINGS_SUCCESS: "GET_PROPERTY_SEGMENT_MAPPINGS_SUCCESS",
    GET_PROPERTY_SEGMENT_MAPPINGS_FAILURE: "GET_PROPERTY_SEGMENT_MAPPINGS_FAILURE",

    UPDATE_PROPERTY_SEGMENT_MAPPINGS_REQUEST: "UPDATE_PROPERTY_SEGMENT_MAPPINGS_REQUEST",
    UPDATE_PROPERTY_SEGMENT_MAPPINGS_SUCCESS: "UPDATE_PROPERTY_SEGMENT_MAPPINGS_SUCCESS",
    UPDATE_PROPERTY_SEGMENT_MAPPINGS_FAILURE: "UPDATE_PROPERTY_SEGMENT_MAPPINGS_FAILURE",

    GET_PROPERTY_SEGMENTS_REQUEST: "GET_PROPERTY_SEGMENTS_REQUEST",
    GET_PROPERTY_SEGMENTS_SUCCESS: "GET_PROPERTY_SEGMENTS_SUCCESS",
    GET_PROPERTY_SEGMENTS_FAILURE: "GET_PROPERTY_SEGMENTS_FAILURE",

    GET_PROPERTY_SEGMENT_REQUEST: "GET_PROPERTY_SEGMENT_REQUEST",
    GET_PROPERTY_SEGMENT_SUCCESS: "GET_PROPERTY_SEGMENT_SUCCESS",
    GET_PROPERTY_SEGMENT_FAILURE: "GET_PROPERTY_SEGMENT_FAILURE",
    
    ADD_PROPERTY_SEGMENT_REQUEST: "ADD_PROPERTY_SEGMENT_REQUEST",
    ADD_PROPERTY_SEGMENT_SUCCESS: "ADD_PROPERTY_SEGMENT_SUCCESS",
    ADD_PROPERTY_SEGMENT_FAILURE: "ADD_PROPERTY_SEGMENT_FAILURE",

    UPDATE_PROPERTY_SEGMENT_REQUEST: "UPDATE_PROPERTY_SEGMENT_REQUEST",
    UPDATE_PROPERTY_SEGMENT_SUCCESS: "UPDATE_PROPERTY_SEGMENT_SUCCESS",
    UPDATE_PROPERTY_SEGMENT_FAILURE: "UPDATE_PROPERTY_SEGMENT_FAILURE",

    DELETE_PROPERTY_SEGMENT_REQUEST: "DELETE_PROPERTY_SEGMENT_REQUEST",
    DELETE_PROPERTY_SEGMENT_SUCCESS: "DELETE_PROPERTY_SEGMENT_SUCCESS",
    DELETE_PROPERTY_SEGMENT_FAILURE: "DELETE_PROPERTY_SEGMENT_FAILURE",

    ADD_PROPERTY_SEGMENT_MAPPING_REQUEST: "ADD_PROPERTY_SEGMENT_MAPPING_REQUEST",
    ADD_PROPERTY_SEGMENT_MAPPING_SUCCESS: "ADD_PROPERTY_SEGMENT_MAPPING_SUCCESS",
    ADD_PROPERTY_SEGMENT_MAPPING_FAILURE: "ADD_PROPERTY_SEGMENT_MAPPING_FAILURE",

    UPDATE_PROPERTY_SEGMENT_MAPPING_REQUEST: "UPDATE_PROPERTY_SEGMENT_MAPPING_REQUEST",
    UPDATE_PROPERTY_SEGMENT_MAPPING_SUCCESS: "UPDATE_PROPERTY_SEGMENT_MAPPING_SUCCESS",
    UPDATE_PROPERTY_SEGMENT_MAPPING_FAILURE: "UPDATE_PROPERTY_SEGMENT_MAPPING_FAILURE",

    DELETE_PROPERTY_SEGMENT_MAPPING_REQUEST: "DELETE_PROPERTY_SEGMENT_MAPPING_REQUEST",
    DELETE_PROPERTY_SEGMENT_MAPPING_SUCCESS: "DELETE_PROPERTY_SEGMENT_MAPPING_SUCCESS",
    DELETE_PROPERTY_SEGMENT_MAPPING_FAILURE: "DELETE_PROPERTY_SEGMENT_MAPPING_FAILURE",

    ADD_PROPERTY_SEGMENT_GROUP_REQUEST: "ADD_PROPERTY_SEGMENT_GROUP_REQUEST",
    ADD_PROPERTY_SEGMENT_GROUP_SUCCESS: "ADD_PROPERTY_SEGMENT_GROUP_SUCCESS",
    ADD_PROPERTY_SEGMENT_GROUP_FAILURE: "ADD_PROPERTY_SEGMENT_GROUP_FAILURE",

    GET_PROPERTY_ROOM_TYPES_REQUEST: "GET_PROPERTY_ROOM_TYPES_REQUEST",
    GET_PROPERTY_ROOM_TYPES_SUCCESS: "GET_PROPERTY_ROOM_TYPES_SUCCESS",
    GET_PROPERTY_ROOM_TYPES_FAILURE: "GET_PROPERTY_ROOM_TYPES_FAILURE",

    GET_PROPERTY_ROOM_TYPE_REQUEST: "GET_PROPERTY_ROOM_TYPE_REQUEST",
    GET_PROPERTY_ROOM_TYPE_SUCCESS: "GET_PROPERTY_ROOM_TYPE_SUCCESS",
    GET_PROPERTY_ROOM_TYPE_FAILURE: "GET_PROPERTY_ROOM_TYPE_FAILURE",

    ADD_PROPERTY_ROOM_TYPE_REQUEST: "ADD_PROPERTY_ROOM_TYPE_REQUEST",
    ADD_PROPERTY_ROOM_TYPE_SUCCESS: "ADD_PROPERTY_ROOM_TYPE_SUCCESS",
    ADD_PROPERTY_ROOM_TYPE_FAILURE: "ADD_PROPERTY_ROOM_TYPE_FAILURE",

    UPDATE_PROPERTY_ROOM_TYPE_REQUEST: "UPDATE_PROPERTY_ROOM_TYPE_REQUEST",
    UPDATE_PROPERTY_ROOM_TYPE_SUCCESS: "UPDATE_PROPERTY_ROOM_TYPE_SUCCESS",
    UPDATE_PROPERTY_ROOM_TYPE_FAILURE: "UPDATE_PROPERTY_ROOM_TYPE_FAILURE",

    DELETE_PROPERTY_ROOM_TYPE_REQUEST: "DELETE_PROPERTY_ROOM_TYPE_REQUEST",
    DELETE_PROPERTY_ROOM_TYPE_SUCCESS: "DELETE_PROPERTY_ROOM_TYPE_SUCCESS",
    DELETE_PROPERTY_ROOM_TYPE_FAILURE: "DELETE_PROPERTY_ROOM_TYPE_FAILURE",

    ADD_PROPERTY_ROOM_MAPPING_REQUEST: "ADD_PROPERTY_ROOM_MAPPING_REQUEST",
    ADD_PROPERTY_ROOM_MAPPING_SUCCESS: "ADD_PROPERTY_ROOM_MAPPING_SUCCESS",
    ADD_PROPERTY_ROOM_MAPPING_FAILURE: "ADD_PROPERTY_ROOM_MAPPING_FAILURE",

    UPDATE_PROPERTY_ROOM_MAPPING_REQUEST: "UPDATE_PROPERTY_ROOM_MAPPING_REQUEST",
    UPDATE_PROPERTY_ROOM_MAPPING_SUCCESS: "UPDATE_PROPERTY_ROOM_MAPPING_SUCCESS",
    UPDATE_PROPERTY_ROOM_MAPPING_FAILURE: "UPDATE_PROPERTY_ROOM_MAPPING_FAILURE",

    DELETE_PROPERTY_ROOM_MAPPING_REQUEST: "DELETE_PROPERTY_ROOM_MAPPING_REQUEST",
    DELETE_PROPERTY_ROOM_MAPPING_SUCCESS: "DELETE_PROPERTY_ROOM_MAPPING_SUCCESS",
    DELETE_PROPERTY_ROOM_MAPPING_FAILURE: "DELETE_PROPERTY_ROOM_MAPPING_FAILURE",

    ADD_PROPERTY_COMPETITOR_REQUEST: "ADD_PROPERTY_COMPETITOR_REQUEST",
    ADD_PROPERTY_COMPETITOR_SUCCESS: "ADD_PROPERTY_COMPETITOR_SUCCESS",
    ADD_PROPERTY_COMPETITOR_FAILURE: "ADD_PROPERTY_COMPETITOR_FAILURE",

    UPDATE_PROPERTY_COMPETITOR_REQUEST: "UPDATE_PROPERTY_COMPETITOR_REQUEST", 
    UPDATE_PROPERTY_COMPETITOR_SUCCESS: "UPDATE_PROPERTY_COMPETITOR_SUCCESS",
    UPDATE_PROPERTY_COMPETITOR_FAILURE: "UPDATE_PROPERTY_COMPETITOR_FAILURE",

    DELETE_PROPERTY_COMPETITOR_REQUEST: "DELETE_PROPERTY_COMPETITOR_REQUEST",
    DELETE_PROPERTY_COMPETITOR_SUCCESS: "DELETE_PROPERTY_COMPETITOR_SUCCESS",
    DELETE_PROPERTY_COMPETITOR_FAILURE: "DELETE_PROPERTY_COMPETITOR_FAILURE",

    GET_VALID_ASOFS_REQUEST: "GET_VALID_ASOFS_REQUEST",
    GET_VALID_ASOFS_SUCCESS: "GET_VALID_ASOFS_SUCCESS",
    GET_VALID_ASOFS_FAILURE: "GET_VALID_ASOFS_FAILURE",

    GET_CHART_SEGMENTS_DATA_REQUEST: "GET_CHART_SEGMENTS_DATA_REQUEST",
    GET_CHART_SEGMENTS_DATA_SUCCESS: "GET_CHART_SEGMENTS_DATA_SUCCESS",
    GET_CHART_SEGMENTS_DATA_FAILURE: "GET_CHART_SEGMENTS_DATA_FAILURE",

    GET_CHART_COMP_PRICE_BY_DAYS_OUT_REQUEST: "GET_CHART_COMP_PRICE_BY_DAYS_OUT_REQUEST",
    GET_CHART_COMP_PRICE_BY_DAYS_OUT_SUCCESS: "GET_CHART_COMP_PRICE_BY_DAYS_OUT_SUCCESS",
    GET_CHART_COMP_PRICE_BY_DAYS_OUT_FAILURE: "GET_CHART_COMP_PRICE_BY_DAYS_OUT_FAILURE",

    GET_CHART_ASOFS_DATA_REQUEST: "GET_CHART_ASOFS_DATA_REQUEST",
    GET_CHART_ASOFS_DATA_SUCCESS: "GET_CHART_ASOFS_DATA_SUCCESS",
    GET_CHART_ASOFS_DATA_FAILURE: "GET_CHART_ASOFS_DATA_FAILURE",

    GET_CHART_MULTIPLE_ASOFS_DATA_REQUEST: "GET_CHART_MULTIPLE_ASOFS_DATA_REQUEST",
    GET_CHART_MULTIPLE_ASOFS_DATA_SUCCESS: "GET_CHART_MULTIPLE_ASOFS_DATA_SUCCESS",
    GET_CHART_MULTIPLE_ASOFS_DATA_FAILURE: "GET_CHART_MULTIPLE_ASOFS_DATA_FAILURE",

    GET_RESERVATION_LIST_REQUEST: "GET_RESERVATION_LIST_REQUEST",
    GET_RESERVATION_LIST_SUCCESS: "GET_RESERVATION_LIST_SUCCESS",
    GET_RESERVATION_LIST_FAILURE: "GET_RESERVATION_LIST_FAILURE",

    GET_STATISTICS_SEGMENTS_DATA_REQUEST: "GET_STATISTICS_SEGMENTS_DATA_REQUEST",
    GET_STATISTICS_SEGMENTS_DATA_SUCCESS: "GET_STATISTICS_SEGMENTS_DATA_SUCCESS",
    GET_STATISTICS_SEGMENTS_DATA_FAILURE: "GET_STATISTICS_SEGMENTS_DATA_FAILURE",

    SHOW_SEGMENTS_FEATURES: "SHOW_SEGMENTS_FEATURES",
    SHOW_ROOMTYPE_FEATURES: "SHOW_ROOMTYPE_FEATURES",
    SHOW_VISUALS: "SHOW_VISUALS",
    SHOW_VISUALS_TABLES: "SHOW_VISUALS_TABLES",
    UPDATE_VISUALS_SELECTED_TAB: "UPDATE_VISUALS_SELECTED_TAB",
    UPDATE_VISUALS_SEGMENTS_DATA: "UPDATE_VISUALS_SEGMENTS_DATA",
    UPDATE_VISUALS_ASOFS_DATA: "UPDATE_VISUALS_ASOFS_DATA",
    UPDATE_VISUALS_WATERFALL_SORTING: "UPDATE_VISUALS_WATERFALL_SORTING",
    UPDATE_VISUALS_BOOKING_CHART_SELECTED_DAYS_OUT: "UPDATE_VISUALS_BOOKING_CHART_SELECTED_DAYS_OUT",
    UPDATE_STATISTICS_SEGMENTS_DATA: "UPDATE_STATISTICS_SEGMENTS_DATA",
    SET_NOTES_MODAL: "SET_NOTES_MODAL",

    TOGGLE_TUTORIAL: "TOGGLE_TUTORIAL",
    TOGGLE_TUTORIAL_SECTION: "TOGGLE_TUTORIAL_SECTION",
    TOGGLE_CHART_MODAL_SECTION: "TOGGLE_CHART_MODAL_SECTION",

    UPDATE_USER_TUTORIAL: "UPDATE_USER_TUTORIAL",
    UPDATE_PACE_TYPE: "UPDATE_PACE_TYPE",
    /**
     * ADMIN INTEGRATION PARTNER
     */
    ADD_CHANNEL_REQUEST: 'ADD_CHANNEL_REQUEST',
    ADD_CHANNEL_SUCCESS: 'ADD_CHANNEL_SUCCESS',
    ADD_CHANNEL_FAILURE: 'ADD_CHANNEL_FAILURE',

    UPDATE_CHANNEL_REQUEST: 'UPDATE_CHANNEL_REQUEST',
    UPDATE_CHANNEL_SUCCESS: 'UPDATE_CHANNEL_SUCCESS',
    UPDATE_CHANNEL_FAILURE: 'UPDATE_CHANNEL_FAILURE',

    DELETE_CHANNEL_REQUEST: 'DELETE_CHANNEL_REQUEST',
    DELETE_CHANNEL_SUCCESS: 'DELETE_CHANNEL_SUCCESS',
    DELETE_CHANNEL_FAILURE: 'DELETE_CHANNEL_FAILURE',
    /**
     * ADMIN SEGMENTS
     */
    ADD_SEGMENT_REQUEST: 'ADD_SEGMENT_REQUEST',
    ADD_SEGMENT_SUCCESS: 'ADD_SEGMENT_SUCCESS',
    ADD_SEGMENT_FAILURE: 'ADD_SEGMENT_FAILURE',

    UPDATE_SEGMENT_REQUEST: 'UPDATE_SEGMENT_REQUEST',
    UPDATE_SEGMENT_SUCCESS: 'UPDATE_SEGMENT_SUCCESS',
    UPDATE_SEGMENT_FAILURE: 'UPDATE_SEGMENT_FAILURE',

    DELETE_SEGMENT_REQUEST: 'DELETE_SEGMENT_REQUEST',
    DELETE_SEGMENT_SUCCESS: 'DELETE_SEGMENT_SUCCESS',
    DELETE_SEGMENT_FAILURE: 'DELETE_SEGMENT_FAILURE',
    /**
     * ADMIN SEGMENT GROUPS
     */
    ADD_SEGMENT_GROUP_REQUEST: 'ADD_SEGMENT_GROUP_REQUEST',
    ADD_SEGMENT_GROUP_SUCCESS: 'ADD_SEGMENT_GROUP_SUCCESS',
    ADD_SEGMENT_GROUP_FAILURE: 'ADD_SEGMENT_GROUP_FAILURE',

    UPDATE_SEGMENT_GROUP_REQUEST: 'UPDATE_SEGMENT_GROUP_REQUEST',
    UPDATE_SEGMENT_GROUP_SUCCESS: 'UPDATE_SEGMENT_GROUP_SUCCESS',
    UPDATE_SEGMENT_GROUP_FAILURE: 'UPDATE_SEGMENT_GROUP_FAILURE',

    DELETE_SEGMENT_GROUP_REQUEST: 'DELETE_SEGMENT_GROUP_REQUEST',
    DELETE_SEGMENT_GROUP_SUCCESS: 'DELETE_SEGMENT_GROUP_SUCCESS',
    DELETE_SEGMENT_GROUP_FAILURE: 'DELETE_SEGMENT_GROUP_FAILURE',
    /**
     * ADMIN ROOM TYPES
     */
    ADD_ROOM_TYPE_REQUEST: 'ADD_ROOM_TYPE_REQUEST',
    ADD_ROOM_TYPE_SUCCESS: 'ADD_ROOM_TYPE_SUCCESS',
    ADD_ROOM_TYPE_FAILURE: 'ADD_ROOM_TYPE_FAILURE',

    UPDATE_ROOM_TYPE_REQUEST: 'UPDATE_ROOM_TYPE_REQUEST',
    UPDATE_ROOM_TYPE_SUCCESS: 'UPDATE_ROOM_TYPE_SUCCESS',
    UPDATE_ROOM_TYPE_FAILURE: 'UPDATE_ROOM_TYPE_FAILURE',

    DELETE_ROOM_TYPE_REQUEST: 'DELETE_ROOM_TYPE_REQUEST',
    DELETE_ROOM_TYPE_SUCCESS: 'DELETE_ROOM_TYPE_SUCCESS',
    DELETE_ROOM_TYPE_FAILURE: 'DELETE_ROOM_TYPE_FAILURE',
    /**
     * ADMIN BOOKING SOURCES
     */
    ADD_BOOKING_SOURCE_REQUEST: 'ADD_BOOKING_SOURCE_REQUEST',
    ADD_BOOKING_SOURCE_SUCCESS: 'ADD_BOOKING_SOURCE_SUCCESS',
    ADD_BOOKING_SOURCE_FAILURE: 'ADD_BOOKING_SOURCE_FAILURE',

    UPDATE_BOOKING_SOURCE_REQUEST: 'UPDATE_BOOKING_SOURCE_REQUEST',
    UPDATE_BOOKING_SOURCE_SUCCESS: 'UPDATE_BOOKING_SOURCE_SUCCESS',
    UPDATE_BOOKING_SOURCE_FAILURE: 'UPDATE_BOOKING_SOURCE_FAILURE',

    DELETE_BOOKING_SOURCE_REQUEST: 'DELETE_BOOKING_SOURCE_REQUEST',
    DELETE_BOOKING_SOURCE_SUCCESS: 'DELETE_BOOKING_SOURCE_SUCCESS',
    DELETE_BOOKING_SOURCE_FAILURE: 'DELETE_BOOKING_SOURCE_FAILURE',
    /**
     * ADMIN COMPETITORS
     */
    ADD_COMPETITOR_REQUEST: 'ADD_COMPETITOR_REQUEST',
    ADD_COMPETITOR_SUCCESS: 'ADD_COMPETITOR_SUCCESS',
    ADD_COMPETITOR_FAILURE: 'ADD_COMPETITOR_FAILURE',

    UPDATE_COMPETITOR_REQUEST: 'UPDATE_COMPETITOR_REQUEST',
    UPDATE_COMPETITOR_SUCCESS: 'UPDATE_COMPETITOR_SUCCESS',
    UPDATE_COMPETITOR_FAILURE: 'UPDATE_COMPETITOR_FAILURE',

    DELETE_COMPETITOR_REQUEST: 'DELETE_COMPETITOR_REQUEST',
    DELETE_COMPETITOR_SUCCESS: 'DELETE_COMPETITOR_SUCCESS',
    DELETE_COMPETITOR_FAILURE: 'DELETE_COMPETITOR_FAILURE',

    UPDATE_PARTNERS_MAPPING: "UPDATE_PARTNERS_MAPPING",

    SHOW_ALGORITHM: "SHOW_ALGORITHM",
    UPDATE_LIST_ITEM_IN_VIEW: "UPDATE_LIST_ITEM_IN_VIEW",
    UPDATE_TOOLS_SELECTED_TAB: "UPDATE_TOOLS_SELECTED_TAB",
    ENABLE_ALGORITHM_MODAL: "ENABLE_ALGORITHM_MODAL",
    ENABLE_DASHBOARD_MODAL: "ENABLE_DASHBOARD_MODAL",
    ENABLE_DASHBOARD_SIDEBAR: "ENABLE_DASHBOARD_SIDEBAR",

    UPDATE_SUMMARY_REQUEST: "UPDATE_SUMMARY_REQUEST",
    UPDATE_SUMMARY_SUCCESS: "UPDATE_SUMMARY_SUCCESS",
    UPDATE_SUMMARY_FAILURE: "UPDATE_SUMMARY_FAILURE",

    UPDATE_COMPARISONS_ENABLED: "UPDATE_COMPARISONS_ENABLED",
    UPDATE_COMPARISONS_LATEST: "UPDATE_COMPARISONS_LATEST",
    UPDATE_COMPARISONS_OPTIONS: "UPDATE_COMPARISONS_OPTIONS",

    CDV_FCST_DEMAND_SCORE: 'FORECAST_DEMAND_SCORE',
    CDV_FCST_PACE_SCORE: 'FORECAST_PACE_SCORE',
    CDV_FCST_PICKUP_SCORE: 'FORECAST_PICKUP_SCORE',
    CDV_CUSTOM_DATE_SELECTION: 'CUSTOM_DATE_SELECTION',
    CDV_NOTES_VIEW: 'NOTES',

    CDV_SORT_DESCENDING: 'SORT_DESCENDING',
    CDV_SORT_ASCENDING: 'SORT_ASCENDING',

    GET_ROLES_REQUEST: "GET_ROLES_REQUEST",
    GET_ROLES_SUCCESS: "GET_ROLES_SUCCESS",
    GET_ROLES_FAILURE: "GET_ROLES_FAILURE",

    GET_ROLE_REQUEST: "GET_ROLE_REQUEST",
    GET_ROLE_SUCCESS: "GET_ROLE_SUCCESS",
    GET_ROLE_FAILURE: "GET_ROLE_FAILURE",

    ADD_ROLE_REQUEST: "ADD_ROLE_REQUEST",
    ADD_ROLE_SUCCESS: "ADD_ROLE_SUCCESS",
    ADD_ROLE_FAILURE: "ADD_ROLE_FAILURE",
    
    UPDATE_ROLE_REQUEST: "UPDATE_ROLE_REQUEST",
    UPDATE_ROLE_SUCCESS: "UPDATE_ROLE_SUCCESS",
    UPDATE_ROLE_FAILURE: "UPDATE_ROLE_FAILURE",

    DELETE_ROLE_REQUEST: "DELETE_ROLE_REQUEST",
    DELETE_ROLE_SUCCESS: "DELETE_ROLE_SUCCESS",
    DELETE_ROLE_FAILURE: "DELETE_ROLE_FAILURE",

    GET_ROLE_READABLE_PERMISSIONS_REQUEST: "GET_ROLE_READABLE_PERMISSIONS_REQUEST",
    GET_ROLE_READABLE_PERMISSIONS_SUCCESS: "GET_ROLE_READABLE_PERMISSIONS_SUCCESS",
    GET_ROLE_READABLE_PERMISSIONS_FAILURE: "GET_ROLE_READABLE_PERMISSIONS_FAILURE"
};

const enums = {
    DAYS_OUT_OPTIONS: [
        { days_out: 30, chart_interval: 1 }, 
        { days_out: 60, chart_interval: 2 }, 
        { days_out: 90, chart_interval: 3 }
    ],
    CUSTOM_DASHBOARD_VIEW_OPTIONS: [{
        label: "Top 50 High Demand Dates",
        code: constants.CDV_FCST_DEMAND_SCORE,
        value: constants.CDV_SORT_DESCENDING
    }, {
        label: "Top 50 Low Demand Dates",
        code: constants.CDV_FCST_DEMAND_SCORE,
        value: constants.CDV_SORT_ASCENDING
    }, {
        label: "Top 50 High Pace RNS",
        code: constants.CDV_FCST_PACE_SCORE,
        value: constants.CDV_SORT_DESCENDING
    }, {
        label: "Top 50 Low Pace RNS",
        code: constants.CDV_FCST_PACE_SCORE,
        value: constants.CDV_SORT_ASCENDING
    }, {
        label: "Top 50 High Pick-Up Dates",
        code: constants.CDV_FCST_PICKUP_SCORE,
        value: constants.CDV_SORT_DESCENDING
    }, {
        label: "Custom Selected Dates",
        code: constants.CDV_CUSTOM_DATE_SELECTION,
        value: []
    }, {
        label: "Holiday Notes View",
        code: constants.CDV_NOTES_VIEW,
        value: "HOLIDAY"
    }, {
        label: "Blocking Notes View",
        code: constants.CDV_NOTES_VIEW,
        value: "BLOCKING"
    }, {
        label: "Alert Notes View",
        code: constants.CDV_NOTES_VIEW,
        value: "ALERT"
    }]
}

export default { ...constants, ...enums }