import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// import moment from "moment";
import dayjs from 'dayjs';

import Button from "../../Button/Button";
import NotesNotif from "../NotesNotif";
import { Affix } from "antd";
import { ExportCalendarButton } from "../../ExportData";
import { TutorialSpot } from "../../Tutorial/index";
import { negativeToBrackets, formatData, withCurrency, percent, getPaceTitle, getPaceRate } from "../../../utils";
import { getAggregatePaceValue, getAggregatePaceAdrValue, isNullOrUndefined } from "../../../utils";
import { moveArrayItem } from "../../../utils/data";
import useHelperFunctions from "store/actions/actions";
import usePermissions from "hooks/usePermissions";
import OutlinedIcon from "elements/Icon/OutlinedIcon";


const AggregateTable = ({ type, data, onClick, isLoading, loadMore }) => {

    const { canAccess: canAccessExport } = usePermissions('property', 'report_export');
    const state = useSelector((state) => state.mainReducer);
    const { calendar_data, currency, ADRState } = state;
    const isWeek = (type === "week");
    const isGroup = state.isGroup;

    const users = useSelector((state) => state.users);
    const userPrefs = users.authUser.prefs;
    const usePercentsForOccs = isGroup && !isNullOrUndefined(userPrefs) && (userPrefs.usePercentsForOccs ?? true);
    const { setNotesModalState } = useHelperFunctions();

    const withColor = (item, key, col, data = null) => {
        const withCurrencyArr = ["pickup_adr", "average_sell_rate", "variance_adr", "pace_adr", "rate"];
        let pct = (usePercentsForOccs && (col === "pick_up" || col === "pace")) ? '%' : '';
        let value = (key === null) ? data : item[key];
        if (withCurrencyArr.indexOf(key) !== -1) {
            value = withCurrency(formatData(value), currency ?? item.currency);
        } else if (key === "pickup_rooms_sold_computed" || key === "variance_occ") {
            value = (value > 0 ? "+" : "") + value;
        }
        if (value === null || value === "") value = "-"

        return <div className={`${item.individualColor[col]}`}>{value}{pct}</div>;
    };

    const renderNoteIcon = (dataItem) => {
        if (!dataItem.note_type) return <></>;
        return <NotesNotif type={ dataItem?.note_type } style={{ marginRight: '-2px' }} onClick={(e) => {
            e.stopPropagation();
            setNotesModalState({ 
                visible: true,
                startDate: dataItem.dateRange[0],
                endDate: dataItem.dateRange[1]
            });
        }} />
    }


    let aggregateColumns = [
        {
            key: "color",
            label: "Demand",
        },
        {
            key: usePercentsForOccs ? "pct_pickup" : "pickup",
            label: "Pick up",
            renderer: (item, key) => withColor(item, key, "pick_up"),
        },
        {
            key: "pickup_adr",
            label: "Pick up ADR",
            renderer: (item, key) => withCurrency(formatData(item[key]), currency ?? item.currency),
        },
        {
            key: usePercentsForOccs ? "pct_pace" : "pace",
            label: "Pace Variance",
            renderer: (item, key) => withColor(item, null, "pace", getAggregatePaceValue(calendar_data.pace_string, item, usePercentsForOccs)),
        },
        {
            key: "pace_adr",
            label: "Pace ADR Variance",
            renderer: (item, key) => withCurrency(getAggregatePaceAdrValue(calendar_data.pace_string, item), currency ?? item.currency),
        },
        {
            key: "rate",
            label: "Price",
            renderer: (item, key) => withColor(item, key, "pick_up"),
        },
        {
            key: "pickup_rate",
            label: "Price " + calendar_data.pick_up.substring(1) + " days",
            renderer: (item, key) => withCurrency(formatData(item[key]), currency ?? item.currency),
        },
        {
            key: "pace_rate",
            label: "Price " + getPaceTitle(calendar_data.pace_string),
            renderer: (item, key) => getPaceRate(calendar_data.pace_string, item, currency ?? item.currency),
        },
        {
            key: "total_occ",
            label: "OTB OCC%",
            renderer: (item, key) => {
                return percent(Math.round(
                    (item[key] / item["total_inventory"]) * 100
                ));
            },
        },
        {
            key: "adr",
            label: "OTB ADR",
            renderer: (item, key) => withCurrency(formatData(item[key]), currency ?? item.currency),
        },
        {
            key: "total_rev",
            label: "OTB revenue",
            renderer: (item, key) => withCurrency(formatData(item[key]), currency ?? item.currency),
        }
    ];

    const weeklyColumns = [...aggregateColumns, {
        key: "week_range",
        label: "Date",
        renderer: (item, key) => {
            let dateRanges = "";

            if (item[key][0]) {
                dateRanges = `${dayjs(item[key][0]).format("MMM D")} - ${dayjs(
                    item[key][1]
                ).format("MMM D")}`;
            } else {
                dateRanges = `${dayjs()
                    .year(item.year)
                    .isoWeek(item.week)
                    .day("Monday")
                    .format("MMM D")} - ${dayjs()
                        .year(item.year)
                        .isoWeek(item.week)
                        .day("Monday")
                        .add(6, "days")
                        .format("MMM D")}`;
            }

            return (
                <span style={{ fontSize: "14px", height: "50px" }}>
                    <span style={{ display: "block" }}>{dateRanges}</span>
                </span>
            );
        },
    }, {
        key: "note_type",
        label: "Note",
        renderer: (item, key) => renderNoteIcon(item),
    }];

    const monthlyColumns = [...aggregateColumns, {
        key: "month",
        label: "Date",
    }, {
        key: "note_type",
        label: "Note",
        renderer: (item, key) => renderNoteIcon(item),
    },];


    const ADRarr = ["Pick up ADR", "ADR", "Pace ADR", "OTB ADR", "Pace ADR Variance"];
    let columns = isWeek ? weeklyColumns : monthlyColumns;
    columns = moveArrayItem(columns, 11, 1);
    let headers = columns.map(column => column.label);
    if (!ADRState) {
        headers = headers.filter(item => ADRarr.indexOf(item) === -1);
        columns = columns.filter(item => ADRarr.indexOf(item.label) === -1);
    }


    const [dataSource, setDataSource] = useState([]);
    useEffect(() => {
        if (data) setDataSource(data);
    }, [data]);


    const renderTableData = () => {
        if (dataSource.length) {
            return dataSource.sort((a, b) => {
                return new Date(a.dateRange[0]) - new Date(b.dateRange[0]);
            }).map((item, index) => {
                const highlight = item.selected ? "active" : "";
                return (
                    <tr className={`table-row ${highlight}`} key={index} onClick={() => onClick(index)}>
                        {columns.map((value, i) => {
                            // for forecast color
                            if (value.key === "color") {
                                return (
                                    <td className="status-box" key={i}>
                                        <div className={`${item[value.key]}`}></div>
                                    </td>
                                );
                            }
                            // if with custom renderer
                            if (value.renderer) {
                                return (
                                    <td key={i}>
                                        <span>{value.renderer(item, value.key)}</span>
                                    </td>
                                );
                            }

                            return (
                                <td key={i}>
                                    <span>{negativeToBrackets(item[value.key])}</span>
                                </td>
                            );
                        })}
                    </tr>
                );
            });
        }
    };



    return (
        <div className="aggregate-table-container">
            <div>
                <Affix offsetTop={70}>
                    <table>
                        <thead className="tableTutorial">
                            {state.tutorial.table.showSpot && (
                                <TutorialSpot left="-10px" bottom="8px" section="overviewCalendar" type="table" />
                            )}
                            <tr>
                                {headers.map((header, index) => (
                                    <th className={header} key={header}>
                                        {header.toUpperCase()}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                    </table>
                </Affix>
                <table>
                    <tbody>{renderTableData()}</tbody>
                </table>
            </div>
            {loadMore && dataSource.length > 0 && (
                <div className="load-more-container">
                    <div className='load-more-containter-navigator'>
                        <OutlinedIcon type='left' style={{ fontSize: '20px' }} onClick={() => loadMore("start")} />
                        {/* <span className='load-more-containter-prev-nav'>Prev</span> */}
                    </div>
                    
                    <span className='load-more-container-text' style={{ textTransform: 'uppercase', fontSize: '18px', fontWeight: '600'}}>
                        Load More
                    </span>
                    <div className='load-more-containter-navigator'>
                        {/* <span className='load-more-container-next-nav'>Next</span> */}
                        <OutlinedIcon type='right' style={{ fontSize: '20px' }} onClick={() => loadMore("end")} />
                    </div>
                   
                    {/* <Button onClick={() => loadMore("end")}>
                        {isLoading ? "..." : "LOAD MORE"}
                    </Button> */}
                    <ExportCalendarButton hasAccess={canAccessExport()} className="pointer" style={{ position: "absolute", right: 0 }} data={data} />
                </div>
            )}
        </div>
    )
};

export default AggregateTable;
