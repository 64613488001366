import * as React from "react";

function SvgGraph(props) {
    const fill = props.fill?? '#51b68a';

    return (
        <svg 
            viewBox="0 0 24.00 24.00" 
            width="210px" 
            height="210px" 
            id="chart-column-grow" 
            xmlns="http://www.w3.org/2000/svg" 
            className="icon flat-color" 
            transform="rotate(0)matrix(1, 0, 0, 1, 0, 0)"
            { ...props}
            fill="#000000"
        >   
            <g strokeWidth="0"></g>
            <g strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="0.192"></g>
            <g>
                <path id="secondary" d="M22,6.76l-1-4A1,1,0,0,0,20,2H16a1,1,0,0,0,0,2h2.12C13.79,9.69,5.9,11,3,11a1,1,0,0,0,0,2c3.16,0,11.71-1.45,16.56-7.63L20,7.24A1,1,0,1,0,22,6.76Z" style={{ fill: fill }}></path>
                <path id="primary" d="M23,11V21a1,1,0,0,1-1,1H19a1,1,0,0,1-1-1V11a1,1,0,0,1,1-1h3A1,1,0,0,1,23,11Zm-9,3H11a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h3a1,1,0,0,0,1-1V15A1,1,0,0,0,14,14ZM6,16H3a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H6a1,1,0,0,0,1-1V17A1,1,0,0,0,6,16Z" style={{ fill: fill }}></path>
            </g>
        </svg>
    );
}

export default SvgGraph;
