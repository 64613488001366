/**
 * @author Jarbee Bejar
 */
import { useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';

const useForm = ({ data = {}, formFields = [], onSubmit }) => {
    const [fields, setFields] = useState(formFields.map(field => {
        return { ...field, 
            key: field.key?? null,
            value: field?.defaultValue?? null,
            validations: field?.validations?? {},
            touched: false,
            edited: false,
            errors: {},
        }
    }));
    const [rawData, setRawData] = useState(data);
    
    const values = useMemo(() => {
        return fields.reduce((allValues, field) => {
            return { ...allValues, [field.key]: field.value };
        }, {});
    }, [fields]);

    const editedValues = useMemo(() => {
        return fields.filter(field => field.edited).reduce((values, field) => {
            return { ...values, [field.key]: field.value };
        }, {});
    }, [fields]); 

    const touchedValues = useMemo(() => {
        return fields.filter(field => field.touched).reduce((values, field) => {
            return { ...values, [field.key]: field.value };
        }, {});
    }, [fields]);

    const errors = useMemo(() => {
        return fields.filter(field => field?.errors && Object.keys(field.errors).length > 0).reduce((errors, field) => {
            return { ...errors, [field.key]: field.errors };
        }, {});
    }, [fields]);
    
    const setFormData = (formData, reset = false) => {
        const newData = { ...formData };
        setFields(prev => {
            return formFields.map(field => {
                return { ...field, 
                    key: field.key?? null,
                    value: (reset? newData[field.key]: field?.value)?? field?.defaultValue,
                    validations: field?.validations?? {},
                    touched: reset? false: field?.touched,
                    edited: reset? false: field?.edited,
                    errors: reset? {}: field?.errors
                }
            })
        });
    }

    useEffect(() => {
        setFormData(rawData, true);
    }, [rawData]);

    const loadFormData = (formData, reset = true) => {
        setFormData(formData, reset);
        setRawData(formData);
    }

    const clearFormData = () => {
        setFormData({}, true);
        setRawData({});
    }

    const reloadFormData = () => {
        setFormData(rawData, true);
    }

    const setFormFields = (formFields) => {
        setFields(formFields);
    }

    const validate = (field) => {
        const { validations = {}, value, type } = field;
        const errors = {};
        switch(type.toUpperCase()) {
            case 'BOOLEAN':
                if (validations?.required === true && (value === null || value === undefined)) {
                    errors.required = `This field is required`; 
                    break;
                }
            case 'DATE':
                if (validations?.required === true && (value?? "").length === 0) {
                    errors.required = `This field is required`; 
                    break;
                }
                if (value && !dayjs(value).isValid()) {
                    errors.required = `This field accepts date value only`;
                }
                if (validations?.max && dayjs(value).isAfter(validations?.max)) {
                    errors.max = `This field's value is greater than maximum date allowed (${dayjs(validations?.max).format("MMM DD, YYYY")})`;
                }
                if (validations?.min && dayjs(value).isBefore(validations?.min)) {
                    errors.min = `This field's value is less than minimum number allowed (${dayjs(validations?.min).format("MMM DD, YYYY")})`;
                }
                break;
            case 'STRING':
                if (validations?.required === true && (value?? "").length === 0) {
                    errors.required = `This field is required`; 
                    break;
                }
                if (validations?.limit && (value?? "").length > validations.limit) {
                    errors.limit = `This field exceeded its maximum number of characters allowed (${validations?.limit})`
                }
                if (validations?.enum && !validations.enum.includes(value)) {
                    errors.enum = `This field only accept required values (${validations.enum.join(', ')})`
                }
                break;
            case 'NUMBER': 
                if (isNaN(parseFloat(value))) {
                    errors.type = `This field requires NUMBER value`;
                    break;
                }
                if (validations?.required === true && (value === null || value === undefined)) {
                    errors.required = `This field is required`; 
                    break;
                }
                if (validations?.max > 0 && value > validations?.max) {
                    errors.max = `This field's value is greater than maximum number allowed (${validations?.max})`;
                }
                if (validations?.min < 0 && value < validations?.min) {
                    errors.min = `This field's value is less than minimum number allowed (${validations?.min})`;
                }
                if (validations?.enum && !validations.enum.includes(value)) {
                    errors.enum = `This field only accept required values (${validations.enum.join(', ')})`
                }
                break;
        }
        return errors;
    }

    const validateField = (key) => {
        const field = fields.find(field => field.key === key);
        if (!field || !Array.isArray(field?.validations)) return;
        return validate(field);
    }

    const validateAll = () => {
        const validatedFields = fields.map(field => {
            if (Object.keys(field.validations).length === 0) return field;
            field.errors = validate(field);
            return field;
        });

        setFields(validatedFields);
        
        return validatedFields;
    }

    const setValue = (key, value, autoValidate = false) => {
        setFields(prev => prev.map(field => {
            if (field.key === key) {
                field.value = value;

                field.edited = rawData[key] !== field.value;
                field.touched = true;

                if (autoValidate) {
                    field.errors = validate(field);
                }
            }

            return field;
        }))
    }

    const getFormData = (raw = false) => {
        return { ...rawData, ...values, ...editedValues };
    }

    const submitForm = (strict = true) => {

        if (strict === true) {
            if (validateAll().some(field => Object.keys(field.errors).length > 0)) {
                console.error(validateAll().filter(field => Object.keys(field.errors).length > 0));
                return;
            }
        }

        onSubmit(values);
    }

    return {
        loadFormData,
        reloadFormData,
        clearFormData,
        setFormFields,
        setValue,
        validate,
        validateField,
        validateAll,
        getFormData,
        submitForm,
        values,
        editedValues,
        touchedValues,
        errors,
        fields,
    }

}

export default useForm;