import { useDispatch } from "react-redux";
import { useAuth0 } from "../../react-auth0-spa";
import constants from '../../constants';
import axios from "axios";

const useRoleActions = () => {
    const dispatch = useDispatch();
    const { getTokenSilently } = useAuth0();

    const getRoles = async (name = "", sort = "", page = 1, limit) => {
        const token = await getTokenSilently();
        const params = { name, page, limit, sort };
        const result = dispatch({
            types: [
                constants.GET_ROLES_REQUEST,
                constants.GET_ROLES_SUCCESS,
                constants.GET_ROLES_FAILURE,
            ],
            callAPI: () => axios.get(`${constants.BASE_URL_2}/roles`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: params
            }),
            payload: {
                searchTerm: name,
            }
        });
        return result;
    }

    const getRole = async (id) => {
        const token = await getTokenSilently();
        const result = dispatch({
            types: [
                constants.GET_ROLE_REQUEST,
                constants.GET_ROLE_SUCCESS,
                constants.GET_ROLE_FAILURE,
            ],
            callAPI: () => axios.get(`${constants.BASE_URL_2}/roles/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
        });
        return result;
    }

    const getRoleReadablePermissions = async (id) => {
        const token = await getTokenSilently();
        const result = dispatch({
            types: [
                constants.GET_ROLE_READABLE_PERMISSIONS_REQUEST,
                constants.GET_ROLE_READABLE_PERMISSIONS_SUCCESS,
                constants.GET_ROLE_READABLE_PERMISSIONS_FAILURE,
            ],
            callAPI: () => axios.get(`${constants.BASE_URL_2}/roles/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
        });
        return result;
    }

    const addRole = async (data) => {
        const token = await getTokenSilently();

        const result = dispatch({
            types: [
                constants.ADD_ROLE_REQUEST,
                constants.ADD_ROLE_SUCCESS,
                constants.ADD_ROLE_FAILURE,
            ],
            callAPI: () => axios.post(`${constants.BASE_URL_2}/roles`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
        });
        return result;
    }

    const updateRole = async (id, data) => {
        const token = await getTokenSilently();
        const result = dispatch({
            types: [
                constants.UPDATE_ROLE_REQUEST,
                constants.UPDATE_ROLE_SUCCESS,
                constants.UPDATE_ROLE_FAILURE,
            ],
            callAPI: () => axios.patch(`${constants.BASE_URL_2}/roles/${id}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
        });
        return result;
    }

    const deleteRole = async (id) => {
        const token = await getTokenSilently();
        const result = dispatch({
            types: [
                constants.DELETE_ROLE_REQUEST,
                constants.DELETE_ROLE_SUCCESS,
                constants.DELETE_ROLE_FAILURE,
            ],
            callAPI: () => axios.delete(`${constants.BASE_URL_2}/roles/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
        });
        return result;
    }

    return {
        getRoles, getRole, getRoleReadablePermissions, 
        addRole, updateRole, deleteRole
    }
}


export default useRoleActions;