import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Modal, DatePicker } from 'antd';
import { Exit } from 'Icons';
import DataGrid from 'components/DataGrid';
import Error from 'elements/Text/Error';
import ActionButton from 'elements/Button/Action';
// import moment from 'moment';
import dayjs from 'dayjs';
import './CustomAvgDatesModal.scss';

const CustomAvgDatesModal = ({ isVisible, isViewOnly, items = [], headerCopy, width = 500, onSubmit, onCancel }) => {
    const [originalItems, setOriginalItems] = useState(items?? []);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [dates, setDates] = useState([]);
    const [modalErrors, setModalErrors] = useState([]);
    const isModified = useMemo(() => {
        if (originalItems.length != dates.length) return true;
        return !originalItems.every(origItem => {
            return dates.find(item => dayjs(item.start_date).isSame(origItem.start_date, 'day') && 
                dayjs(item.end_date).isSame(origItem.end_date, 'day')
            );
        });
    }, [originalItems, dates]);
    
    const gridRef = useRef(null);

    useEffect(() => {
        if (isVisible === true) {
            const initialDates = (Array.isArray(items)? items: []).map(item => ({ 
                start_date: dayjs(item.start_date), 
                end_date: dayjs(item.end_date) 
            }) );
            setStartDate(null);
            setEndDate(null);
            setModalErrors([]);
            setOriginalItems(initialDates);
            setDates(initialDates);
        }
    }, [isVisible]);    

    useEffect(() => {
        if (isVisible) {
            gridRef.current.loadData(dates);
        }
    }, [dates])

    const addDate = () => {        
        const errors = [];
        const addError = (id, message) => errors.push({ id, message });

        const conflicts = dates.filter(item => {
            if (dayjs(item.start_date).isBetween(startDate, endDate, undefined, "[]")) 
                return true;
            if (dayjs(item.end_date).isBetween(startDate, endDate, undefined, "[]")) 
                return true;
            if (dayjs(item.start_date).isBefore(startDate) && dayjs(item.end_date).isAfter(endDate)) 
                return true;

            return false;
        });

        if ((startDate || endDate) && conflicts.length > 0)
            conflicts.forEach(conflict => {
                const msg = `Conflict with ${dayjs(conflict.start_date).format("MMMM DD YYYY")}` + 
                    ` - ${dayjs(conflict.end_date).format("MMMM DD YYYY")}`;
                addError('date_range', msg);
            });

        if (startDate == null)
            addError('date_range', "Please provide start date"); 

        if (endDate == null)
            addError('date_range', "Please provide end date");
        
        setModalErrors(prev => [...prev.filter(err => err.id !== 'date_range'), ...errors]);
        
        if (errors.length === 0) {
            setStartDate(null);
            setEndDate(null);
            setDates([...dates, { start_date: startDate, end_date: endDate }]);
        }
    }

    return <Modal className="custom-avg-dates-modal" 
        open={isVisible} width={width} footer={null} maskClosable={false} onCancel={onCancel} 
        closeIcon={<Exit width="25px" height="25px" className="pointer" />}
        forceRender={true} // forceRender is needed to render the DataGrid without opening the modal. Ref will not be set if DataGrid is not rendered.
    >
            <div className="rms-modal-content">
                {/* Header */}
                <div className="rms-modal-header"><h3>{headerCopy}</h3></div>
                <DataGrid 
                    ref={gridRef} 
                    headerHeight='35px'
                    defaultCellHeight='30px'
                    dividerHeight='2px' 
                    emptyRowContent={<div style={{gridColumn: '1 / -1', color: "#808285", justifyContent: 'center'}}>No dates configured yet</div>}
                    style={{ height: "200px" }}
                    columns={[{
                        id: 'start_date',
                        header: 'START DATE',
                        render: (val) => val.format("MMMM DD YYYY")
                    }, {
                        id: 'end_date',
                        header: 'END DATE',
                        render: (val) => val.format("MMMM DD YYYY")
                    }, {
                        id: 'actions',
                        type: 'action_delete',
                        header: '',
                        width: '60px',
                        actionFn: {
                            onDelete: (rowIdx) => {
                                if (isViewOnly) return;
                                setDates(prev => prev.filter((d, idx) => idx !== rowIdx));
                            }
                        }
                    }]} 
                />
                <form onSubmit={(e) => null} style={{ marginTop: "10px" }} className="light-form">
                    {/* Start Date */}
                    {!isViewOnly && <div className="light-input-container">
                        <div className="light-label">Add Date Range</div>
                        <div style={{ display: 'flex' }}>
                            <DatePicker.RangePicker 
                                style={{ 
                                    width: '100%', 
                                    marginBottom: "15px", 
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0
                                }}
                                bordered={false}
                                value={[startDate, endDate]}
                                size="default" 
                                format="MMMM DD YYYY"
                                onChange={(value) => {
                                    const [start, end] = value;
                                    setStartDate(start? dayjs(start).utc().startOf("day"): null);
                                    setEndDate(end? dayjs(end).utc().startOf("day"): null);
                                }}
                            />
                            <ActionButton type={"submit"} action={addDate} text={"Add"} style={{
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                                height: '32px'
                            }} />
                        </div>
                        
                        {modalErrors.filter(err => err.id === 'date_range').map((item, index) => {
                            return <Error key={index} errorKey={index} error={item.message} />
                        })}
                    </div>}
                    {/* Cancel and Submit */}
                    <div className="light-form-buttons" style={{ marginTop: 40, gap: '20px' }}>
                        <ActionButton type='cancel' action={() => onCancel()} text={!isViewOnly? 'Cancel': 'Close'} />
                        {!isViewOnly && <ActionButton type={isModified? "submit": "disabled"} action={() => onSubmit(dates)}
                            text={"Submit"} />}
                    </div>
                </form>
            </div>
    </Modal>
}

export default CustomAvgDatesModal;