/**
 * @author Jarbee Bejar
 * Use ref to control this component.
 */
import React, { useEffect, forwardRef, useImperativeHandle } from 'react';
import OutlinedIcon from 'elements/Icon/OutlinedIcon.js';
import Cell from './Cell.js';
import DataGridController from './DataGridController';
import SortHeader from './DataGridSortHeader';
import GridPagination from './DataGridPagination';
import './DataGrid.scss'

const DataGrid = forwardRef(({
    columns: gridColumns, data, 
    isLoading = null,
    cellConfigs = [], 
    emptyRowContent,
    headerHeight = '50px',
    dividerHeight = '10px',
    footerHeight = '10px',
    defaultCellHeight = '35px',
    width = 'auto',
    height = 'auto',
    style = {},
    gridEventHandler,
    pagination = {}
}, ref) => {
    
    const controller = DataGridController({
        pagination: { 
            enabled: pagination.enabled === true,
            limit: pagination.limit
        },
        columns: gridColumns?? [],
        eventHandler: gridEventHandler
    });

    useImperativeHandle(ref, () => controller);

    useEffect(() => {
        // if (gridColumns) controller.setColumns(gridColumns);
        // if (pagination.enabled === true || pagination.limit > 0) controller.initializePagination(pagination.enabled, pagination.limit);
        if (data) controller.loadData(data, true, pagination.totalCount);
    }, []);

    useEffect(() => {
        if (data) controller.loadData(data);
    }, [data]);

    useEffect(() => {
        if (isLoading !== null) controller.setLoading(isLoading === true);
    }, [isLoading]);
    
    useEffect(() => {
        const generatedCells = controller.generateCells(cellConfigs);
        controller.setErrors(controller.validateCells(generatedCells));
        controller.setCells(generatedCells);
    }, [controller.gridData]);

    const getColumnsWidth = () => {
        return controller.columns.filter(column => !(column.hidden === true)).map(column => column.width || 'minmax(0, 1fr)');
    }

    const getGridTemplateRows = () => {
        if (controller.loading) return `${headerHeight} ${dividerHeight} auto ${footerHeight}`;
        if (!controller.loading) {
            // No Record found
            // if (emptyRowContent === undefined && controller.cells.length === 0) return `${headerHeight} ${dividerHeight} auto ${footerHeight}`;
            // if (emptyRowContent !== undefined && controller.cells.length === 0) return `${headerHeight} ${dividerHeight} auto ${footerHeight}`;
            if (controller.cells.length === 0) return `${headerHeight} ${dividerHeight} auto ${footerHeight}`;
        }
        return `${headerHeight} ${dividerHeight} repeat(${controller.cells.length}, ${defaultCellHeight}) ${footerHeight}`;
    }
    
    return (
    <div className="data-grid-container" style={{position: "relative", width: width, height: height, overflow: 'auto'}}>
        <div className="data-grid" style={{ gridTemplateRows: getGridTemplateRows(), gridTemplateColumns: getColumnsWidth().join(" "), ...style}}>
            {controller.columns.filter(column => column.hidden !== true).map((col, colIdx) => {
                const { columnId:sortColId, value: sortValue } = controller.sortInfo;
                return <div key={`header_${colIdx}`} className="data-grid-header">{
                    col.enableSort === true
                    ?   <SortHeader id={col.id} selectedColumnId={sortColId} value={sortValue} eventHandler={(e) => controller.triggerEvent(e)}>{col.header}</SortHeader>
                    : col.header
                }</div>
            })}
            <span className="data-grid-divider" style={{gridColumn: '1/-1'}}></span>
            {controller.loading && <div className="data-grid-loader" style={{gridColumn: '1 / -1'}}>
                <OutlinedIcon type='loading' style={{ fontSize: "20px", margin: "auto" }} />
            </div>}
            {/* {controller.loading && <div style={{ display: 'flex', position: 'absolute', width: '100%', height: '100%', justifyContent: 'center', backgroundColor: 'rgba(255, 255, 255, 0.05)' }}>
                <OutlinedIcon type='loading'/>
            </div> */}
            
            {!controller.loading && controller.cells.length === 0 && emptyRowContent === undefined && <div style={{gridColumn: '1 / -1', color: "#808285", justifyContent: 'center'}}>No record found</div>}
            {!controller.loading && controller.cells.length === 0 && emptyRowContent !== undefined && emptyRowContent}
            {!controller.loading && controller.cells.map((row, rowIdx) => 
                row.map((cell, colIdx) => {
                    return <Cell 
                        key={`cell_${rowIdx}_${colIdx}`}
                        type={cell.type}
                        items={cell.items}
                        customActions={cell.customActions}
                        actionFn={cell.actionFn}
                        linkFn={cell.linkFn}
                        disabled={cell.disabled}
                        formatValue={cell.formatValue}
                        initialValue={cell.value}
                        coordinates={cell.coordinates}
                        useDisplayField={cell.useDisplayField}
                        render={cell.render}
                        saveValueToGridData={controller.saveValueToGridData}
                        error={cell.error}
                    />
                })
            )}
            <div className="data-grid-footer" style={{gridColumn: '1/-1'}}>
            </div>
        </div>
        {controller.paginationInfo.enabled === true && (
            <GridPagination 
                isLoading={controller.loading}
                viewFromNumber={controller.paginationInfo.view?.from}
                viewToNumber={controller.paginationInfo.view?.to}
                totalCount={controller.paginationInfo.totalCount}
                eventHandler={(e) => controller.triggerEvent(e)}
            />
        )}
    </div>
    )
});

export default DataGrid;