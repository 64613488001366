import React from "react";
import ListHeader from "elements/Text/ListHeader";
import IconButton from "elements/Button/Icon";
import usePermissions from "hooks/usePermissions";

/**
 * Room types list component.
 *
 * @param {[Object]} roomtypes List of competitors data object.
 * @param {Object} user Rev&You user data object.
 * @param {Function} onAdd Event action after clicking the Competitor's Add button.
 * @param {Function} onView Event action after clicking the Competitor's View button.
 * @param {Function} onEdit Event action after clicking the Competitor's Edit button.
 * @param {Function} onDelete Event action after clicking the Competitor's Delete button.
 * @return {Component} Competitors list component.
*/
const CompetitorsList = ({ competitors = [], onAdd, onView, onEdit, onDelete }) => {
    const { 
        canAccess: canAccessCompetitor,
        canCreate: canCreateCompetitor,
        canUpdate: canUpdateCompetitor,
        canDelete: canDeleteCompetitor
    } = usePermissions('property', 'competitor');
    const hasCompetitors = Array.isArray(competitors) && competitors.length > 0;
    
    return (
        <div>
            <ListHeader headerCopy="Competitors" component={
                <IconButton type="add" hasAccess={canCreateCompetitor() && competitors && competitors.length < 5} action={() => onAdd()} width="25px" />
            } />

            <table className="forms-table">
                <thead>
                    <tr style={{ marginBottom: "8px" }}>
                        <th>Label</th>
                        <th>Property</th>
                        <th style={{ textAlign: "right" }}>
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    
                    {!hasCompetitors && <tr>
                        <td>No competitors mapped</td>
                    </tr>}
                    
                    {hasCompetitors && competitors.map((competitor, index) => {
                        return ( 
                            <tr key={`${competitor._id}-${index}`}>
                                <td>{competitor.label}</td>
                                <td>{competitor.name}</td>
                                <td style={{ textAlign: "right", display: 'flex', justifyContent: 'flex-end', gap: '5px' }}>
                                    <IconButton type="view" hasAccess={canAccessCompetitor() && !canUpdateCompetitor()} action={() => onView(competitor)}/>
                                    <IconButton type="edit" hasAccess={canAccessCompetitor() && canUpdateCompetitor()} action={() => onEdit(competitor)}/>
                                    <IconButton type="delete" hasAccess={canDeleteCompetitor()} action={() => onDelete(competitor)}/>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
};

export default CompetitorsList;
