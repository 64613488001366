import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../Button/Button';
import DataGrid from '../../../DataGrid';
import CustomAvgDatesModal from "../Modals/CustomAvgDatesModal";
import { gridPriceThresholdColumns, gridPriceAverageDaysColumns, gridPriceAvgdaysCellConfig } from './gridConfig';
import usePropertiesActions from '../../../../store/actions/propertiesActions';
import usePermissions from 'hooks/usePermissions';

const PriceSection = (props) => {
    const isGroup = props?.isGroup ?? false;
    const { canUpdate } = usePermissions(isGroup? 'property_group': 'property', 'forecast_settings', 'variable_settings');
    const group = props.values;
    const { property, loadingProperty } = useSelector(state => state.properties);
    const item = isGroup ? group : property;
    const forecastSettingsPrice = item?.forecast_settings_v2.price;

    const { updateForecastSettingsV2 } = usePropertiesActions();
    const gridThresholdRef = useRef();
    const gridAvgDaysRef = useRef();
    const [customModalState, setCustomModalState] = useState({ for: null, header: null, items: [], visible: false });

    useEffect(() => {
        if (loadingProperty) return;
        gridThresholdRef.current.loadData(getColumnData(forecastSettingsPrice, false).threshold);
        gridAvgDaysRef.current.loadData(getColumnData(forecastSettingsPrice, false).avg_days);
    }, []);
    
    const getColumnData = (price, loadDefaults = true) => {
        if (loadDefaults) {
            return {
                'threshold': [{
                    'variable': 'Price Thresholds'
                }, {
                    'variable': 'Competitor Price Thresholds'
                }],
                'avg_days': [
                    { 'variable': 'Same day last year', 'average_days': 'N/A' },
                    { 'variable': 'Average day-of-week' },
                    { 'variable': 'Average' },
                    { 'variable': 'Average price for remaining days out to check-in (SDLY)', 'average_days': 'N/A' },
                    { 'variable': 'Average price for remaining days out to check-in (AVG DOW)', 'average_days': 'N/A' },
                    { 'variable': 'Average price for remaining days out to check-in (AVG)', 'average_days': 'N/A' }
                ]
            }
        }
        return {
            'threshold': [{
                'variable': 'Price Thresholds',
                'price_threshold_good': price.threshold.good,
                'price_threshold_avg': price.threshold.avg,
                'price_threshold_below_avg': price.threshold.below_avg,
                'price_threshold_bad': price.threshold.bad
            }, {
                'variable': 'Competitor Price Thresholds',
                'price_threshold_good': price.competitor_threshold.good,
                'price_threshold_avg': price.competitor_threshold.avg,
                'price_threshold_below_avg': price.competitor_threshold.below_avg,
                'price_threshold_bad': price.competitor_threshold.bad
            }],
            'avg_days': [
                { 'variable': 'Same day last year', 'average_days': price.avg_days.sdly ?? 'N/A' },
                { 'variable': 'Rolling Average DOW', 'average_days': price.avg_days.avg_dow },
                { 'variable': 'Rolling Average', 'average_days': price.avg_days.avg },
                { 'variable': 'Remaining Days SDLY', 'average_days': price.avg_days.remaindays_sdly ?? 'N/A' },
                { 'variable': 'Remaining Days Rolling Average DOW', 'average_days': price.avg_days.remaindays_avg_dow },
                { 'variable': 'Remaining Days Rolling Average', 'average_days': price.avg_days.remaindays_avg },
                { 'variable': 'Custom Average DOW', 'average_days': price.avg_days.custom_avg_dow },
                { 'variable': 'Custom Average', 'average_days': price.avg_days.custom_avg },
                { 'variable': 'Remaining Days Custom Average DOW', 'average_days': price.avg_days.remaindays_custom_avg_dow },
                { 'variable': 'Remaining Days Custom Average', 'average_days': price.avg_days.remaindays_custom_avg },
            ]
        }
    }

    const cellConfigs = [...gridPriceAvgdaysCellConfig, { 
        positionX: 1, 
        positionY: 6, 
        type: null,
        defaultValue: null,
        render: (value) => {
            return <button style={{ borderRadius: "5px" }} onClick={() => setCustomModalState({ 
                ...customModalState, 
                for: "avg_dow", 
                header: "Manage Custom Average DOW",
                items: value ?? [], 
                visible: true 
            })}>Customize</button> 
        }
    }, { 
        positionX: 1, 
        positionY: 7, 
        type: null,
        defaultValue: null,
        render: (value) => {
            return <button style={{ borderRadius: "5px" }} onClick={() => setCustomModalState({ 
                ...customModalState, 
                for: "avg", 
                header: "Manage Custom Average",
                items: value ?? [], 
                visible: true 
            })}>Customize</button> 
        }
    }, { 
        positionX: 1, 
        positionY: 8, 
        type: null,
        defaultValue: null,
        render: (value) => {
            return <button style={{ borderRadius: "5px" }} onClick={() => setCustomModalState({ 
                ...customModalState, 
                for: "remdays_avg_dow", 
                header: "Manage Custom Average DOW",
                items: value ?? [], 
                visible: true 
            })}>Customize</button> 
        }
    }, { 
        positionX: 1, 
        positionY: 9, 
        type: null,
        defaultValue: null,
        render: (value) => {
            return <button style={{ borderRadius: "5px" }} onClick={() => setCustomModalState({ 
                ...customModalState, 
                for: "remdays_avg", 
                header: "Manage Custom Average",
                items: value ?? [], 
                visible: true 
            })}>Customize</button> 
        }
    }];

    const handleSubmit = () => {
        if (gridThresholdRef.current.errors.length > 0 || gridAvgDaysRef.current.errors.length > 0) return;

        const newForecastSettingsPrice = { ...forecastSettingsPrice };

        newForecastSettingsPrice.threshold.good = gridThresholdRef.current.getData('price_threshold_good', 0);
        newForecastSettingsPrice.threshold.avg = gridThresholdRef.current.getData('price_threshold_avg', 0);
        newForecastSettingsPrice.threshold.below_avg = gridThresholdRef.current.getData('price_threshold_below_avg', 0);
        newForecastSettingsPrice.threshold.bad = gridThresholdRef.current.getData('price_threshold_bad', 0);

        newForecastSettingsPrice.competitor_threshold.good = gridThresholdRef.current.getData('price_threshold_good', 1);
        newForecastSettingsPrice.competitor_threshold.avg = gridThresholdRef.current.getData('price_threshold_avg', 1);
        newForecastSettingsPrice.competitor_threshold.below_avg = gridThresholdRef.current.getData('price_threshold_below_avg', 1);
        newForecastSettingsPrice.competitor_threshold.bad = gridThresholdRef.current.getData('price_threshold_bad', 1);
        
        newForecastSettingsPrice.avg_days.avg_dow = gridAvgDaysRef.current.getData('average_days', 1);
        newForecastSettingsPrice.avg_days.avg = gridAvgDaysRef.current.getData('average_days', 2);
        
        newForecastSettingsPrice.avg_days.remaindays_avg_dow = gridAvgDaysRef.current.getData('average_days', 4);
        newForecastSettingsPrice.avg_days.remaindays_avg = gridAvgDaysRef.current.getData('average_days', 5);

        newForecastSettingsPrice.avg_days.custom_avg_dow = gridAvgDaysRef.current.getData('average_days', 6);
        newForecastSettingsPrice.avg_days.custom_avg = gridAvgDaysRef.current.getData('average_days', 7);
        
        newForecastSettingsPrice.avg_days.remaindays_custom_avg_dow = gridAvgDaysRef.current.getData('average_days', 8);
        newForecastSettingsPrice.avg_days.remaindays_custom_avg = gridAvgDaysRef.current.getData('average_days', 9);

        if (gridThresholdRef.current.errors.length > 0) return;
        if (gridAvgDaysRef.current.errors.length > 0) return;

        updateForecastSettingsV2(isGroup ? group.id : property._id, newForecastSettingsPrice, 'price', isGroup)
            .then(result => {
                if (result.type === "UPDATE_PROPERTY_FORECAST_SETTINGS_SUCCESS") {
                    const updatedProperty = result.response.data.data;
                    gridThresholdRef.current.loadData(getColumnData(updatedProperty.forecast_settings_v2.price, false).threshold);
                    gridAvgDaysRef.current.loadData(getColumnData(updatedProperty.forecast_settings_v2.price, false).avg_days);
                }
            });
    };

    const handleCancel = () => {
        gridThresholdRef.current.reload();
        gridAvgDaysRef.current.reload();
    };

    return (
        <div className="price-section">
            <div className="threshold-grid">
                <DataGrid ref={gridThresholdRef} columns={gridPriceThresholdColumns.map(config => ({ ...config, disabled: !canUpdate() }))} defaultCellHeight="40px" footerHeight="0px" />
            </div>
            <div className="avg-days-grid">
                <DataGrid ref={gridAvgDaysRef} columns={gridPriceAverageDaysColumns.map(config => ({ ...config, disabled: !canUpdate() }))} cellConfigs={cellConfigs} defaultCellHeight="40px" footerHeight="0px" />
            </div>
            <div style={{ display: "flex", flexDirection: 'row-reverse', paddingTop: "25px" }}>
                {canUpdate() && <Button type="primary" className="submit" onClick={handleSubmit} style={{ marginLeft: "35px" }}>Submit</Button>}
                {canUpdate() && <Button type="bg-dark" onClick={handleCancel}>Cancel</Button>}
            </div>

            <CustomAvgDatesModal width={484}
                isViewOnly={ !canUpdate() }
                headerCopy={ customModalState.header }
                isVisible={ customModalState.visible } 
                items={ customModalState.items }
                onCancel={() => setCustomModalState({ ...customModalState, visible: false })} 
                onSubmit={(items) => {
                    const params = { avg_days: null };
                    
                    if (customModalState.for === 'avg_dow') {
                        params.avg_days = { custom_avg_dow: items };
                    }

                    if (customModalState.for === 'avg') {
                        params.avg_days = { custom_avg: items };
                    }

                    if (customModalState.for === 'remdays_avg_dow') {
                        params.avg_days = { remaindays_custom_avg_dow: items };
                    }

                    if (customModalState.for === 'remdays_avg') {
                        params.avg_days = { remaindays_custom_avg: items };
                    }

                    updateForecastSettingsV2(isGroup ? group.id : property._id, params, 'price', isGroup).then(result => {
                        setCustomModalState({ ...customModalState, visible: false });
                        const updatedProperty = result.response.data.data;
                        gridAvgDaysRef.current.loadData(getColumnData(updatedProperty.forecast_settings_v2.price, false).avg_days);
                    });
                }}
            />
        </div>
    )
}

export default PriceSection;