import React, { useEffect, useState, useRef, useMemo } from 'react';
import Button from "../../Button/Button";
import DataGrid from '../../DataGrid';
import useAdminConsoleActions from "../../../store/actions/adminConsoleActions";
import FormModal from "../../FormModal/FormModal";
import IconButton from 'elements/Button/Icon';
import usePermissions from 'hooks/usePermissions';
import { ToastsStore } from 'react-toasts';
import './Segments.scss';

const Segments = () => {
    const { 
        canAccess: canAccessSegment,
        canCreate: canCreateSegment,
        canUpdate: canUpdateSegment,
        canDelete: canDeleteSegment
    } = usePermissions('admin_console', 'segment');

    const _mode = { ADD: 'Add', EDIT: 'Edit', DELETE: 'Delete' };
    const segmentsGridRef = useRef();
    const { getSegments, getSegment, addSegment, updateSegment, deleteSegment, getSegmentGroups, addSegmentGroup } = useAdminConsoleActions();
    const [segmentGroupItems, setSegmentGroupItems] = useState([]);
    const [segmentModalState, setSegmentModalState] = useState({
        mode: _mode.ADD,
        selectedRowIdx: null
    });
    const segmentModalController = FormModal.newController();
    const { errors, inputValues, onChangeHandler, onBlurHandler } = segmentModalController;
    const segmentGroupModalController = FormModal.newController();
    const { errors: sgErrors, inputValues: sgInputValues, onChangeHandler: sgOnChangeHandler, onBlurHandler: sgOnBlurHandler } = segmentGroupModalController;

    const showModal = (visible, mode, rowIdx) => {
        setSegmentModalState({...segmentModalState, 
            visible: visible,
            selectedRowIdx: rowIdx, 
            mode: mode
        });
    }
    const closeModal = () => {
        setSegmentModalState({...segmentModalState, visible: false});
    }
    const sgSubmit = (e) => {
        e.preventDefault();
        
        if (Object.keys(sgErrors).length > 0) return;
        addSegmentGroup({ 
            _id: sgInputValues.segment_group_code,
            name: sgInputValues.segment_group_name
        }).then(result => {
            loadSegmentGroups();
            segmentGroupModalController.show(false);
        });
    }
    const submit = (e) => {
        e.preventDefault();
        if (Object.keys(errors).length > 0) return;
        switch(segmentModalState.mode) {
            case _mode.ADD: 
                addSegment({
                    code: inputValues.segment_code,
                    name: inputValues.segment_name,
                    segment_group_id: inputValues.segment_group_id
                }).then(result => doneSubmit(result, "ADD_SEGMENT_SUCCESS"));
                break;
            case _mode.EDIT:
                updateSegment(segmentsGridRef.current.getGridData(segmentModalState.selectedRowIdx).segment_code, {
                    name: inputValues.segment_name,
                    segment_group_id: inputValues.segment_group_id === undefined? null: inputValues.segment_group_id
                }).then(result => doneSubmit(result, "UPDATE_SEGMENT_SUCCESS"));
                break;
            case _mode.DELETE:
                deleteSegment(segmentsGridRef.current.getGridData(segmentModalState.selectedRowIdx).segment_code).then(result => 
                    doneSubmit(result, "DELETE_SEGMENT_SUCCESS")
                );
                break;
        }
    }
    const doneSubmit = (result, type) => {
        if (result?.type == type) {
            segmentModalController.show(false);
            loadSegmentsGrid();
        }
    }
    const loadSegmentsGrid = () => {
        segmentsGridRef.current.setLoading(true);
        getSegments().then(response => {
            if (Array.isArray(response.data.data)) {
                const data = [];
                response.data.data.forEach(segment => {
                    data.push({
                        segment_code: segment._id,
                        segment_name: segment.name,
                        segment_group_code: segment.segment_group?._id || '',
                        segment_group_name: segment.segment_group?.name || ''
                    });
                });

                segmentsGridRef.current.loadData(data);
            } else {
                segmentsGridRef.current.loadData([]);
            }
            segmentsGridRef.current.setLoading(false);
        }).catch(error => {
            ToastsStore.error(error.response.data.message);
        });
    }
    const loadSegmentGroups = () => new Promise((resolve, reject) => {
        getSegmentGroups().then(result => {
            let data = [], items = [];
            if (Array.isArray(result.data.data)) {
                data = result.data.data;
                items = data.map(segmentGroup => ({
                    id: segmentGroup._id,
                    displayText: `${segmentGroup.name} (${segmentGroup._id})`
                }));
            }
            setSegmentGroupItems([
                ...items, 
                { id: 'add_segment_group', displayText: 'Add new segment group', class: 'add-new-segment-group-opt'}
            ]);
            resolve(data);
        });
    }) 

    useEffect(() => {
        loadSegmentsGrid();
    }, []);
    
    useEffect(() => {
        if (segmentModalState.visible === true) {
            if ([_mode.EDIT, _mode.DELETE].includes(segmentModalState.mode)) {
                let data = segmentsGridRef.current.getGridData(segmentModalState.selectedRowIdx);
                getSegment(data.segment_code).then(result => {
                    let segment = result.data.data;
                    if (segment && typeof segment === 'object') {
                        loadSegmentGroups().then(result => {
                            let segmentGroup = result.find(group => group.segments.findIndex(groupSegment => groupSegment._id == segment._id) > -1);
                            segmentModalController.show(true, { 
                                segment_name: segment.name, 
                                segment_code: segment._id,
                                segment_group_id: segmentGroup?._id
                            });
                        });
                    } else {
                        console.error("Failed to get segment info: Segment not found");
                    }
                }).catch(result => console.error(result));
                
            } else {
                loadSegmentGroups().then(() => {
                    segmentModalController.show(true);
                });
            }
        } else {
            segmentModalController.show(false, null);
        }
    }, [segmentModalState]);

    
    useEffect(() => {
        if (inputValues.segment_group_id == 'add_segment_group') {
            segmentGroupModalController.show();
            onChangeHandler('segment_group_id', null);
        }
    }, [inputValues]);

    const showSubmit = useMemo(() => {
        if (segmentModalState.mode === _mode.ADD && canCreateSegment()) return true;
        if (segmentModalState.mode === _mode.EDIT && canUpdateSegment()) return true;
        if (segmentModalState.mode === _mode.DELETE && canDeleteSegment()) return true;
        return false;
    }, [segmentModalState.mode]);

    return (
        <div className="admin-segments">
            <div className="add-segment" style={{display: 'flex', marginBottom: '10px'}}>
                <h4 style={{color: 'white', marginRight: '10px'}}>Add Segment</h4>
                <IconButton type='add' hasAccess={canCreateSegment()} action={() => {
                    showModal(true, _mode.ADD);
                }} width='25px' />
            </div>
            <DataGrid
                ref={segmentsGridRef}
                columns={[{
                    id: 'segment_name',
                    header: 'SEGMENT NAME',
                    width: 'minmax(220px, auto)'
                }, {
                    id: 'segment_code',
                    header: 'SEGMENT CODE',
                    width: 'minmax(120px, auto)'
                }, {
                    id: 'segment_group_name',
                    header: 'SEGMENT GROUP',
                    width: 'minmax(220px, auto)'
                }, {
                    id: 'segment_group_code',
                    header: 'SEGMENT GROUP CODE',
                    width: 'minmax(120px, auto)'
                }, {
                    id: 'actions',
                    header: 'ACTIONS',
                    type: 'custom_actions',
                    width: '54px',
                    actions: [{
                        render: rowIdx => <IconButton type='edit' hasAccess={canAccessSegment()} action={() => {
                            showModal(true, _mode.EDIT, rowIdx);
                        }} />
                    }, {
                        render: rowIdx => <IconButton type='delete' hasAccess={canDeleteSegment()} action={() => {
                            showModal(true, _mode.DELETE, rowIdx);
                        }} />
                    }]
                }]} 
                width="800px"
            />
            <FormModal title={`Add New Segment Group`} onSubmit={sgSubmit} 
                width={500} maskClosable={false} 
                onClose={() => { segmentGroupModalController.show(false); }}
                controller={segmentGroupModalController}
            >
                <div className="light-input-container">
                    <FormModal.Field type="textbox" label="Segment Group Name"
                        name="segment_group_name" value={sgInputValues.segment_group_name} error={sgErrors.segment_group_name}
                        validations={{ required: true, characters: 'alphanumeric_with_space' }}
                        onChange={sgOnChangeHandler} onBlur={sgOnBlurHandler} />
                </div>
                <div className="light-input-container">
                    <FormModal.Field type="textbox" label="Segment Group Code"
                        name="segment_group_code" value={sgInputValues.segment_group_code} error={sgErrors.segment_group_code}
                        validations={{ required: true, characters: 'alphanumeric' }}
                        onChange={sgOnChangeHandler} onBlur={sgOnBlurHandler} />
                </div>
                <div className="light-form-buttons">
                    <Button type="bg-light" style={{ marginRight: '8px' }} onClick={() => { segmentGroupModalController.show(false); }}>Cancel</Button>
                    <Button btnType="submit" type="primary">Submit</Button>
                </div>
            </FormModal>
            <FormModal title={`${segmentModalState.mode} Segment`} onSubmit={submit} 
                width={500} maskClosable={false} 
                onClose={() => { closeModal(); }}
                controller={segmentModalController}
            >
            {(segmentModalState.mode === _mode.ADD || segmentModalState.mode === _mode.EDIT) && (<>
                <div className="light-input-container">
                    <FormModal.Field type="textbox" label="Segment Name"
                        name="segment_name" value={inputValues.segment_name} error={errors.segment_name}
                        validations={{ required: true, characters: 'alphanumeric_with_space' }}
                        onChange={onChangeHandler} onBlur={onBlurHandler} />
                </div>
                <div className="light-input-container">
                    <FormModal.Field type="textbox" label="Segment Code"
                        name="segment_code" value={inputValues.segment_code} error={errors.segment_code} disabled={segmentModalState.mode === _mode.EDIT}
                        validations={{ required: true, characters: 'alphanumeric' }}
                        onChange={onChangeHandler} onBlur={onBlurHandler} />
                </div>
                <div className="light-input-container">
                    <FormModal.Field type="dropdown" label="Segment Group"
                        name="segment_group_id" value={inputValues.segment_group_id} error={errors.segment_group_id}
                        validations={{ required: true }} items={segmentGroupItems} config={{ allowClear: true }}
                        onChange={onChangeHandler} onBlur={onBlurHandler} />
                </div>
            </>)}
            {segmentModalState.mode === _mode.DELETE && (<>
                <div className="light-form delete-segment">
                    <center>
                        <p>Deleting this segment will remove the item from the predefined list for segment and remove all mappings associated with it.</p>
                        <p>Are you sure you want to delete <b>{segmentsGridRef.current.getGridData([segmentModalState.selectedRowIdx])?.segment_name}</b>?</p>
                    </center>
                </div>
            </>)}
                <div className="light-form-buttons">
                    <Button type="bg-light" style={{ marginRight: '8px' }} onClick={() => { closeModal(); }}>Cancel</Button>
                    {showSubmit && <Button btnType="submit" type="primary">
                        {segmentModalState.mode === _mode.DELETE? 'DELETE': 'Submit'}
                    </Button>}
                </div>
            </FormModal>
        </div>
    )
}

export default React.memo(Segments);