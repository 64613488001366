import React, { useEffect, useState, useRef } from 'react';
import { AddFilled, Exit } from "Icons";
import DataGrid from '../../DataGrid';
import useRolesActions from "../../../store/actions/roleActions";
import './Roles.scss';
import SearchInput from "elements/Input/Search";
import { composeSortParams } from "utils";
import { ToastsStore } from 'react-toasts';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import useModal from 'hooks/useModal';
import Button from 'components/Button/Button';
import dayjs from 'lib/dayjs';
import usePermissions from 'hooks/usePermissions';
import IconButton from 'elements/Button/Icon';

const Roles = () => {
    const { canAccess, canUpdate, canCreate, canDelete } = usePermissions('role');

    const location = useLocation();
    const navigate = useNavigate();

    const [searchValue, setSearchValue] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const rolesGridRef = useRef();
    const deleteModal = useModal({ id: null, name: null }, () => {
        const { id, name } = deleteModal.values;
        deleteRole(id).then(result => {
            if (result.type === 'DELETE_ROLE_SUCCESS') {
                ToastsStore.success(`Role: ${name} was successfuly deleted`);
                deleteModal.hide();
                fetchRoles();
            } else {
                ToastsStore.error(result.error.response?.data?.message);
            }
        }).catch(error => {
            console.log(error); 
            ToastsStore.error(error);
        });
    });

    const { getRoles, deleteRole } = useRolesActions();

    const fetchRoles = (reset = false) => {
        setIsLoading(true);
        const roleName = (searchValue?? "") === ""? null: searchValue;
        const { sortVal, sortId, pageNo, pageLimit } = rolesGridRef.current.gridInfo;
        getRoles(roleName, composeSortParams(sortVal, sortId), reset === true? 1: pageNo, pageLimit).then(result => {
            if (result.type === 'GET_ROLES_SUCCESS') {
                const total = result.response.data.total_count;
                const roles = result.response.data.data;
                rolesGridRef.current.loadData(roles, reset, total);
            } else {
                ToastsStore.error(result.error.response?.data?.message);
            }
        }).catch(error => {
            ToastsStore.error(error);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    useEffect(() => {
        fetchRoles(true);
    }, [searchValue]);

    // useEffect(() => {
    //     fetchRoles(true);
    // }, []);

    return (
        <div className="admin-role-management">
            <div style={{ width: '800px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', height: '50px'}}>
                    <div className="add-role" style={{display: 'flex', alignItems: 'center' }}>
                        <h4 style={{ color: 'white', marginRight: '10px', alignContent: 'center', marginBottom: 0 }}>Add Role</h4>
                        <IconButton type='add' hasAccess={canCreate()} action={() => {
                            navigate('/roles/add', { state: { from: location.pathname }});
                        }} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', width: '250px' }}>
                        <SearchInput
                            handleSearch={val => setSearchValue(val)}
                            isLoading={isLoading}
                            placeholder="Search for a role"
                        />
                    </div>
                </div>
                
                <DataGrid
                    ref={rolesGridRef}
                    isLoading={isLoading}
                    columns={[{
                        id: 'name',
                        header: 'ROLE',
                        width: 'minmax(300px, 400px)',
                        enableSort: true,
                    }, {
                        id: 'created_date',
                        header: 'CREATED DATE AND TIME',
                        width: 'minmax(120px, auto)',
                        enableSort: true,
                        sortDefault: true,
                        render: (val) => dayjs(val).format('YYYY-MM-DD hh:mm:ss A')
                    }, {
                        id: 'actions',
                        header: 'ACTIONS',
                        type: 'custom_actions',
                        width: '54px',
                        actions: [{
                            render: (rowIdx) => <>
                                    <IconButton type='edit' hasAccess={canAccess() && canUpdate()} action={() => {
                                        const item = rolesGridRef.current?.getRawData(rowIdx);
                                        navigate(`/roles/${item._id}/edit`, { state: { from: location.pathname }});
                                    }} width='20px' />
                                    <IconButton type='view' hasAccess={canAccess() && !canUpdate()} action={() => {
                                        const item = rolesGridRef.current?.getRawData(rowIdx);
                                        navigate(`/roles/${item._id}/edit`, { state: { from: location.pathname }});
                                    }} width='20px' />
                                </>
                        }, {
                            render: (rowIdx) => <IconButton type='delete' hasAccess={canDelete()} action={() => {
                                const item = rolesGridRef.current?.getRawData(rowIdx);
                                deleteModal.show({ mode: 'DELETE', data: { id: item._id, name: item.name }})
                            }} width='20px' />
                        }]
                    }]} 
                    width="800px"
                    gridEventHandler={event => { 
                        fetchRoles();
                    } }
                    pagination={{
                        enabled: true,
                        limit: 5
                    } }
                />
            </div>

            <Modal open={deleteModal.visible} width={500} maskClosable={false} footer={null}
                closeIcon={<Exit width="25px" height="25px" className="pointer" />}
                onCancel={() => deleteModal.hide() }
            >
                <div>
                    <div className="rms-modal-content">
                        <div className="rms-modal-header">
                            <h3>Delete Role</h3>
                        </div>
                        <div className="light-form">
                            
                            <p>Are you sure you want to delete <strong>{deleteModal.values['name']}</strong> role?</p>
                            <br/>
                            <div className="light-form-buttons">
                                <Button type="primary" style={{ marginRight: '8px' }} onClick={() => deleteModal.handleSubmit() }> 
                                    DELETE
                                </Button>
                                <Button type="bg-light" onClick={() => deleteModal.hide()}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                
            </Modal>
        </div>
    )
}

export default React.memo(Roles);