import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import { ToastsStore } from "react-toasts";

import IconButton from "elements/Button/Icon";
import ActionButton from "elements/Button/Action";
import BasicInput from "elements/Input/Basic";
import ModalSelect from "elements/Select/Modal";
import Error from "elements/Text/Error";

import useProperties from "../../store/actions/propertiesActions";
import { isNullOrUndefined } from "utils";
import { isAlphaNumeric, sortArray } from "utils/data";
import constants from "../../constants";



const SegmentMappingsModal = ({ visible, type, channels = [], ss = [], onCancel = null, onSubmit = null }) => {
    const state = useSelector((state) => state.mainReducer);
    const { listItemInView, propertyInView } = state;
    const { addSegmentMapping, updateSegmentMapping, deleteSegmentMapping } = useProperties();

    const partners = sortArray(channels, "name")
        .map((item) => { return { name: item.name, value: item._id } });
    const segments = sortArray(propertyInView?.segments ?? [], "global")
        .map((item) => { if (item.local) return { name: `${item.local.name} (${item.local.code})`, value: item.local.code } });

    const [partner, setPartner] = useState("");
    const [segment, setSegment] = useState("");
    const [sourcename, setSourceName] = useState("");
    const [sourcecode, setSourceCode] = useState("");
    const [sourcedesc, setSourceDesc] = useState("");

    const [hasPartner, setHasPartner] = useState(false);
    const [beforeEdit, setBeforeEdit] = useState(null);
    const [modalErrors, setModalErrors] = useState([]);

    const isViewModal = type === "view";
    const isAddModal = type === "add";
    const isEditModal = type === "edit";
    const isDeleteModal = type === "delete";


    // Dynamic Header Copy & Element
    let headerCopy = "";
    if (isAddModal) headerCopy = "Add Segment Mapping";
    if (isViewModal) headerCopy = "Segment Mapping";
    if (isEditModal) headerCopy = "Edit Segment Mapping";
    if (isDeleteModal) headerCopy = "Delete Segment Mapping";

    // Dynamic Success/Fail Copy
    let successCopy = "";
    if (isAddModal) successCopy = "Segment mapping successfully added";
    if (isEditModal) successCopy = "Segment mapping successfully updated";
    if (isDeleteModal) successCopy = "Segment mapping successfully removed";

    let failureCopy = "";
    if (isAddModal) failureCopy = "Sorry, we're unable to add the segment mapping at this time";
    if (isEditModal) failureCopy = "Sorry, we're unable to update the segment mapping at this time";
    if (isDeleteModal) failureCopy = "Sorry, we're unable to removed the segment mapping at this time";


    // Happens in Edit Mode
    // Pre-Fill Modal with Generic listItemInView State Data if a Partner Item is Selected
    useEffect(() => {
        resetModal();
        if (!isNullOrUndefined(listItemInView)) {
            
            if (listItemInView.segment) {
                setSegment(listItemInView.segment.local.code);
            }
            if (listItemInView.mapping) {
                const mapping = listItemInView.mapping;
                setSourceName(mapping?.source ?? "");
                setSourceCode(mapping?.source_code ?? "");
                setSourceDesc(mapping?.description ?? "");
                setPartner(mapping?.partner_code ?? "");
                setHasPartner(!isNullOrUndefined(mapping));
                setBeforeEdit({
                    name: mapping ? mapping.source : "",
                    code: mapping ? mapping.source_code : "",
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    const resetModal = () => {
        setPartner("");
        setSegment("");
        setSourceName("");
        setSourceCode("");
        setSourceDesc("");
        setModalErrors([]);
    }

    const handleModalCancel = () => {
        onCancel();
        resetModal();
    }

    const handleModalSubmit = () => {
        const propertyId = propertyInView._id;
        const selectedSegmentCode = segment;

        if (isAddModal || isEditModal) {
            const errors = [];
            if ((partner?? "") === "") errors.push({ id: 'partner', message: "Please select a channel" });
            if ((selectedSegmentCode?? "") === "") errors.push({ id: 'type', message: "Please select a segment"});
            if ((sourcecode?? "") === "") errors.push({ id: 's_code', message: "Please select a segment" });
            if ((sourcename?? "") === "") errors.push({ id: 's_name', message: "Please provide a source name"});
            // if (!isAlphaNumeric(sourcename)) errors.push({ id: 's_name', message: "Name contains non-alphanumeric characters" });
            // if (!isAlphaNumeric(sourcecode, false)) errors.push({ id: 's_code', message: "Code contains non-alphanumeric characters" });
            setModalErrors(errors);
            if (errors.length > 0) return;
        }
        
        const selectedSegment = propertyInView.segments.find(segment => segment.local?.code === selectedSegmentCode);
        const selectedMapping = selectedSegment.sources.find(source => source.partner_code === listItemInView?.mapping?.partner_code && source.source_code === listItemInView?.mapping?.source_code);
        // The ids here are preparation for implementation of IDS for each segments and segment mappings.
        // There should be an ID for those items/objects for better identification.
        // Once implemented, replace segment object in the request with those ids.
        const selectedSegmentId = selectedSegment?._id;
        const selectedMappingId = selectedMapping?._id;

        const filter = {
            segment: selectedSegment.local.code,
            partner: selectedMapping?.partner_code,
            source: selectedMapping?.source_code
        };

        const data = {
            partner_code: partner,
            source_code: sourcecode,
            source_name: sourcename,
            description: sourcedesc
        }
        
        if (isAddModal) {
            addSegmentMapping(propertyId, filter.segment, data).then(result => {
                if (result && result.type === constants.ADD_PROPERTY_SEGMENT_MAPPING_SUCCESS) {
                    onSubmit();
                    resetModal();
                    ToastsStore.success(successCopy);
                } else {
                    ToastsStore.error(failureCopy);
                }
            });
        }
        if (isEditModal) {
            updateSegmentMapping(propertyId, filter.segment, filter.partner, filter.source, data).then(result => {
                if (result && result.type === constants.UPDATE_PROPERTY_SEGMENT_MAPPING_SUCCESS) {
                    onSubmit();
                    resetModal();
                    ToastsStore.success(successCopy);
                } else {
                    ToastsStore.error(failureCopy);
                }
            });
        }
        if (isDeleteModal) {
            deleteSegmentMapping(propertyId, filter.segment, filter.partner, filter.source).then(result => {
                if (result && result.type === constants.DELETE_PROPERTY_SEGMENT_MAPPING_SUCCESS) {
                    onSubmit();
                    resetModal();
                    ToastsStore.success(successCopy);
                } else {
                    ToastsStore.error(failureCopy);
                }
            });
        }
    }

    return (
        <Modal open={visible} onCancel={() => handleModalCancel()} footer={null} closeIcon={
            <IconButton type="delete" hasAccess={true} width="25px" />
        }>
            <div className="rms-modal-content">
                {/* Header */}
                <div className="rms-modal-header"><h3>{headerCopy}</h3></div>

                <form onSubmit={(e) => null} className="light-form">
                    {!isDeleteModal && <>

                        {/* Select Channel */}
                        <ModalSelect label="Select Channel" value={partner} options={partners}
                            disabled={(isEditModal && hasPartner && partner.trim() !== "") || isViewModal}
                            action={(value) => setPartner(value)} />
                        {modalErrors.filter(err => err.id === "partner").map((item, index) => {
                            return <Error key={index} errorKey={index} error={item.message} />
                        })}

                        {/* Select Segment */}
                        <ModalSelect label="Select Segment" value={segment} options={segments}
                            disabled={isEditModal || isViewModal} action={(value) => setSegment(value)} />
                        {modalErrors.filter(err => err.id === "type").map((item, index) => {
                            return <Error key={index} errorKey={index} error={item.message} />
                        })}

                        <div style={{ display: "grid", gridTemplateColumns: "48% 4% 48%" }}>
                            <div>
                                {/* Source Name */}
                                <BasicInput id="sourceName" label="Source Name" value={sourcename}
                                    disabled={isViewModal} onBlur={(e) => setSourceName(e.target.value)}
                                    onChange={(e) => setSourceName(e.target.value)} />
                                {modalErrors.filter(err => err.id === "s_name").map((item, index) => {
                                    return <Error key={index} errorKey={index} error={item.message} />
                                })}
                            </div>

                            <span />

                            <div>
                                {/* Source Code */}
                                <BasicInput id="sourcecode" label="Source Code" value={sourcecode}
                                    disabled={isViewModal} onBlur={(e) => setSourceCode(e.target.value)}
                                    onChange={(e) => setSourceCode(e.target.value)} />
                                {modalErrors.filter(err => err.id === "s_code").map((item, index) => {
                                    return <Error key={index} errorKey={index} error={item.message} />
                                })}
                            </div>
                        </div>

                        {/* Source Description */}
                        <div>
                            <BasicInput id="sourcedesc" label="Source Description" value={sourcedesc}
                                disabled={isViewModal} onBlur={(e) => setSourceDesc(e.target.value)}
                                onChange={(e) => setSourceDesc(e.target.value)} />
                        </div>

                    </>}


                    {/* Delete Segment Copy */}
                    {isDeleteModal && <div style={{ padding: 20 }}>
                        <p>
                            <strong>Segment Name: </strong>
                            <span style={{ marginLeft: 8 }}>{listItemInView.segment.local.name}</span>
                            <br />
                            <strong>Segment Code: </strong>
                            <span style={{ marginLeft: 8 }}>{listItemInView.segment.local.code}</span>
                            <br />
                            <strong>Channel: </strong>
                            <span style={{ marginLeft: 8 }}>{listItemInView.mapping.partner_code}</span>
                            <br />
                            <strong>Source Name: </strong>
                            <span style={{ marginLeft: 8 }}>{listItemInView.mapping.source}</span>
                            <br />
                            <strong>Source Code: </strong>
                            <span style={{ marginLeft: 8 }}>{listItemInView.mapping.source_code}</span>
                        </p>
                        <p>Are you sure you want to delete this mapping?</p>
                    </div>}


                    {/* Cancel, Submit, Delete Buttons */}
                    <div className="light-form-buttons" style={{ marginTop: isDeleteModal ? 20 : 40, gap: '20px' }}>
                        <ActionButton type={"cancel"} action={() => handleModalCancel()} text={isViewModal? "Close": "Cancel"} />
                        {!isViewModal && <ActionButton type="submit" action={() => handleModalSubmit()}
                            text={isDeleteModal ? "Delete" : "Submit"} />}
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default SegmentMappingsModal;
