import React from "react";
import { useSelector } from "react-redux";

import TableChart from "elements/Chart/TableChart";
import PriceRecoIcon from "elements/Icon/PriceRecoIcon";

import { isNullOrUndefined } from "utils";


/**
 * Daily View of the Algorithm Modal.
 *
 * @return {Component}
*/
const AlgorithmModalDaily = () => {
    const { sidebarData, propertyInView } = useSelector(state => state.mainReducer);
    const users = useSelector((state) => state.users);
    const settings = propertyInView.forecast_settings_v2;

    let breakdown = sidebarData.algorithm_breakdown;
    let computed = sidebarData._computed;

    const userPrefs = users.authUser.prefs;
    const colorScoreEnabled = !isNullOrUndefined(userPrefs) && !isNullOrUndefined(userPrefs.colorScoreEnabled);
    const useColorScore = colorScoreEnabled && userPrefs.colorScoreEnabled;


    // General Forecast Data from Sidebar
    let pickupForecast = null;
    let paceForecast = null;
    let demandForecast = null;
    let priceForecast = null;
    let finalsForecast = null;
    let priceRecommendation = null;
    let currency = null;

    if (!isNullOrUndefined(sidebarData) && !isNullOrUndefined(breakdown)) {
        pickupForecast = breakdown.pickup;
        paceForecast = breakdown.pace;
        demandForecast = breakdown.demand;
        priceForecast = breakdown.price;
        finalsForecast = breakdown.finals;
        priceRecommendation = breakdown.price_recommendation;
        currency = computed?.currency?.symbol ?? "";
    }

    const displayCopy = (value) => {
        return isNullOrUndefined(value) ? "null" : value;
    }


    // Overview Table Data
    let overview_headers = [
        "Forecast",
        "Pickup",
        "Pace",
        "Demand",
        "Price",
        "Finals",
        "Price Recommendation",
    ];
    let overview_data = {
        score: {
            name: "Score",
            data: [
                pickupForecast.forecast.sum,
                paceForecast.forecast.sum,
                demandForecast.forecast.sum,
                `${priceForecast.forecast.percent_diff}% difference`,
                finalsForecast.forecast.sum,
                `${priceRecommendation.recommendation.percent}%`
            ],
            color: null
        },
        color: {
            name: "Color",
            data: [
                pickupForecast.forecast.color,
                paceForecast.forecast.color,
                demandForecast.forecast.color,
                priceForecast.forecast.color,
                finalsForecast.forecast.color,
                <PriceRecoIcon quantity={priceRecommendation.recommendation.arrow_quantity} hasCopy={true} />
            ],
            color: null
        },
    };

    // Demand Forecast Table Data
    let demand_headers = [
        "",
        "Score",
        "Color",
        "% Weight",
        "Weighted Score"
    ];
    let demand_data = {
        sdly: {
            name: "Pickup",
            data: [
                demandForecast.breakdown.pickup.score,
                demandForecast.breakdown.pickup.color,
                `${demandForecast.breakdown.pickup.weight}%`,
                demandForecast.breakdown.pickup.weight_score
            ],
            color: null
        },
        avg: {
            name: "Pace",
            data: [
                demandForecast.breakdown.pace.score,
                demandForecast.breakdown.pace.color,
                `${demandForecast.breakdown.pace.weight}%`,
                demandForecast.breakdown.pace.weight_score

            ],
            color: null
        },
        finals: {
            name: "Finals",
            data: [
                demandForecast.breakdown.finals.score,
                demandForecast.breakdown.finals.color,
                `${demandForecast.breakdown.finals.weight}%`,
                demandForecast.breakdown.finals.weight_score

            ],
            color: null
        },
    };

    const renderDemandSummary = () => {
        return (
            <div>
                <p><strong>DEMAND FORECAST</strong></p>
                <p>
                    <strong style={{ marginRight: 8 }}>Demand Forecast Score:</strong>
                    <span>{JSON.stringify(demandForecast.forecast.sum)}</span>
                    <br />
                    <strong style={{ marginRight: 8 }}>Demand Forecast Color:</strong>
                    <span>{displayCopy(demandForecast.forecast.color)}</span>
                </p>
            </div>
        )
    }


    // Pickup Forecast Table Data
    let pickup_headers = [
        "",
        "Pickup",
        "% Diff",
        "V-Color",
        "V-Color Score",
        "V-Weighted Score",
        `Instances ${useColorScore ? 'Color' : 'Score'}`,
        "Instances Qty",
        "I-Average",
        "I-Color",
        "I-Color Score",
        "I-Weighted Score"
    ];
    let pickup_data = {
        sdly: {
            name: "SDLY",
            data: [
                pickupForecast.variables.vs_sdly.value,
                `${pickupForecast.variables.vs_sdly.value_diff}%`,
                pickupForecast.variables.vs_sdly.color,
                pickupForecast.variables.vs_sdly.color_score,
                pickupForecast.variables.vs_sdly.weight_score,
                JSON.stringify(pickupForecast.instance.vs_sdly.value),
                pickupForecast.instance.vs_sdly.no_instance,
                pickupForecast.instance.vs_sdly.average,
                pickupForecast.instance.vs_sdly.color,
                pickupForecast.instance.vs_sdly.color_score,
                pickupForecast.instance.vs_sdly.weight_score,
            ],
            color: null
        },
        avg: {
            name: "AVG",
            data: [
                pickupForecast.variables.vs_avg.value,
                `${pickupForecast.variables.vs_avg.value_diff}%`,
                pickupForecast.variables.vs_avg.color,
                pickupForecast.variables.vs_avg.color_score,
                pickupForecast.variables.vs_avg.weight_score,
                JSON.stringify(pickupForecast.instance.vs_avg.value),
                pickupForecast.instance.vs_avg.no_instance,
                pickupForecast.instance.vs_avg.average,
                pickupForecast.instance.vs_avg.color,
                pickupForecast.instance.vs_avg.color_score,
                pickupForecast.instance.vs_avg.weight_score,
            ],
            color: null
        },
        avg_dow: {
            name: "AVG DOW",
            data: [
                pickupForecast.variables.vs_avg_dow.value,
                `${pickupForecast.variables.vs_avg_dow.value_diff}%`,
                pickupForecast.variables.vs_avg_dow.color,
                pickupForecast.variables.vs_avg_dow.color_score,
                pickupForecast.variables.vs_avg_dow.weight_score,
                JSON.stringify(pickupForecast.instance.vs_avg_dow.value),
                pickupForecast.instance.vs_avg_dow.no_instance,
                pickupForecast.instance.vs_avg_dow.average,
                pickupForecast.instance.vs_avg_dow.color,
                pickupForecast.instance.vs_avg_dow.color_score,
                pickupForecast.instance.vs_avg_dow.weight_score,
            ],
            color: null
        },
        custom_avg: {
            name: "CSTM AVG",
            data: [
                pickupForecast.variables.vs_custom_avg?.value,
                `${pickupForecast.variables.vs_custom_avg?.value_diff}%`,
                pickupForecast.variables.vs_custom_avg?.color,
                pickupForecast.variables.vs_custom_avg?.color_score,
                pickupForecast.variables.vs_custom_avg?.weight_score,
                JSON.stringify(pickupForecast.instance.vs_custom_avg?.value),
                pickupForecast.instance.vs_custom_avg?.no_instance,
                pickupForecast.instance.vs_custom_avg?.average,
                pickupForecast.instance.vs_custom_avg?.color,
                pickupForecast.instance.vs_custom_avg?.color_score,
                pickupForecast.instance.vs_custom_avg?.weight_score,
            ],
            color: null
        },
        custom_avg_dow: {
            name: "CSTM AVG DOW",
            data: [
                pickupForecast.variables.vs_custom_avg_dow?.value,
                `${pickupForecast.variables.vs_custom_avg_dow?.value_diff}%`,
                pickupForecast.variables.vs_custom_avg_dow?.color,
                pickupForecast.variables.vs_custom_avg_dow?.color_score,
                pickupForecast.variables.vs_custom_avg_dow?.weight_score,
                JSON.stringify(pickupForecast.instance.vs_custom_avg_dow?.value),
                pickupForecast.instance.vs_custom_avg_dow?.no_instance,
                pickupForecast.instance.vs_custom_avg_dow?.average,
                pickupForecast.instance.vs_custom_avg_dow?.color,
                pickupForecast.instance.vs_custom_avg_dow?.color_score,
                pickupForecast.instance.vs_custom_avg_dow?.weight_score,
            ],
            color: null
        },
    };

    const renderPickupSummary = () => {
        return (
            <div>
                <p><strong>PICKUP FORECAST ({settings.pickup.days} Days)</strong></p>
                <p>
                    <strong style={{ marginRight: 8 }}>Current Pickup:</strong>
                    <span>{JSON.stringify(pickupForecast.current)}</span>
                    <br />
                    <strong style={{ marginRight: 8 }}>Pickup Score:</strong>
                    <span>{JSON.stringify(pickupForecast.forecast.sum)}</span>
                    <br />
                    <strong style={{ marginRight: 8 }}>Pickup Color:</strong>
                    <span>{displayCopy(pickupForecast.forecast.color)}</span>
                </p>
            </div>
        )
    }


    // Pace Forecast Table Data
    let pace_headers = [
        "",
        "Pace",
        "% Diff",
        "V-Color",
        "V-Color Score",
        "V-Weighted Score",
        `Instances ${useColorScore ? 'Color' : 'Score'}`,
        "Instances Qty",
        "I-Average",
        "I-Color",
        "I-Color Score",
        "I-Weighted Score"
    ];
    let pace_data = {
        sdly: {
            name: "SDLY",
            data: [
                paceForecast.variables.vs_sdly.value,
                `${paceForecast.variables.vs_sdly.value_diff}%`,
                paceForecast.variables.vs_sdly.color,
                paceForecast.variables.vs_sdly.color_score,
                paceForecast.variables.vs_sdly.weight_score,
                JSON.stringify(paceForecast.instance.vs_sdly.value),
                paceForecast.instance.vs_sdly.no_instance,
                paceForecast.instance.vs_sdly.average,
                paceForecast.instance.vs_sdly.color,
                paceForecast.instance.vs_sdly.color_score,
                paceForecast.instance.vs_sdly.weight_score,
            ],
            color: null
        },
        avg: {
            name: "AVG",
            data: [
                paceForecast.variables.vs_avg.value,
                `${paceForecast.variables.vs_avg.value_diff}%`,
                paceForecast.variables.vs_avg.color,
                paceForecast.variables.vs_avg.color_score,
                paceForecast.variables.vs_avg.weight_score,
                JSON.stringify(paceForecast.instance.vs_avg.value),
                paceForecast.instance.vs_avg.no_instance,
                paceForecast.instance.vs_avg.average,
                paceForecast.instance.vs_avg.color,
                paceForecast.instance.vs_avg.color_score,
                paceForecast.instance.vs_avg.weight_score,
            ],
            color: null
        },
        avg_dow: {
            name: "AVG DOW",
            data: [
                paceForecast.variables.vs_avg_dow.value,
                `${paceForecast.variables.vs_avg_dow.value_diff}%`,
                paceForecast.variables.vs_avg_dow.color,
                paceForecast.variables.vs_avg_dow.color_score,
                paceForecast.variables.vs_avg_dow.weight_score,
                JSON.stringify(paceForecast.instance.vs_avg_dow.value),
                paceForecast.instance.vs_avg_dow.no_instance,
                paceForecast.instance.vs_avg_dow.average,
                paceForecast.instance.vs_avg_dow.color,
                paceForecast.instance.vs_avg_dow.color_score,
                paceForecast.instance.vs_avg_dow.weight_score,
            ],
            color: null
        },
        custom_avg: {
            name: "CSTM AVG",
            data: [
                paceForecast.variables.vs_custom_avg?.value,
                `${paceForecast.variables.vs_custom_avg?.value_diff}%`,
                paceForecast.variables.vs_custom_avg?.color,
                paceForecast.variables.vs_custom_avg?.color_score,
                paceForecast.variables.vs_custom_avg?.weight_score,
                JSON.stringify(paceForecast.instance.vs_custom_avg?.value),
                paceForecast.instance.vs_custom_avg?.no_instance,
                paceForecast.instance.vs_custom_avg?.average,
                paceForecast.instance.vs_custom_avg?.color,
                paceForecast.instance.vs_custom_avg?.color_score,
                paceForecast.instance.vs_custom_avg?.weight_score,
            ],
            color: null
        },
        custom_avg_dow: {
            name: "CSTM AVG DOW",
            data: [
                paceForecast.variables.vs_custom_avg_dow?.value,
                `${paceForecast.variables.vs_custom_avg_dow?.value_diff}%`,
                paceForecast.variables.vs_custom_avg_dow?.color,
                paceForecast.variables.vs_custom_avg_dow?.color_score,
                paceForecast.variables.vs_custom_avg_dow?.weight_score,
                JSON.stringify(paceForecast.instance.vs_custom_avg_dow?.value),
                paceForecast.instance.vs_custom_avg_dow?.no_instance,
                paceForecast.instance.vs_custom_avg_dow?.average,
                paceForecast.instance.vs_custom_avg_dow?.color,
                paceForecast.instance.vs_custom_avg_dow?.color_score,
                paceForecast.instance.vs_custom_avg_dow?.weight_score,
            ],
            color: null
        },
    };

    const renderPaceSummary = () => {
        return (
            <div>
                <p><strong>PACE FORECAST</strong></p>
                <p>
                    <strong style={{ marginRight: 8 }}>Current Rooms Sold:</strong>
                    <span>{JSON.stringify(paceForecast.current)}</span>
                    <br />
                    <strong style={{ marginRight: 8 }}>Pace Score:</strong>
                    <span>{JSON.stringify(paceForecast.forecast.sum)}</span>
                    <br />
                    <strong style={{ marginRight: 8 }}>Pace Color:</strong>
                    <span>{displayCopy(paceForecast.forecast.color)}</span>
                </p>
            </div>
        )
    }


    // Price Forecast Table Data
    let price_headers = [
        "",
        "Price",
        "% Weight",
        "Weighted Score"
    ];
    let price_data = {
        sdly: {
            name: "SDLY",
            data: [
                priceForecast.breakdown.sdly.price,
                `${priceForecast.breakdown.sdly.weight}%`,
                priceForecast.breakdown.sdly.weight_score
            ],
            color: null
        },
        avg: {
            name: "AVG",
            data: [
                priceForecast.breakdown.avg.price,
                `${priceForecast.breakdown.avg.weight}%`,
                priceForecast.breakdown.avg.weight_score
            ],
            color: null
        },
        avg_dow: {
            name: "AVG DOW",
            data: [
                priceForecast.breakdown.avg_dow.price,
                `${priceForecast.breakdown.avg_dow.weight}%`,
                priceForecast.breakdown.avg_dow.weight_score
            ],
            color: null
        },
        custom_avg: {
            name: "CSTM AVG",
            data: [
                priceForecast.breakdown.custom_avg.price,
                `${priceForecast.breakdown.custom_avg.weight}%`,
                priceForecast.breakdown.custom_avg.weight_score
            ],
            color: null
        },
        custom_avg_dow: {
            name: "CSTM AVG DOW",
            data: [
                priceForecast.breakdown.custom_avg_dow.price,
                `${priceForecast.breakdown.custom_avg_dow.weight}%`,
                priceForecast.breakdown.custom_avg_dow.weight_score
            ],
            color: null
        },
        remaindays_sdly: {
            name: "RemainDays SDLY",
            data: [
                priceForecast.breakdown.remaindays_sdly.price,
                `${priceForecast.breakdown.remaindays_sdly.weight}%`,
                priceForecast.breakdown.remaindays_sdly.weight_score
            ],
            color: null
        },
        remaindays_avg: {
            name: "RemainDays AVG",
            data: [
                priceForecast.breakdown.remaindays_avg.price,
                `${priceForecast.breakdown.remaindays_avg.weight}%`,
                priceForecast.breakdown.remaindays_avg.weight_score
            ],
            color: null
        },
        remaindays_avg_dow: {
            name: "RemainDays AVG DOW",
            data: [
                priceForecast.breakdown.remaindays_avg_dow.price,
                `${priceForecast.breakdown.remaindays_avg_dow.weight}%`,
                priceForecast.breakdown.remaindays_avg_dow.weight_score
            ],
            color: null
        },
        remaindays_custom_avg: {
            name: "RemainDays CSTM AVG",
            data: [
                priceForecast.breakdown.remaindays_custom_avg.price,
                `${priceForecast.breakdown.remaindays_custom_avg.weight}%`,
                priceForecast.breakdown.remaindays_custom_avg.weight_score
            ],
            color: null
        },
        remaindays_custom_avg_dow: {
            name: "RemainDays CSTM AVG DOW",
            data: [
                priceForecast.breakdown.remaindays_custom_avg_dow.price,
                `${priceForecast.breakdown.remaindays_custom_avg_dow.weight}%`,
                priceForecast.breakdown.remaindays_custom_avg_dow.weight_score
            ],
            color: null
        },
        competitor_pricing: {
            name: "Competitor Pricing",
            data: [
                priceForecast.breakdown.competitor_pricing.price,
                `${priceForecast.breakdown.competitor_pricing.weight}%`,
                priceForecast.breakdown.competitor_pricing.weight_score
            ],
            color: null
        },
    };

    const renderPriceSummary = () => {
        return (
            <div>
                <p><strong>PRICE FORECAST</strong></p>
                <p>
                    <strong style={{ marginRight: 8 }}>Current Price:</strong>
                    <span>{`${currency} ${JSON.stringify(priceForecast.current)}`}</span>
                    <br />
                    <strong style={{ marginRight: 8 }}>Comparison Price:</strong>
                    <span>{`${currency} ${JSON.stringify(priceForecast.forecast.sum)}`}</span>
                    <br />
                    <strong style={{ marginRight: 8 }}>Comparison Price % Difference:</strong>
                    <span>{`${JSON.stringify(priceForecast.forecast.percent_diff)}%`}</span>
                    <br />
                    <strong style={{ marginRight: 8 }}>Comparison Price Color:</strong>
                    <span>{displayCopy(priceForecast.forecast.color)}</span>
                </p>
            </div>
        )
    }


    // Finals Forecast Table Data
    let finals_headers_1 = [
        "",
        "Rooms Sold",
        "% Occupancy",
        "Color",
    ];
    let finals_headers_2 = [
        "",
        "Color Score",
        "Weight",
        "Weighted Score",
    ];
    let finals_data_1 = {
        sdly: {
            name: "SDLY",
            data: [
                finalsForecast.variables.vs_sdly.value,
                `${finalsForecast.variables.vs_sdly.value_diff}%`,
                finalsForecast.variables.vs_sdly.color,
            ],
            color: null
        },
        avg: {
            name: "AVG",
            data: [
                finalsForecast.variables.vs_avg.value,
                `${finalsForecast.variables.vs_avg.value_diff}%`,
                finalsForecast.variables.vs_avg.color,

            ],
            color: null
        },
        avg_dow: {
            name: "AVG DOW",
            data: [
                finalsForecast.variables.vs_avg_dow.value,
                `${finalsForecast.variables.vs_avg_dow.value_diff}%`,
                finalsForecast.variables.vs_avg_dow.color,

            ],
            color: null
        },
        custom_avg: {
            name: "CSTM AVG",
            data: [
                finalsForecast.variables.vs_custom_avg?.value,
                `${finalsForecast.variables.vs_custom_avg?.value_diff}%`,
                finalsForecast.variables.vs_custom_avg?.color,

            ],
            color: null
        },
        custom_avg_dow: {
            name: "CSTM AVG DOW",
            data: [
                finalsForecast.variables.vs_custom_avg_dow?.value,
                `${finalsForecast.variables.vs_custom_avg_dow?.value_diff}%`,
                finalsForecast.variables.vs_custom_avg_dow?.color,

            ],
            color: null
        },
        otb: {
            name: "OTB",
            data: [
                finalsForecast.variables.vs_otb.value,
                `${finalsForecast.variables.vs_otb.value_diff}%`,
                finalsForecast.variables.vs_otb.color,

            ],
            color: null
        },
    };
    let finals_data_2 = {
        sdly: {
            name: "SDLY",
            data: [
                finalsForecast.variables.vs_sdly.color_score,
                finalsForecast.variables.vs_sdly.weight,
                finalsForecast.variables.vs_sdly.weight_score
            ],
            color: null
        },
        avg: {
            name: "AVG",
            data: [
                finalsForecast.variables.vs_avg.color_score,
                finalsForecast.variables.vs_avg.weight,
                finalsForecast.variables.vs_avg.weight_score

            ],
            color: null
        },
        avg_dow: {
            name: "AVG DOW",
            data: [
                finalsForecast.variables.vs_avg_dow.color_score,
                finalsForecast.variables.vs_avg_dow.weight,
                finalsForecast.variables.vs_avg_dow.weight_score

            ],
            color: null
        },
        custom_avg: {
            name: "CSTM AVG",
            data: [
                finalsForecast.variables.vs_custom_avg?.color_score,
                finalsForecast.variables.vs_custom_avg?.weight,
                finalsForecast.variables.vs_custom_avg?.weight_score

            ],
            color: null
        },
        custom_avg_dow: {
            name: "CSTM AVG DOW",
            data: [
                finalsForecast.variables.vs_custom_avg_dow?.color_score,
                finalsForecast.variables.vs_custom_avg_dow?.weight,
                finalsForecast.variables.vs_custom_avg_dow?.weight_score

            ],
            color: null
        },
        otb: {
            name: "OTB",
            data: [
                finalsForecast.variables.vs_otb.color_score,
                finalsForecast.variables.vs_otb.weight,
                finalsForecast.variables.vs_otb.weight_score
            ],
            color: null
        },
    };

    const renderFinalsSummary = () => {
        return (
            <div>
                <p><strong>FINALS FORECAST</strong></p>
                <p>
                    <strong style={{ marginRight: 8 }}>Inventory:</strong>
                    <span>{JSON.stringify(finalsForecast.inventory)}</span>
                    <br />
                    <strong style={{ marginRight: 8 }}>Finals Forecast Score:</strong>
                    <span>{JSON.stringify(finalsForecast.forecast.sum)}</span>
                    <br />
                    <strong style={{ marginRight: 8 }}>Finals Forecast Color:</strong>
                    <span>{displayCopy(finalsForecast.forecast.color)}</span>
                </p>
            </div>
        )
    }


    // Price Recommendation Information
    const renderPriceRecoSummary = () => {
        return (
            <div>
                <p><strong>PRICING RECOMMENDATION</strong></p>
                <p>
                    <strong style={{ marginRight: 8 }}>Action:</strong>
                    <span>{displayCopy(priceRecommendation.recommendation.definition)}</span>
                    <br />
                    <strong style={{ marginRight: 8 }}>Target % Value:</strong>
                    <span>{`${JSON.stringify(priceRecommendation.breakdown.adjustment)}%`}</span>
                    <br />
                    <strong style={{ marginRight: 8 }}>Target Price % Adjustment:</strong>
                    <span>{`${JSON.stringify(priceRecommendation.recommendation.percent)}%`}</span>
                    <br />
                    <strong style={{ marginRight: 8 }}>Recommendation:</strong>
                    <PriceRecoIcon quantity={priceRecommendation.recommendation.arrow_quantity} hasCopy={true} />
                    <br />
                    <br />
                    <strong style={{ marginRight: 8 }}>Current Price:</strong>
                    <span>{`${currency} ${JSON.stringify(priceRecommendation.current)}`}</span>
                    <br />
                    <strong style={{ marginRight: 8 }}>Comparison Price:</strong>
                    <span>{`${currency} ${JSON.stringify(priceRecommendation.breakdown.comparison)}`}</span>
                </p>
            </div>
        )
    }





    return (
        <div>
            {/* Overview Summary and Table */}
            <div style={{
                display: "grid", gridAutoFlow: "column", width: "100%",
                justifyItems: "center", marginBottom: 20
            }}>
                <p><strong>OVERVIEW</strong></p>
                <TableChart headers={overview_headers} data={overview_data}
                    rowIndecesWithColor={[0, 1, 2, 3, 4]} allowString={true}
                    rowNameWidth={150} rowDataWidth={115} />
            </div>
            <hr />


            {/* Pickup, Pace Summaries, and Demand Summary/Table */}
            <div style={{
                display: "grid", gridAutoFlow: "column", width: "100%",
                gridTemplateColumns: "20% 20% 25% 35%", marginTop: 20
            }}>
                {renderPickupSummary()}
                {renderPaceSummary()}
                {renderDemandSummary()}

                <TableChart headers={demand_headers} data={demand_data}
                    rowIndecesWithColor={[1]} allowString={true}
                    rowNameWidth={150} rowDataWidth={115} />
            </div>


            {/* Pickup and Pace Tables */}
            <TableChart headers={pickup_headers} data={pickup_data}
                rowIndecesWithColor={[2, 8]} rowIndecesWithMultiColor={[5]}
                allowString={true} rowNameWidth={150} rowDataWidth={115} />
            <br />
            <TableChart headers={pace_headers} data={pace_data}
                rowIndecesWithColor={[2, 8]} rowIndecesWithMultiColor={[5]}
                allowString={true} rowNameWidth={150} rowDataWidth={115} />
            <p><br /></p>


            <div style={{
                display: "grid", gridAutoFlow: "column", width: "100%",
                justifyItems: "center", gridTemplateColumns: "40% 30% 30%"
            }}>
                {/* Price Summary and Table */}
                <div>
                    {renderPriceSummary()}
                    <TableChart headers={price_headers} data={price_data}
                        rowIndecesWithCurrency={[0]} allowString={true}
                        rowNameWidth={150} rowDataWidth={115} />
                </div>

                {/* Finals Summary and Table */}
                <div style={{ paddingLeft: 20 }}>
                    {renderFinalsSummary()}
                    <TableChart headers={finals_headers_1} data={finals_data_1}
                        rowIndecesWithColor={[2]} allowString={true}
                        rowNameWidth={150} rowDataWidth={115} />
                    <TableChart headers={finals_headers_2} data={finals_data_2}
                        allowString={true} rowNameWidth={150} rowDataWidth={115} />
                </div>

                {/* Price Recommendation Summary */}
                {renderPriceRecoSummary()}
            </div>
        </div>
    )
}


export default AlgorithmModalDaily;
