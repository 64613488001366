import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import CustomTabs from '../../components/CustomTabs/CustomTabs';
import Accordion from 'elements/Accordion/Accordion';
import { 
    Segments, 
    IntegrationPartners, 
    RoomTypes, 
    MessageLogs, 
    BookingSources, 
    Competitors,
    Roles,
    Users
} from '../../components/AdminConsole';
import './Admin.scss';
import { useLocation } from 'react-router-dom';
import usePermissions from 'hooks/usePermissions';


const Admin = () => {
    const { canAccess } = usePermissions();
    const location = useLocation();
    const [activeKey, setActiveKey] = useState();

    const predefinedListItems = [{
        key: "segments",
        label: "Segments",
        visible: canAccess('admin_console', 'segment', 'list'),
        content: () => <Segments/>
    }, {
        key: "partners",
        label: "Integration Partners",
        visible: canAccess('admin_console', 'integration_partner', 'list'),
        content: () => <IntegrationPartners/>
    }, {
        key: "room_types",
        label: "Room Types",
        visible: canAccess('admin_console', 'room_type', 'list'),
        content: () => <RoomTypes/>
    }, {
        key: "booking_sources",
        label: "Booking Sources",
        visible: canAccess('admin_console', 'booking_source', 'list'),
        content: () => <BookingSources/>
    }, {
        key: "competitors",
        label: "Competitors",
        visible: canAccess('admin_console', 'competitor', 'list'),
        content: () => <Competitors/>
    }];

    const rolesAndUsersItems = [{
        key: "roles",
        label: "Role Management",
        visible: canAccess('role', 'list'),
        content: () => <Roles />
    }, {
        key: "users",
        label: "User Management",
        visible: canAccess('role', 'list'),
        content: () => <Users />
    }];

    const tabs = [{ 
        key: 'predefined_lists', 
        label: 'PREDEFINED LISTS',
        hide: !canAccess('admin_console', 'section', 'predefined_lists')
        // hide: !canAccess('admin_console', 'segment', 'list') &&
        //     !canAccess('admin_console', 'integration_partner', 'list') &&
        //     !canAccess('admin_console', 'room_type', 'list') && 
        //     !canAccess('admin_console', 'booking_source', 'list') && 
        //     !canAccess('admin_console', 'competitor', 'list')
    }, { 
        key: 'message_logs', 
        label: 'MESSAGE LOGS',
        hide: !canAccess('admin_console', 'section', 'message_logs')
        // hide: !canAccess('admin_console', 'message_log', 'list')
    }, {
        key: 'roles_and_users', 
        label: 'ROLES AND USERS',
        hide: !canAccess('admin_console', 'section', 'roles_and_users')
        // hide: !canAccess('role', 'list') && !canAccess('user', 'list')
    }];

    // useEffect(() => {

    // }, [window.href])
    useEffect(() => {
        setActiveKey(location.hash.replace('#', ''))
    }, [location.hash])

    const getSelectedTab = () => {
        switch(location.hash.replace('#', '')) {
            case 'roles': 
            case 'users': 
                return 'roles_and_users';
            case 'message_logs': 
                return 'message_logs';
            case 'segments': 
            case 'room_types':
            case 'booking_sources':
            case 'partners': 
            case 'competitors': 
                return 'predefined_lists';
            default: return null;
        }
    }

    return (
        <div className="admin page">
            <Helmet><title>Rev&You | Admin Console</title></Helmet>
            <div>
                <div className="container centered bg-white property-info-header" style={{ padding: '16px 80px 0' }}>
                    <h2>Admin Console</h2>
                </div>
            </div>
            <div className="page-content">
                <CustomTabs name="adminConsoleTabs" tabs={tabs} selectedTab={getSelectedTab()}>
                    <CustomTabs.Tab key="predefined_lists">
                        <div style={{width: '1040px', borderTop: "1px solid white", margin: '40px auto 0 auto', paddingTop: '7px'}}>
                            <Accordion
                                headerClassname="predefined-list-accordion-header"
                                accordion={true}
                                items={predefinedListItems}
                                collapse={true}
                                onChange={(key) => setActiveKey(key)}
                                activeKey={activeKey}
                            ></Accordion>
                        </div> 
                    </CustomTabs.Tab>
                    <CustomTabs.Tab key="message_logs">
                        <div style={{width: '1250px', margin: '20px auto 0 auto', paddingTop: '7px'}}>
                            <MessageLogs></MessageLogs>
                        </div> 
                    </CustomTabs.Tab>
                    <CustomTabs.Tab key="roles_and_users">
                        <div style={{width: '1040px', borderTop: "1px solid white", margin: '40px auto 0 auto', paddingTop: '7px'}}>
                            <Accordion
                                headerClassname="roles-and-users-accordion-header"
                                accordion={true}
                                items={rolesAndUsersItems}
                                collapse={true}
                                onChange={(key) => setActiveKey(key)}
                                activeKey={activeKey}
                            ></Accordion>
                        </div>
                    </CustomTabs.Tab>
                </CustomTabs>
            </div>
        </div>
    )
}

export default Admin;