import { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import Switch from 'components/Switch/Switch';
import useRoleActions from 'store/actions/roleActions';
import permissionsList from 'utils/permissions.js';
import Button from "../../Button/Button";
import { ToastsStore } from 'react-toasts';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner.js';
import usePermissions from 'hooks/usePermissions';
import CustomTabs from 'components/CustomTabs/CustomTabs';

const RolesForm = () => {
    const { canUpdate } = usePermissions('role');
    const navigate = useNavigate();
    const location = useLocation();
    const { getRole, addRole, updateRole } = useRoleActions();
    const { id: roleId } = useParams();
    const [roleName, setRoleName] = useState();
    const [permissions, setPermissions] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const isAddMode = location.pathname.includes('/add');
    const isEditMode = roleId && location.pathname.includes('/edit');

    const getPageTitle = () => {
        let title = "RevAndYou";

        if (isAddMode) title = "Add Role";
        if (isEditMode) title = "Edit Role";

        return title;
    }
    
    useEffect(() => {
        if (isEditMode) {
            getRole(roleId).then(result => {
                if (result.type === 'GET_ROLE_SUCCESS') {
                    const role = result.response.data.data;
                    setRoleName(role.name);
                    setPermissions(Object.keys(permissionsList).reduce((obj, key) => {
                        return { ...obj, [key]: role.permissions.includes(key)};
                    }, {}));
                    setIsLoading(false);
                }
            });
        } 

        if (isAddMode) {
            setPermissions(Object.keys(permissionsList).reduce((obj, key) => {
                const disabledPermissions = [
                    permissionsList.ACCESS_PROPERTY_UNMAPPED_INFO,
                    permissionsList.ACCESS_PROPERTY_UNMAPPED_LIST,
                    permissionsList.UPDATE_PROPERTY_UNMAPPED_ENTRY,
                    permissionsList.DELETE_PROPERTY_UNMAPPED_ENTRY
                ];
                return { ...obj, [key]: !disabledPermissions.includes(key)};
            }, {}))
            setIsLoading(false);
        }
    }, []);

    const Field = ({ label, permission = "", disabled = false }) => {
        const isEnabled = permissions[permission] === true;
        return <div className={`forms-field`} style={{ cursor: 'pointer' }} onClick={() => { 
            setPermissions(prev => ({ ...prev, [permission]: !prev[permission]}))
        }}>
            <p className="field__label">
                <span style={{ color: isEnabled? 'white': '#d1d3d4'}}>{label}</span>
            </p>
            <div style={{ display: "inline-flex", marginTop: "auto", marginBottom: "auto" }}>
                <span style={{ marginRight: 8, fontSize: 14 }}></span>
                <Switch checked={permissions[permission] === true} disabled={disabled === true} />
            </div>
        </div>;
    }

    const GroupField = ({ label, permission = [], disabled = false }) => {
        const allEnabled = permission.every(key => permissions[key] === true);
        const someEnabled = permission.some(key => permissions[key] === true);
        return <div className={['forms-field', ...[disabled === true && 'disabled']].join(' ')} style={{ cursor: disabled? 'not-allowed': 'pointer' }} onClick={() => { 
            if (disabled === true) return;
            setPermissions(prev => {
                const newPermissions = { ...prev };
                permission.forEach(key => {
                    newPermissions[key] = !(allEnabled || someEnabled);
                });
                return newPermissions;
            });
        }}>
            <p className="field__label">
                <span style={{ color: allEnabled? 'white': '#d1d3d4'}}>{label}</span>
            </p>
            <div className={!allEnabled && someEnabled? 'some-enabled': ''} style={{ display: "inline-flex", marginTop: "auto", marginBottom: "auto" }}>
                <span style={{ marginRight: 8, fontSize: 14 }}></span>
                <Switch checked={allEnabled || someEnabled} disabled={disabled === true} />
            </div>
        </div>;
    }

    const handleSave = () => {
        const allowedPermissions = Object.keys(permissions).filter(key => permissions[key] === true);
        if (!roleName) {
            setRoleName('');
            return;
        }
        if (isAddMode) {
            setIsLoading(true);
            addRole({
                name: roleName,
                permissions: allowedPermissions
            }).then(result => {
                if (result.type === 'ADD_ROLE_SUCCESS') {
                    const role = result.response.data.data;
                    console.log(result);
                    ToastsStore.success(`Role: ${roleName} was successfuly added`);
                    // navigate(`/roles/${role._id}/edit`);
                    setIsLoading(false);
                    navigate('/admin#roles');
                } else {
                    ToastsStore.error(result.error.response.data.message);
                }
            }).catch(error => {
                console.log(error);
                ToastsStore.error(error.response.data.message);
            })
        }

        if (isEditMode) {
            setIsLoading(true);
            updateRole(roleId, {
                name: roleName,
                permissions: allowedPermissions
            }).then(result => {
                console.log(result);
                if (result.type === 'UPDATE_ROLE_SUCCESS') {
                    const role = result.response.data.data;
                    console.log(result);
                    ToastsStore.success(`Role: ${roleName} was successfuly updated`);
                    setIsLoading(false);
                    // navigate('/admin#roles');
                } else {
                    ToastsStore.error(result.error.response.data.message);
                }
            }).catch(error => {
                console.log(error);
                ToastsStore.error(error.response.data.message);
            });
        }
    }
    
    return <div className="role-form forms page">
        {isLoading && <LoadingSpinner  mask={true} /> }
        <Helmet>{getPageTitle()}</Helmet>

        <div>
            <div className='container centered bg-white' style={{ padding: '16px 80px 0' }}>
                <h2>Manage Role</h2>
            </div>
        </div>
        <div className='container centered' style={{ paddingTop: '24px' }}>
            <div className='row'>
                <div className={`forms-field role-name-input`} style={{ width: '100%' }}>
                    <p className="field__label"><span className={(roleName === '')? 'error': ''}>Name</span></p>
                    <input
                        type="text"
                        value={roleName || ''}
                        onChange={(e) => setRoleName(e.target.value) }
                        className={`field__input`}
                    />
                </div>
            </div>
            <div>

            </div>
            <CustomTabs name='role-config' tabs={[
                { key: 'basic', label: 'Basic' }, 
                { key: 'advanced', label: 'Advanced' }
            ]}>
                <CustomTabs.Tab key='basic'>
                    <>
                        <div className='row'>
                            <div className='forms-section'>
                                <h3>USER ROLES MODULE</h3>
                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px'}}>
                                    {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gridRow: '1 / 6'}} >ROLE</div> */}
                                    <GroupField disabled={!canUpdate()} label="VIEW ROLE" permission={[
                                        permissionsList.ACCESS_ROLE_INFO,
                                        permissionsList.ACCESS_ROLE_LIST ]}/>
                                    <GroupField disabled={!canUpdate()} label="CREATE ROLE" permission={[
                                        permissionsList.CREATE_ROLE_ENTRY]}/>
                                    <GroupField disabled={!canUpdate()} label="UPDATE ROLE" permission={[
                                        permissionsList.UPDATE_ROLE_ENTRY]}/>
                                    <GroupField disabled={!canUpdate()} label="DELETE ROLE" permission={[
                                        permissionsList.DELETE_ROLE_ENTRY]}/>
                                    <GroupField disabled={!canUpdate()} label="VIEW USER" permission={[
                                        permissionsList.ACCESS_USER_INFO,
                                        permissionsList.ACCESS_USER_LIST ]}/>
                                    <GroupField disabled={!canUpdate()} label="CREATE USER" permission={[
                                        permissionsList.CREATE_USER_ENTRY]}/>
                                    <GroupField disabled={!canUpdate()} label="EDIT USER DETAILS" permission={[
                                        permissionsList.UPDATE_USER_ENTRY]}/>
                                    <GroupField disabled={!canUpdate()} label="DELETE USER" permission={[
                                        permissionsList.DELETE_USER_ENTRY]}/>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='forms-section'>
                                <h3>PROPERTIES MODULE</h3>
                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px'}}>
                                    {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gridRow: '1 / 6'}} >ROLE</div> */}
                                    <GroupField disabled={!canUpdate()} label="ACCESS PROPERTY MODULE TAB" permission={[
                                        permissionsList.ACCESS_PROPERTY_SECTION_INFORMATION ]}/>
                                    <GroupField disabled={!canUpdate()} label="CREATE PROPERTY" permission={[
                                        permissionsList.CREATE_PROPERTY_ENTRY ]}/>
                                    <GroupField disabled={!canUpdate()} label="VIEW PROPERTY INFO" permission={[
                                        permissionsList.ACCESS_PROPERTY_DETAILS ]}/>
                                    <GroupField disabled={!canUpdate()} label="EDIT PROPERTY INFO" permission={[
                                        permissionsList.UPDATE_PROPERTY_DETAILS ]}/>
                                    <GroupField disabled={!canUpdate()} label="EDIT ROOM AVAILABILITY" permission={[
                                        permissionsList.ACCESS_PROPERTY_ROOM_AVAILABILITY,
                                        permissionsList.CREATE_PROPERTY_ROOM_AVAILABILITY_ENTRY,
                                        permissionsList.UPDATE_PROPERTY_ROOM_AVAILABILITY_ENTRY,
                                        permissionsList.DELETE_PROPERTY_ROOM_AVAILABILITY_ENTRY ]}/>
                                    <GroupField disabled={!canUpdate()} label="USER MAPPING IN PROPERTY" permission={[
                                        permissionsList.ACCESS_PROPERTY_USER_MAPPING,
                                        permissionsList.UPDATE_PROPERTY_USER_MAPPING_ENTRY ]}/>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='forms-section'>
                                <h3>ADMIN CONTROLS MODULE</h3>
                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px'}}>
                                    {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gridRow: '1 / 6'}} >ROLE</div> */}
                                    <GroupField disabled={!canUpdate()} label="ACCESS ADMIN CONTROL TAB" permission={[
                                        permissionsList.ACCESS_PROPERTY_SECTION_ADMIN_CONTROLS ]}/>
                                    <GroupField disabled={!canUpdate()} label="EDIT ADMIN CONTROL STATUS" permission={[
                                        permissionsList.UPDATE_PROPERTY_SECTION_ADMIN_CONTROLS,
                                        permissionsList.UPDATE_PROPERTY_STATUS ]}/>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='forms-section'>
                                <h3>SEGMENTS MODULE</h3>
                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px'}}>
                                    {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gridRow: '1 / 6'}} >ROLE</div> */}
                                    <GroupField disabled={!canUpdate()} label="ACCESS SEGMENTS TAB" permission={[
                                        permissionsList.ACCESS_PROPERTY_SECTION_SEGMENTS,
                                        permissionsList.ACCESS_PROPERTY_SEGMENT_INFO,
                                        permissionsList.ACCESS_PROPERTY_SEGMENT_LIST,
                                        permissionsList.ACCESS_PROPERTY_SEGMENT_MAPPING_INFO,
                                        permissionsList.ACCESS_PROPERTY_SEGMENT_MAPPING_LIST ]}/>
                                    <GroupField disabled={!canUpdate()} label="EDIT SEGMENTS SETTINGS" permission={[
                                        permissionsList.CREATE_PROPERTY_SEGMENT_ENTRY,
                                        permissionsList.CREATE_PROPERTY_SEGMENT_MAPPING,
                                        permissionsList.CREATE_PROPERTY_SEGMENT_GROUP_ENTRY,
                                        permissionsList.UPDATE_PROPERTY_SEGMENT_ENTRY,
                                        permissionsList.UPDATE_PROPERTY_SEGMENT_MAPPING,
                                        permissionsList.UPDATE_PROPERTY_SEGMENT_GROUP_ENTRY,
                                        permissionsList.DELETE_PROPERTY_SEGMENT_ENTRY,
                                        permissionsList.DELETE_PROPERTY_SEGMENT_MAPPING,
                                        permissionsList.DELETE_PROPERTY_SEGMENT_GROUP_ENTRY, ]}/>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='forms-section'>
                                <h3>FORECAST SETTINGS MODULE</h3>
                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px'}}>
                                    {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gridRow: '1 / 6'}} >ROLE</div> */}
                                    <GroupField disabled={!canUpdate()} label="ACCESS FORECAST SETTINGS TAB" permission={[
                                        permissionsList.ACCESS_PROPERTY_SECTION_FORECAST_SETTINGS,
                                        permissionsList.ACCESS_PROPERTY_FORECAST_SETTINGS_VARIABLE_SETTINGS,
                                        permissionsList.ACCESS_PROPERTY_FORECAST_SETTINGS_WEIGHTS ]}/>
                                    <GroupField disabled={!canUpdate()} label="EDIT FORECAST SETTINGS" permission={[
                                        permissionsList.UPDATE_PROPERTY_SECTION_FORECAST_SETTINGS,
                                        permissionsList.UPDATE_PROPERTY_FORECAST_SETTINGS_VARIABLE_SETTINGS,
                                        permissionsList.UPDATE_PROPERTY_FORECAST_SETTINGS_WEIGHTS ]}/>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='forms-section'>
                                <h3>ROOM MAPPING MODULE</h3>
                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px'}}>
                                    {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gridRow: '1 / 6'}} >ROLE</div> */}
                                    <GroupField disabled={!canUpdate()} label="ACCESS ROOM MAPPING TAB" permission={[
                                        permissionsList.ACCESS_PROPERTY_SECTION_ROOM_TYPES,
                                        permissionsList.ACCESS_PROPERTY_ROOM_TYPE_INFO,
                                        permissionsList.ACCESS_PROPERTY_ROOM_TYPE_LIST,
                                        permissionsList.ACCESS_PROPERTY_ROOM_TYPE_DEFAULT_BAR,
                                        permissionsList.ACCESS_PROPERTY_ROOM_TYPE_MAPPING_INFO,
                                        permissionsList.ACCESS_PROPERTY_ROOM_TYPE_MAPPING_LIST ]}/>
                                    <GroupField disabled={!canUpdate()} label="EDIT ROOM MAPPING SETTINGS" permission={[
                                        permissionsList.UPDATE_PROPERTY_SECTION_ROOM_TYPES,
                                        permissionsList.CREATE_PROPERTY_ROOM_TYPE_ENTRY,
                                        permissionsList.CREATE_PROPERTY_ROOM_TYPE_MAPPING,
                                        permissionsList.UPDATE_PROPERTY_ROOM_TYPE_ENTRY,
                                        permissionsList.UPDATE_PROPERTY_ROOM_TYPE_MAPPING,
                                        permissionsList.DELETE_PROPERTY_ROOM_TYPE_ENTRY,
                                        permissionsList.DELETE_PROPERTY_ROOM_TYPE_MAPPING ]}/>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='forms-section'>
                                <h3>COMPETITOR MAPPING MODULE</h3>
                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px'}}>
                                    {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gridRow: '1 / 6'}} >ROLE</div> */}
                                    <GroupField disabled={!canUpdate()} label="ACCESS COMPETITOR MAPPING TAB" permission={[
                                        permissionsList.ACCESS_PROPERTY_SECTION_COMPETITORS,
                                        permissionsList.ACCESS_PROPERTY_COMPETITOR_INFO,
                                        permissionsList.ACCESS_PROPERTY_COMPETITOR_LIST ]}/>
                                    <GroupField disabled={!canUpdate()} label="EDIT COMPETITOR MAPPING SETTINGS" permission={[
                                        permissionsList.UPDATE_PROPERTY_SECTION_COMPETITORS,
                                        permissionsList.CREATE_PROPERTY_COMPETITOR_ENTRY,
                                        permissionsList.UPDATE_PROPERTY_COMPETITOR_ENTRY,
                                        permissionsList.DELETE_PROPERTY_COMPETITOR_ENTRY ]}/>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='forms-section'>
                                <h3>CUSTOM DASHBOARD MODULE</h3>
                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px'}}>
                                    {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gridRow: '1 / 6'}} >ROLE</div> */}
                                    <GroupField disabled={!canUpdate()} label="ACCESS DASHBOARD SETTINGS TAB" permission={[
                                        permissionsList.ACCESS_PROPERTY_SECTION_CUSTOM_DASHBOARD,
                                        permissionsList.ACCESS_PROPERTY_CUSTOM_DASHBOARD
                                    ]}/>
                                    <GroupField disabled={!canUpdate()} label="EDIT DASHBOARD SETTINGS" permission={[
                                        permissionsList.UPDATE_PROPERTY_SECTION_CUSTOM_DASHBOARD,
                                        permissionsList.UPDATE_PROPERTY_CUSTOM_DASHBOARD ]}/>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='forms-section'>
                                <h3>NOTES MODULE</h3>
                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px'}}>
                                    {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gridRow: '1 / 6'}} >ROLE</div> */}
                                    <GroupField disabled={!canUpdate()} label="VIEW NOTES" permission={[
                                        permissionsList.ACCESS_PROPERTY_NOTE_INFO,
                                        permissionsList.ACCESS_PROPERTY_NOTE_LIST
                                    ]}/>
                                    <GroupField disabled={!canUpdate()} label="ADD NOTES" permission={[
                                        permissionsList.CREATE_PROPERTY_NOTE_ENTRY ]}/>
                                    <GroupField disabled={!canUpdate()} label="EDIT NOTES" permission={[
                                        permissionsList.UPDATE_PROPERTY_NOTE_ENTRY ]}/>
                                    <GroupField disabled={!canUpdate()} label="DELETE NOTES" permission={[
                                        permissionsList.DELETE_PROPERTY_NOTE_ENTRY ]}/>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='forms-section'>
                                <h3>UPLOAD DATA MODULE</h3>
                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px'}}>
                                    {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gridRow: '1 / 6'}} >ROLE</div> */}
                                    <GroupField disabled={!canUpdate()} label="RESERVATIONS" permission={[
                                        permissionsList.ACCESS_PROPERTY_UPLOAD_RESERVATIONS ]}/>
                                    <GroupField disabled={!canUpdate()} label="RATES" permission={[
                                        permissionsList.ACCESS_PROPERTY_UPLOAD_RATES ]}/>
                                    <GroupField disabled={!canUpdate()} label="SEGMENTS" permission={[
                                        permissionsList.ACCESS_PROPERTY_UPLOAD_SEGMENTS ]}/>
                                    <GroupField disabled={!canUpdate()} label="EVENTS" permission={[
                                        permissionsList.ACCESS_PROPERTY_UPLOAD_EVENTS ]}/>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='forms-section'>
                                <h3>OTHER PERMISSIONS MODULE</h3>
                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px'}}>
                                    {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gridRow: '1 / 6'}} >ROLE</div> */}
                                    <GroupField disabled={!canUpdate()} label="EXPORT REPORT" permission={[
                                        permissionsList.ACCESS_PROPERTY_REPORT_EXPORT ]}/>
                                    <GroupField disabled={!canUpdate()} label="ALGORITHM" permission={[
                                        permissionsList.ACCESS_PROPERTY_ALGORITHM_MODAL ]}/>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='forms-section'>
                                <h3>ADMIN CONSOLE MODULE</h3>
                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px'}}>
                                    {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gridRow: '1 / 6'}} >ROLE</div> */}
                                    <GroupField disabled={!canUpdate()} label="ACCESS ADMIN CONSOLE MENU" permission={[
                                        permissionsList.ACCESS_ADMIN_CONSOLE_SECTION_PREDEFINED_LISTS,
                                        permissionsList.ACCESS_ADMIN_CONSOLE_SECTION_MESSAGE_LOGS,
                                        permissionsList.ACCESS_ADMIN_CONSOLE_SECTION_ROLES_AND_USERS ]}/>
                                    <GroupField disabled={!canUpdate()} label="ACCESS PREDEFINED LISTS" permission={[
                                        permissionsList.ACCESS_ADMIN_CONSOLE_SECTION_PREDEFINED_LISTS ]}/>
                                    <GroupField disabled={!canUpdate()} label="ACCESS MESSAGE LOGS" permission={[
                                        permissionsList.ACCESS_ADMIN_CONSOLE_SECTION_MESSAGE_LOGS ]}/>
                                    <GroupField disabled={!canUpdate()} label="ACCESS ROLES AND USERS" permission={[
                                        permissionsList.ACCESS_ADMIN_CONSOLE_SECTION_ROLES_AND_USERS ]}/>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'end', gap: '20px', padding: '20px 0' }}>
                                <Button type="bg-dark" onClick={() => { 
                                    history.back();
                                    // navigate(`/admin#roles`);
                                }}>
                                    Cancel
                                </Button>
                                {canUpdate() && <Button type="primary" onClick={() => handleSave()}>
                                    Submit
                                </Button>}
                            </div>
                    </>
                </CustomTabs.Tab>
                <CustomTabs.Tab key='advanced'>
                    <> 
                        <div className='row'>
                            <div className='forms-section'>
                                <h3>ROLE PERMISSIONS</h3>
                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px'}}>
                                    {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gridRow: '1 / 6'}} >ROLE</div> */}
                                    <Field label="VIEW LIST" permission={permissionsList.ACCESS_ROLE_LIST}/>
                                    <Field label="VIEW INFO" permission={permissionsList.ACCESS_ROLE_INFO}/>
                                    <Field label="CREATE ROLE" permission={permissionsList.CREATE_ROLE_ENTRY}/>
                                    <Field label="UPDATE ROLE" permission={permissionsList.UPDATE_ROLE_ENTRY}/>
                                    <Field label="DELETE ROLE" permission={permissionsList.DELETE_ROLE_ENTRY}/>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='forms-section'>
                                <h3>USER PERMISSIONS</h3>
                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px'}}>
                                {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gridRow: '1 / 6'}} >USER</div> */}
                                    <Field label="VIEW LIST" permission={permissionsList.ACCESS_USER_LIST}/>
                                    <Field label="VIEW INFO" permission={permissionsList.ACCESS_USER_INFO}/>
                                    <Field label="CREATE USER" permission={permissionsList.CREATE_USER_ENTRY}/>
                                    <Field label="UPDATE USER" permission={permissionsList.UPDATE_USER_ENTRY}/>
                                    <Field label="DELETE USER" permission={permissionsList.DELETE_USER_ENTRY}/>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='forms-section'>
                                <h3>PROPERTY PERMISSIONS</h3>
                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px' }}>
                                    {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gridRow: '1 / 6'}} >ROLE</div> */}
                                    <Field label="VIEW LIST" permission={permissionsList.ACCESS_PROPERTY_LIST}/>
                                    <Field label="VIEW INFO" permission={permissionsList.ACCESS_PROPERTY_INFO}/>
                                    <Field label="CREATE PROPERTY" permission={permissionsList.CREATE_PROPERTY_ENTRY}/>
                                    <Field label="UPDATE PROPERTY" permission={permissionsList.UPDATE_PROPERTY_ENTRY}/>
                                    {/* <Field label="DELETE PROPERTY" permission={permissionsList.DELETE_PROPERTY_ENTRY}/> */}
                                </div>

                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px', marginTop: '20px' }}>
                                    <Field label="VIEW ACTIVE PROPERTY LIST" permission={permissionsList.ACCESS_PROPERTY_ACTIVE_LIST}/>
                                    <Field label="VIEW ACTIVE PROPERTY INFO" permission={permissionsList.ACCESS_PROPERTY_ACTIVE_INFO}/>
                                    <Field label="UPDATE ACTIVE PROPERTY" permission={permissionsList.UPDATE_PROPERTY_ACTIVE_ENTRY}/>
                                </div>

                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px', marginTop: '20px' }}>
                                    <Field label="VIEW INACTIVE PROPERTY LIST" permission={permissionsList.ACCESS_PROPERTY_INACTIVE_LIST}/>
                                    <Field label="VIEW INACTIVE PROPERTY INFO" permission={permissionsList.ACCESS_PROPERTY_INACTIVE_INFO}/>
                                    <Field label="UPDATE INACTIVE PROPERTY" permission={permissionsList.UPDATE_PROPERTY_INACTIVE_ENTRY}/>
                                </div>
                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px', marginTop: '20px' }}>
                                    <Field label="VIEW UNMAPPED PROPERTY LIST" permission={permissionsList.ACCESS_PROPERTY_UNMAPPED_LIST}/>
                                    <Field label="VIEW UNMAPPED PROPERTY INFO" permission={permissionsList.ACCESS_PROPERTY_UNMAPPED_INFO}/>
                                    <Field label="UPDATE UNMAPPED PROPERTY" permission={permissionsList.UPDATE_PROPERTY_UNMAPPED_ENTRY}/>
                                </div>

                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px', marginTop: '20px' }}>
                                    <Field label="UPDATE PROPERTY STATUS" permission={permissionsList.UPDATE_PROPERTY_STATUS}/>
                                </div>

                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px', marginTop: '20px' }}>
                                    <Field label="VIEW INTEGRATION PARTNER LIST" permission={permissionsList.ACCESS_PROPERTY_INTEGRATION_PARTNER_LIST}/>
                                    <Field label="VIEW INTEGRATION PARTNER INFO" permission={permissionsList.ACCESS_PROPERTY_INTEGRATION_PARTNER_INFO}/>
                                    <Field label="CREATE INTEGRATION PARTNER" permission={permissionsList.CREATE_PROPERTY_INTEGRATION_PARTNER_ENTRY}/>
                                    <Field label="UPDATE INTEGRATION PARTNER" permission={permissionsList.UPDATE_PROPERTY_INTEGRATION_PARTNER_ENTRY}/>
                                    <Field label="DELETE INTEGRATION PARTNER" permission={permissionsList.DELETE_PROPERTY_INTEGRATION_PARTNER_ENTRY}/>
                                </div>


                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px', marginTop: '20px' }}>
                                    <Field label="VIEW ROOM AVAILABILITY" permission={permissionsList.ACCESS_PROPERTY_ROOM_AVAILABILITY}/>
                                    <Field label="CREATE ROOM AVAILABILITY" permission={permissionsList.CREATE_PROPERTY_ROOM_AVAILABILITY_ENTRY}/>
                                    <Field label="UPDATE ROOM AVAILABILITY" permission={permissionsList.UPDATE_PROPERTY_ROOM_AVAILABILITY_ENTRY}/>
                                    <Field label="DELETE ROOM AVAILABILITY" permission={permissionsList.DELETE_PROPERTY_ROOM_AVAILABILITY_ENTRY}/>
                                </div>

                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px', marginTop: '20px' }}>
                                    <Field label="VIEW USER MAPPING" permission={permissionsList.ACCESS_PROPERTY_USER_MAPPING}/>
                                    <Field label="UPDATE USER MAPPING" permission={permissionsList.UPDATE_PROPERTY_USER_MAPPING_ENTRY}/>
                                </div>

                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px', marginTop: '20px' }}>
                                    <Field label="VIEW ROOM TYPE DEFAULT BAR" permission={permissionsList.ACCESS_PROPERTY_ROOM_TYPE_DEFAULT_BAR}/>
                                    <Field label="UPDATE ROOM TYPE DEFAULT BAR" permission={permissionsList.UPDATE_PROPERTY_ROOM_TYPE_DEFAULT_BAR}/>
                                </div>
                                
                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px', marginTop: '20px' }}>
                                    <Field label="VIEW ROOM TYPE LIST" permission={permissionsList.ACCESS_PROPERTY_ROOM_TYPE_LIST}/>
                                    <Field label="VIEW ROOM TYPE INFO" permission={permissionsList.ACCESS_PROPERTY_ROOM_TYPE_INFO}/>
                                    <Field label="CREATE ROOM TYPE" permission={permissionsList.CREATE_PROPERTY_ROOM_TYPE_ENTRY}/>
                                    <Field label="UPDATE ROOM TYPE" permission={permissionsList.UPDATE_PROPERTY_ROOM_TYPE_ENTRY}/>
                                    <Field label="DELETE ROOM TYPE" permission={permissionsList.DELETE_PROPERTY_ROOM_TYPE_ENTRY}/>
                                </div>

                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px', marginTop: '20px' }}>
                                    <Field label="VIEW ROOM TYPE MAPPING LIST" permission={permissionsList.ACCESS_PROPERTY_ROOM_TYPE_MAPPING_LIST}/>
                                    <Field label="VIEW ROOM TYPE MAPPING INFO" permission={permissionsList.ACCESS_PROPERTY_ROOM_TYPE_MAPPING_INFO}/>
                                    <Field label="CREATE ROOM TYPE MAPPING" permission={permissionsList.CREATE_PROPERTY_ROOM_TYPE_MAPPING}/>
                                    <Field label="UPDATE ROOM TYPE MAPPING" permission={permissionsList.UPDATE_PROPERTY_ROOM_TYPE_MAPPING}/>
                                    <Field label="DELETE ROOM TYPE MAPPING" permission={permissionsList.DELETE_PROPERTY_ROOM_TYPE_MAPPING}/>
                                </div>

                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px', marginTop: '20px' }}>
                                    <Field label="VIEW SEGMENT LIST" permission={permissionsList.ACCESS_PROPERTY_SEGMENT_LIST}/>
                                    <Field label="VIEW SEGMENT INFO" permission={permissionsList.ACCESS_PROPERTY_SEGMENT_INFO}/>
                                    <Field label="CREATE SEGMENT" permission={permissionsList.CREATE_PROPERTY_SEGMENT_ENTRY}/>
                                    <Field label="UPDATE SEGMENT" permission={permissionsList.UPDATE_PROPERTY_SEGMENT_ENTRY}/>
                                    <Field label="DELETE SEGMENT" permission={permissionsList.DELETE_PROPERTY_SEGMENT_ENTRY}/>
                                </div>

                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px', marginTop: '20px' }}>
                                    <Field label="VIEW SEGMENT MAPPING LIST" permission={permissionsList.ACCESS_PROPERTY_SEGMENT_MAPPING_LIST}/>
                                    <Field label="VIEW SEGMENT MAPPING INFO" permission={permissionsList.ACCESS_PROPERTY_SEGMENT_MAPPING_INFO}/>
                                    <Field label="CREATE SEGMENT MAPPING" permission={permissionsList.CREATE_PROPERTY_SEGMENT_MAPPING}/>
                                    <Field label="UPDATE SEGMENT MAPPING" permission={permissionsList.UPDATE_PROPERTY_SEGMENT_MAPPING}/>
                                    <Field label="DELETE SEGMENT MAPPING" permission={permissionsList.DELETE_PROPERTY_SEGMENT_MAPPING}/>
                                </div>

                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px', marginTop: '20px' }}>
                                    <Field label="CREATE SEGMENT GROUP" permission={permissionsList.CREATE_PROPERTY_SEGMENT_GROUP_ENTRY}/>
                                </div>
                                

                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px', marginTop: '20px' }}>
                                    <Field label="VIEW COMPETITOR LIST" permission={permissionsList.ACCESS_PROPERTY_COMPETITOR_LIST}/>
                                    <Field label="VIEW COMPETITOR INFO" permission={permissionsList.ACCESS_PROPERTY_COMPETITOR_INFO}/>
                                    <Field label="CREATE COMPETITOR" permission={permissionsList.CREATE_PROPERTY_COMPETITOR_ENTRY}/>
                                    <Field label="UPDATE COMPETITOR" permission={permissionsList.UPDATE_PROPERTY_COMPETITOR_ENTRY}/>
                                    <Field label="DELETE COMPETITOR" permission={permissionsList.DELETE_PROPERTY_COMPETITOR_ENTRY}/>
                                </div>

                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px', marginTop: '20px' }}>
                                    <Field label="VIEW FORECAST SETTINGS - VARIABLE SETTINGS" permission={permissionsList.ACCESS_PROPERTY_FORECAST_SETTINGS_VARIABLE_SETTINGS}/>
                                    <Field label="UPDATE FORECAST SETTINGS - VARIABLE SETTINGS" permission={permissionsList.UPDATE_PROPERTY_FORECAST_SETTINGS_VARIABLE_SETTINGS}/>
                                    <Field label="VIEW FORECAST SETTINGS - FORECAST WEIGHTS" permission={permissionsList.ACCESS_PROPERTY_FORECAST_SETTINGS_WEIGHTS}/>
                                    <Field label="UPDATE FORECAST SETTINGS - FORECAST WEIGHTS" permission={permissionsList.UPDATE_PROPERTY_FORECAST_SETTINGS_WEIGHTS}/>
                                </div>

                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px', marginTop: '20px' }}>
                                    <Field label="VIEW CUSTOM DASHBOARD" permission={permissionsList.ACCESS_PROPERTY_CUSTOM_DASHBOARD}/>
                                    <Field label="UPDATE CUSTOM DASHBOARD" permission={permissionsList.UPDATE_PROPERTY_CUSTOM_DASHBOARD}/>
                                </div>

                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px', marginTop: '20px' }}>
                                    <Field label="VIEW NOTE LIST" permission={permissionsList.ACCESS_PROPERTY_NOTE_LIST}/>
                                    <Field label="VIEW NOTE INFO" permission={permissionsList.ACCESS_PROPERTY_NOTE_INFO}/>
                                    <Field label="CREATE NOTE" permission={permissionsList.CREATE_PROPERTY_NOTE_ENTRY}/>
                                    <Field label="UPDATE NOTE" permission={permissionsList.UPDATE_PROPERTY_NOTE_ENTRY}/>
                                    <Field label="DELETE NOTE" permission={permissionsList.DELETE_PROPERTY_NOTE_ENTRY}/>
                                </div>

                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px', marginTop: '20px' }}>
                                    <Field label="ACCESS RESERVATIONS FILE UPLOAD" permission={permissionsList.ACCESS_PROPERTY_UPLOAD_RESERVATIONS}/>
                                    <Field label="ACCESS RATES FILE UPLOAD" permission={permissionsList.ACCESS_PROPERTY_UPLOAD_RATES}/>
                                    <Field label="ACCESS SEGMENTS FILE UPLOAD" permission={permissionsList.ACCESS_PROPERTY_UPLOAD_SEGMENTS}/>
                                    <Field label="ACCESS EVENTS FILE UPLOAD" permission={permissionsList.ACCESS_PROPERTY_UPLOAD_EVENTS}/>
                                </div>

                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px', marginTop: '20px' }}>
                                    <Field label="EXPORT REPORTS" permission={permissionsList.ACCESS_PROPERTY_REPORT_EXPORT}/>
                                    <Field label="VIEW ALGORITHM MODAL" permission={permissionsList.ACCESS_PROPERTY_ALGORITHM_MODAL}/>
                                </div>

                            </div>
                        </div>
                        <div className='row'>
                            <div className='forms-section'>
                                <h3>PROPERTY GROUP PERMISSIONS</h3>
                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px' }}>
                                    <Field label="VIEW LIST" permission={permissionsList.ACCESS_PROPERTY_GROUP_LIST}/>
                                    <Field label="VIEW INFO" permission={permissionsList.ACCESS_PROPERTY_GROUP_INFO}/>
                                    <Field label="CREATE PROPERTY GROUP" permission={permissionsList.CREATE_PROPERTY_GROUP_ENTRY}/>
                                    <Field label="UPDATE PROPERTY GROUP" permission={permissionsList.UPDATE_PROPERTY_GROUP_ENTRY}/>
                                    {/* <Field label="DELETE PROPERTY GROUP" permission={permissionsList.DELETE_PROPERTY_GROUP_ENTRY}/> */}
                                </div>

                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px', marginTop: '20px' }}>
                                    <Field label="VIEW ACTIVE PROPERTY GROUP LIST" permission={permissionsList.ACCESS_PROPERTY_GROUP_ACTIVE_LIST}/>
                                    <Field label="VIEW ACTIVE PROPERTY GROUP INFO" permission={permissionsList.ACCESS_PROPERTY_GROUP_ACTIVE_INFO}/>
                                    <Field label="UPDATE ACTIVE PROPERTY GROUP" permission={permissionsList.UPDATE_PROPERTY_GROUP_ACTIVE_ENTRY}/>
                                </div>

                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px', marginTop: '20px' }}>
                                    <Field label="VIEW INACTIVE PROPERTY GROUP LIST" permission={permissionsList.ACCESS_PROPERTY_GROUP_INACTIVE_LIST}/>
                                    <Field label="VIEW INACTIVE PROPERTY GROUP INFO" permission={permissionsList.ACCESS_PROPERTY_GROUP_INACTIVE_INFO}/>
                                    <Field label="UPDATE INACTIVE PROPERTY" permission={permissionsList.UPDATE_PROPERTY_GROUP_INACTIVE_ENTRY}/>
                                </div>


                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px', marginTop: '20px' }}>
                                    <Field label="UPDATE PROPERTY GROUP STATUS" permission={permissionsList.UPDATE_PROPERTY_GROUP_STATUS}/>
                                </div>

                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px', marginTop: '20px' }}>
                                    <Field label="VIEW PROPERTY LIST" permission={permissionsList.ACCESS_PROPERTY_GROUP_PROPERTY_LIST}/>
                                    <Field label="UPDATE PROPERTY LIST" permission={permissionsList.UPDATE_PROPERTY_GROUP_PROPERTY_LIST}/>
                                </div>

                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px', marginTop: '20px' }}>
                                    <Field label="VIEW FORECAST SETTINGS - VARIABLE SETTINGS" permission={permissionsList.ACCESS_PROPERTY_GROUP_FORECAST_SETTINGS_VARIABLE_SETTINGS}/>
                                    <Field label="UPDATE FORECAST SETTINGS - VARIABLE SETTINGS" permission={permissionsList.UPDATE_PROPERTY_GROUP_FORECAST_SETTINGS_VARIABLE_SETTINGS}/>
                                    <Field label="VIEW FORECAST SETTINGS - FORECAST WEIGHTS" permission={permissionsList.ACCESS_PROPERTY_GROUP_FORECAST_SETTINGS_WEIGHTS}/>
                                    <Field label="UPDATE FORECAST SETTINGS - FORECAST WEIGHTS" permission={permissionsList.UPDATE_PROPERTY_GROUP_FORECAST_SETTINGS_WEIGHTS}/>
                                </div>

                            </div>
                        </div>
                        <div className='row'>
                            <div className='forms-section'>
                                <h3>ADMIN CONSOLE PERMISSIONS</h3>

                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px', marginTop: '20px' }}>
                                    <Field label="ACCESS SEGMENT LIST" permission={permissionsList.ACCESS_ADMIN_CONSOLE_SEGMENT_LIST}/>
                                    <Field label="ACCESS SEGMENT INFO" permission={permissionsList.ACCESS_ADMIN_CONSOLE_SEGMENT_INFO}/>
                                    <Field label="CREATE SEGMENT" permission={permissionsList.CREATE_ADMIN_CONSOLE_SEGMENT_ENTRY}/>
                                    <Field label="UPDATE SEGMENT" permission={permissionsList.UPDATE_ADMIN_CONSOLE_SEGMENT_ENTRY}/>
                                    <Field label="DELETE SEGMENT" permission={permissionsList.DELETE_ADMIN_CONSOLE_SEGMENT_ENTRY}/>
                                </div>

                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px', marginTop: '20px' }}>
                                    <Field label="ACCESS ROOM TYPE LIST" permission={permissionsList.ACCESS_ADMIN_CONSOLE_ROOM_TYPE_LIST}/>
                                    <Field label="ACCESS ROOM TYPE INFO" permission={permissionsList.ACCESS_ADMIN_CONSOLE_ROOM_TYPE_INFO}/>
                                    <Field label="CREATE ROOM TYPE" permission={permissionsList.CREATE_ADMIN_CONSOLE_ROOM_TYPE_ENTRY}/>
                                    <Field label="UPDATE ROOM TYPE" permission={permissionsList.UPDATE_ADMIN_CONSOLE_ROOM_TYPE_ENTRY}/>
                                    <Field label="DELETE ROOM TYPE" permission={permissionsList.DELETE_ADMIN_CONSOLE_ROOM_TYPE_ENTRY}/>
                                </div>

                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px', marginTop: '20px' }}>
                                    <Field label="ACCESS BOOKING SOURCE LIST" permission={permissionsList.ACCESS_ADMIN_CONSOLE_BOOKING_SOURCE_LIST}/>
                                    <Field label="ACCESS BOOKING SOURCE INFO" permission={permissionsList.ACCESS_ADMIN_CONSOLE_BOOKING_SOURCE_INFO}/>
                                    <Field label="CREATE BOOKING SOURCE" permission={permissionsList.CREATE_ADMIN_CONSOLE_BOOKING_SOURCE_ENTRY}/>
                                    <Field label="UPDATE BOOKING SOURCE" permission={permissionsList.UPDATE_ADMIN_CONSOLE_BOOKING_SOURCE_ENTRY}/>
                                    <Field label="DELETE BOOKING SOURCE" permission={permissionsList.DELETE_ADMIN_CONSOLE_BOOKING_SOURCE_ENTRY}/>
                                </div>

                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px', marginTop: '20px' }}>
                                    <Field label="ACCESS COMPETITOR LIST" permission={permissionsList.ACCESS_ADMIN_CONSOLE_COMPETITOR_LIST}/>
                                    <Field label="ACCESS COMPETITOR INFO" permission={permissionsList.ACCESS_ADMIN_CONSOLE_COMPETITOR_INFO}/>
                                    <Field label="CREATE COMPETITOR" permission={permissionsList.CREATE_ADMIN_CONSOLE_COMPETITOR_ENTRY}/>
                                    <Field label="UPDATE COMPETITOR" permission={permissionsList.UPDATE_ADMIN_CONSOLE_COMPETITOR_ENTRY}/>
                                    <Field label="DELETE COMPETITOR" permission={permissionsList.DELETE_ADMIN_CONSOLE_COMPETITOR_ENTRY}/>
                                </div>

                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px', marginTop: '20px' }}>
                                    <Field label="ACCESS INTEGRATION PARTNER LIST" permission={permissionsList.ACCESS_ADMIN_CONSOLE_INTEGRATION_PARTNER_LIST}/>
                                    <Field label="ACCESS INTEGRATION PARTNER INFO" permission={permissionsList.ACCESS_ADMIN_CONSOLE_INTEGRATION_PARTNER_INFO}/>
                                    <Field label="CREATE INTEGRATION PARTNER" permission={permissionsList.CREATE_ADMIN_CONSOLE_INTEGRATION_PARTNER_ENTRY}/>
                                    <Field label="UPDATE INTEGRATION PARTNER" permission={permissionsList.UPDATE_ADMIN_CONSOLE_INTEGRATION_PARTNER_ENTRY}/>
                                    <Field label="DELETE INTEGRATION PARTNER" permission={permissionsList.DELETE_ADMIN_CONSOLE_INTEGRATION_PARTNER_ENTRY}/>
                                </div>

                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '70px', marginTop: '20px' }}>
                                    <Field label="VIEW MESSAGE LOG LIST" permission={permissionsList.ACCESS_ADMIN_CONSOLE_MESSAGE_LOG_LIST}/>
                                    <Field label="VIEW MESSAGE LOG INFO" permission={permissionsList.ACCESS_ADMIN_CONSOLE_MESSAGE_LOG_INFO}/>
                                </div>
                            </div>

                            <div style={{ display: 'flex', width: '100%', justifyContent: 'end', gap: '20px', padding: '20px 0' }}>
                                <Button type="bg-dark" onClick={() => { 
                                    history.back();
                                    // navigate(`/admin#roles`);
                                }}>
                                    Cancel
                                </Button>
                                {canUpdate() && <Button type="primary" onClick={() => handleSave()}>
                                    Submit
                                </Button>}
                            </div>

                        </div>
                    </>
                </CustomTabs.Tab>
            </CustomTabs>
        </div>
        
    </div>

}

export default RolesForm;