import { useEffect, useState } from 'react'

/**
 * @param {Object} _fields Configurations of fields. This serves as the data store of the modal. Configs: default, validations.
 * @param {Function} submit The function to execute when handleSubmit is called.
 * @returns Set of useful hook functions
 */

const useModal = (_fields, submit) => {

    const [fields, setFields] = useState(Object.keys(_fields).reduce((obj, key) => {
        const field = _fields[key]?? {};
        return { ...obj, [key]: {
            ...field,
            isTouched: false,
            isEdited: false
        }}
    }, {}));
    const [rawValues, setRawValues] = useState({});
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [mode, setMode] = useState();

    const clearData = () => {
        setErrors({});
        initiateValues();
        setFields(prev => {
            Object.keys(prev).forEach(key => {
                prev[key].isTouched = false;
                prev[key].isEdited = false;
            })
            return prev;
        })
    }

    const resetData = () => {
        setValues(rawValues);
    }

    useEffect(() => {
        setValues(rawValues);
    }, [rawValues]);

    useEffect(() => {
        // console.log(fields);
    }, [fields]);

    const handleSubmit = (strict = true) => {
        if (typeof submit !== 'function') return;
        if (strict === true && Object.keys(validate()).length > 0) 
            return false;
        const { editedValues, touchedValues } = getModifiedValues(values);
        return submit({ mode, values, editedValues, touchedValues, errors });
    }

    const initiateValues = (_values) => {
        const newValues = {};
        Object.keys(fields).forEach(key => {
            const value = (_values?.[key]?? "");
            if (value === "" && (fields[key]?.default?? "") !== "") {
                newValues[key] = fields[key].default;
                return;
            }
            newValues[key] = value !== ""? value: "";
        });
        setRawValues(newValues);
    }

    const getModifiedValues = (values) => {
        const editedValues = {};
        const touchedValues = {};
        Object.keys(fields).forEach(key => {
            if (fields[key].isEdited === true) editedValues[key] = values[key];
            if (fields[key].isTouched === true) touchedValues[key] = values[key];
        });

        return { editedValues, touchedValues };
    }

    const handleValueChanged = (key, value) => {
        setValues(prev => ({ ...prev, [key]: value }));
        setFields(prev => {
            if (!prev[key].isTouched) prev[key].isTouched = true;
            prev[key].isEdited = rawValues[key] !== value;
            return prev;
        });

        const newErrors = { ...errors };
        delete newErrors[key];
        validateField(key, value).forEach(error => {
            newErrors[key] = error;
        });
        // console.log(newErrors);
        setErrors(newErrors);
    }

    const validateField = (key, value) => {
        const errors = [];
        const validations = fields[key]?.validations;
        if (validations?.required === true && (value?? "") === "") {
            errors.push('This field is required');
        }

        if (validations?.charLength > 0 && (value?? "").toString().length > validations?.charLength) {
            errors.push(`Exceeded max character length: ${validations?.charLength}`);
        }

        if (typeof validations?.custom === 'function') {
            const { editedValues, touchedValues } = getModifiedValues({ ...values, [key]: value });
            // Merge existing values with the new value. Because of state batches.
            const result = validations.custom({ rawValues, editedValues, touchedValues });
            if (result !== true) errors.push(result);
        }
        return errors;
    }

    const validate = () => {
        const errors = {};
        Object.keys({ ...rawValues, ...values }).filter(key => {
            validateField(key, values[key]).forEach(error => {
                errors[key] = error;
            });
        });
        setErrors(errors);
        return errors;
    }

    const show = ({ mode, data }) => {
        if (mode) {
            setMode(mode);
            if (mode === 'ADD') clearData();
        }

        if (data) setRawValues(data);

        setVisible(true);
    }

    const hide = () => {
        setVisible(false)
    }
    
    return {
        fields, setFields,
        errors, setErrors,
        values, setValues,
        visible, setVisible,
        isLoading, setLoading,
        mode, setMode,
        clearData,
        handleValueChanged,
        rawValues, setRawValues,
        validate,
        handleSubmit,
        initiateValues,
        resetData,
        show,
        hide
    }
}

export default useModal;