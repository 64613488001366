const permissionsList = {
    ACCESS_ROLE_INFO: 'ACCESS_ROLE_INFO',
    ACCESS_ROLE_LIST: 'ACCESS_ROLE_LIST',
    ACCESS_USER_INFO: 'ACCESS_USER_INFO',
    ACCESS_USER_LIST: 'ACCESS_USER_LIST',
    ACCESS_PROPERTY_INFO: 'ACCESS_PROPERTY_INFO',
    ACCESS_PROPERTY_LIST: 'ACCESS_PROPERTY_LIST',
    ACCESS_PROPERTY_SECTION_INFORMATION: 'ACCESS_PROPERTY_SECTION_INFORMATION',
    ACCESS_PROPERTY_SECTION_ADMIN_CONTROLS: 'ACCESS_PROPERTY_SECTION_ADMIN_CONTROLS',
    ACCESS_PROPERTY_SECTION_FORECAST_SETTINGS: 'ACCESS_PROPERTY_SECTION_FORECAST_SETTINGS',
    ACCESS_PROPERTY_SECTION_SEGMENTS: 'ACCESS_PROPERTY_SECTION_SEGMENTS',
    ACCESS_PROPERTY_SECTION_ROOM_TYPES: 'ACCESS_PROPERTY_SECTION_ROOM_TYPES',
    ACCESS_PROPERTY_SECTION_COMPETITORS: 'ACCESS_PROPERTY_SECTION_COMPETITORS',
    ACCESS_PROPERTY_SECTION_CUSTOM_DASHBOARD: 'ACCESS_PROPERTY_SECTION_CUSTOM_DASHBOARD',
    ACCESS_PROPERTY_DETAILS: 'ACCESS_PROPERTY_DETAILS',
    ACCESS_PROPERTY_ACTIVE_INFO: 'ACCESS_PROPERTY_ACTIVE_INFO',
    ACCESS_PROPERTY_ACTIVE_LIST: 'ACCESS_PROPERTY_ACTIVE_LIST',
    ACCESS_PROPERTY_INACTIVE_INFO: 'ACCESS_PROPERTY_INACTIVE_INFO',
    ACCESS_PROPERTY_INACTIVE_LIST: 'ACCESS_PROPERTY_INACTIVE_LIST',
    ACCESS_PROPERTY_UNMAPPED_INFO: 'ACCESS_PROPERTY_UNMAPPED_INFO',
    ACCESS_PROPERTY_UNMAPPED_LIST: 'ACCESS_PROPERTY_UNMAPPED_LIST',
    ACCESS_PROPERTY_ROOM_AVAILABILITY: 'ACCESS_PROPERTY_ROOM_AVAILABILITY',
    ACCESS_PROPERTY_USER_MAPPING: 'ACCESS_PROPERTY_USER_MAPPING',
    ACCESS_PROPERTY_INTEGRATION_PARTNER_INFO: 'ACCESS_PROPERTY_INTEGRATION_PARTNER_INFO',
    ACCESS_PROPERTY_INTEGRATION_PARTNER_LIST: 'ACCESS_PROPERTY_INTEGRATION_PARTNER_LIST',
    ACCESS_PROPERTY_COMPETITOR_INFO: 'ACCESS_PROPERTY_COMPETITOR_INFO',
    ACCESS_PROPERTY_COMPETITOR_LIST: 'ACCESS_PROPERTY_COMPETITOR_LIST',
    ACCESS_PROPERTY_FORECAST_SETTINGS_VARIABLE_SETTINGS: 'ACCESS_PROPERTY_FORECAST_SETTINGS_VARIABLE_SETTINGS',
    ACCESS_PROPERTY_FORECAST_SETTINGS_WEIGHTS: 'ACCESS_PROPERTY_FORECAST_SETTINGS_WEIGHTS',
    ACCESS_PROPERTY_SEGMENT_INFO: 'ACCESS_PROPERTY_SEGMENT_INFO',
    ACCESS_PROPERTY_SEGMENT_LIST: 'ACCESS_PROPERTY_SEGMENT_LIST',
    ACCESS_PROPERTY_SEGMENT_MAPPING_INFO: 'ACCESS_PROPERTY_SEGMENT_MAPPING_INFO',
    ACCESS_PROPERTY_SEGMENT_MAPPING_LIST: 'ACCESS_PROPERTY_SEGMENT_MAPPING_LIST',
    ACCESS_PROPERTY_ROOM_TYPE_DEFAULT_BAR: 'ACCESS_PROPERTY_ROOM_TYPE_DEFAULT_BAR',
    ACCESS_PROPERTY_ROOM_TYPE_INFO: 'ACCESS_PROPERTY_ROOM_TYPE_INFO',
    ACCESS_PROPERTY_ROOM_TYPE_LIST: 'ACCESS_PROPERTY_ROOM_TYPE_LIST',
    ACCESS_PROPERTY_ROOM_TYPE_MAPPING_INFO: 'ACCESS_PROPERTY_ROOM_TYPE_MAPPING_INFO',
    ACCESS_PROPERTY_ROOM_TYPE_MAPPING_LIST: 'ACCESS_PROPERTY_ROOM_TYPE_MAPPING_LIST',
    ACCESS_PROPERTY_CUSTOM_DASHBOARD: 'ACCESS_PROPERTY_CUSTOM_DASHBOARD',
    ACCESS_PROPERTY_NOTE_INFO: 'ACCESS_PROPERTY_NOTE_INFO',
    ACCESS_PROPERTY_NOTE_LIST: 'ACCESS_PROPERTY_NOTE_LIST',
    ACCESS_PROPERTY_UPLOAD_RESERVATIONS: 'ACCESS_PROPERTY_UPLOAD_RESERVATIONS',
    ACCESS_PROPERTY_UPLOAD_RATES: 'ACCESS_PROPERTY_UPLOAD_RATES',
    ACCESS_PROPERTY_UPLOAD_SEGMENTS: 'ACCESS_PROPERTY_UPLOAD_SEGMENTS',
    ACCESS_PROPERTY_UPLOAD_EVENTS: 'ACCESS_PROPERTY_UPLOAD_EVENTS',
    ACCESS_PROPERTY_REPORT_EXPORT: 'ACCESS_PROPERTY_REPORT_EXPORT',
    ACCESS_PROPERTY_ALGORITHM_MODAL: 'ACCESS_PROPERTY_ALGORITHM_MODAL',
    ACCESS_PROPERTY_GROUP_INFO: 'ACCESS_PROPERTY_GROUP_INFO',
    ACCESS_PROPERTY_GROUP_LIST: 'ACCESS_PROPERTY_GROUP_LIST',
    ACCESS_PROPERTY_GROUP_ACTIVE_INFO: 'ACCESS_PROPERTY_GROUP_ACTIVE_INFO',
    ACCESS_PROPERTY_GROUP_ACTIVE_LIST: 'ACCESS_PROPERTY_GROUP_ACTIVE_LIST',
    ACCESS_PROPERTY_GROUP_INACTIVE_INFO: 'ACCESS_PROPERTY_GROUP_INACTIVE_INFO',
    ACCESS_PROPERTY_GROUP_INACTIVE_LIST: 'ACCESS_PROPERTY_GROUP_INACTIVE_LIST',
    ACCESS_PROPERTY_GROUP_PROPERTY_LIST: 'ACCESS_PROPERTY_GROUP_PROPERTY_LIST',
    ACCESS_PROPERTY_GROUP_FORECAST_SETTINGS_VARIABLE_SETTINGS: 'ACCESS_PROPERTY_GROUP_FORECAST_SETTINGS_VARIABLE_SETTINGS',
    ACCESS_PROPERTY_GROUP_FORECAST_SETTINGS_WEIGHTS: 'ACCESS_PROPERTY_GROUP_FORECAST_SETTINGS_WEIGHTS',
    ACCESS_ADMIN_CONSOLE_SECTION_PREDEFINED_LISTS: 'ACCESS_ADMIN_CONSOLE_SECTION_PREDEFINED_LISTS',
    ACCESS_ADMIN_CONSOLE_SECTION_MESSAGE_LOGS: 'ACCESS_ADMIN_CONSOLE_SECTION_MESSAGE_LOGS',
    ACCESS_ADMIN_CONSOLE_SECTION_ROLES_AND_USERS: 'ACCESS_ADMIN_CONSOLE_SECTION_ROLES_AND_USERS',
    ACCESS_ADMIN_CONSOLE_SEGMENT_INFO: 'ACCESS_ADMIN_CONSOLE_SEGMENT_INFO',
    ACCESS_ADMIN_CONSOLE_SEGMENT_LIST: 'ACCESS_ADMIN_CONSOLE_SEGMENT_LIST',
    ACCESS_ADMIN_CONSOLE_INTEGRATION_PARTNER_INFO: 'ACCESS_ADMIN_CONSOLE_INTEGRATION_PARTNER_INFO',
    ACCESS_ADMIN_CONSOLE_INTEGRATION_PARTNER_LIST: 'ACCESS_ADMIN_CONSOLE_INTEGRATION_PARTNER_LIST',
    ACCESS_ADMIN_CONSOLE_ROOM_TYPE_INFO: 'ACCESS_ADMIN_CONSOLE_ROOM_TYPE_INFO',
    ACCESS_ADMIN_CONSOLE_ROOM_TYPE_LIST: 'ACCESS_ADMIN_CONSOLE_ROOM_TYPE_LIST',
    ACCESS_ADMIN_CONSOLE_BOOKING_SOURCE_INFO: 'ACCESS_ADMIN_CONSOLE_BOOKING_SOURCE_INFO',
    ACCESS_ADMIN_CONSOLE_BOOKING_SOURCE_LIST: 'ACCESS_ADMIN_CONSOLE_BOOKING_SOURCE_LIST',
    ACCESS_ADMIN_CONSOLE_COMPETITOR_INFO: 'ACCESS_ADMIN_CONSOLE_COMPETITOR_INFO',
    ACCESS_ADMIN_CONSOLE_COMPETITOR_LIST: 'ACCESS_ADMIN_CONSOLE_COMPETITOR_LIST',
    ACCESS_ADMIN_CONSOLE_MESSAGE_LOG_INFO: 'ACCESS_ADMIN_CONSOLE_MESSAGE_LOG_INFO',
    ACCESS_ADMIN_CONSOLE_MESSAGE_LOG_LIST: 'ACCESS_ADMIN_CONSOLE_MESSAGE_LOG_LIST',
    CREATE_ROLE_ENTRY: 'CREATE_ROLE_ENTRY',
    CREATE_USER_ENTRY: 'CREATE_USER_ENTRY',
    CREATE_PROPERTY_ENTRY: 'CREATE_PROPERTY_ENTRY',
    CREATE_PROPERTY_ROOM_AVAILABILITY_ENTRY: 'CREATE_PROPERTY_ROOM_AVAILABILITY_ENTRY',
    CREATE_PROPERTY_INTEGRATION_PARTNER_ENTRY: 'CREATE_PROPERTY_INTEGRATION_PARTNER_ENTRY',
    CREATE_PROPERTY_COMPETITOR_ENTRY: 'CREATE_PROPERTY_COMPETITOR_ENTRY',
    CREATE_PROPERTY_SEGMENT_ENTRY: 'CREATE_PROPERTY_SEGMENT_ENTRY',
    CREATE_PROPERTY_SEGMENT_MAPPING: 'CREATE_PROPERTY_SEGMENT_MAPPING',
    CREATE_PROPERTY_SEGMENT_GROUP_ENTRY: 'CREATE_PROPERTY_SEGMENT_GROUP_ENTRY',
    CREATE_PROPERTY_ROOM_TYPE_ENTRY: 'CREATE_PROPERTY_ROOM_TYPE_ENTRY',
    CREATE_PROPERTY_ROOM_TYPE_MAPPING: 'CREATE_PROPERTY_ROOM_TYPE_MAPPING',
    CREATE_PROPERTY_NOTE_ENTRY: 'CREATE_PROPERTY_NOTE_ENTRY',
    CREATE_PROPERTY_GROUP_ENTRY: 'CREATE_PROPERTY_GROUP_ENTRY',
    CREATE_ADMIN_CONSOLE_SEGMENT_ENTRY: 'CREATE_ADMIN_CONSOLE_SEGMENT_ENTRY',
    CREATE_ADMIN_CONSOLE_INTEGRATION_PARTNER_ENTRY: 'CREATE_ADMIN_CONSOLE_INTEGRATION_PARTNER_ENTRY',
    CREATE_ADMIN_CONSOLE_ROOM_TYPE_ENTRY: 'CREATE_ADMIN_CONSOLE_ROOM_TYPE_ENTRY',
    CREATE_ADMIN_CONSOLE_BOOKING_SOURCE_ENTRY: 'CREATE_ADMIN_CONSOLE_BOOKING_SOURCE_ENTRY',
    CREATE_ADMIN_CONSOLE_COMPETITOR_ENTRY: 'CREATE_ADMIN_CONSOLE_COMPETITOR_ENTRY',
    UPDATE_ROLE_ENTRY: 'UPDATE_ROLE_ENTRY',
    UPDATE_USER_ENTRY: 'UPDATE_USER_ENTRY',
    UPDATE_PROPERTY_ENTRY: 'UPDATE_PROPERTY_ENTRY',
    UPDATE_PROPERTY_SECTION_INFORMATION: 'UPDATE_PROPERTY_SECTION_INFORMATION',
    UPDATE_PROPERTY_SECTION_ADMIN_CONTROLS: 'UPDATE_PROPERTY_SECTION_ADMIN_CONTROLS',
    UPDATE_PROPERTY_SECTION_FORECAST_SETTINGS: 'UPDATE_PROPERTY_SECTION_FORECAST_SETTINGS',
    UPDATE_PROPERTY_SECTION_SEGMENTS: 'UPDATE_PROPERTY_SECTION_SEGMENTS',
    UPDATE_PROPERTY_SECTION_ROOM_TYPES: 'UPDATE_PROPERTY_SECTION_ROOM_TYPES',
    UPDATE_PROPERTY_SECTION_COMPETITORS: 'UPDATE_PROPERTY_SECTION_COMPETITORS',
    UPDATE_PROPERTY_SECTION_CUSTOM_DASHBOARD: 'UPDATE_PROPERTY_SECTION_CUSTOM_DASHBOARD',
    UPDATE_PROPERTY_DETAILS: 'UPDATE_PROPERTY_DETAILS',
    UPDATE_PROPERTY_STATUS: 'UPDATE_PROPERTY_STATUS',
    UPDATE_PROPERTY_ACTIVE_ENTRY: 'UPDATE_PROPERTY_ACTIVE_ENTRY',
    UPDATE_PROPERTY_INACTIVE_ENTRY: 'UPDATE_PROPERTY_INACTIVE_ENTRY',
    UPDATE_PROPERTY_UNMAPPED_ENTRY: 'UPDATE_PROPERTY_UNMAPPED_ENTRY',
    UPDATE_PROPERTY_USER_MAPPING_ENTRY: 'UPDATE_PROPERTY_USER_MAPPING_ENTRY',
    UPDATE_PROPERTY_ROOM_AVAILABILITY_ENTRY: 'UPDATE_PROPERTY_ROOM_AVAILABILITY_ENTRY',
    UPDATE_PROPERTY_INTEGRATION_PARTNER_ENTRY: 'UPDATE_PROPERTY_INTEGRATION_PARTNER_ENTRY',
    UPDATE_PROPERTY_COMPETITOR_ENTRY: 'UPDATE_PROPERTY_COMPETITOR_ENTRY',
    UPDATE_PROPERTY_FORECAST_SETTINGS_VARIABLE_SETTINGS: 'UPDATE_PROPERTY_FORECAST_SETTINGS_VARIABLE_SETTINGS',
    UPDATE_PROPERTY_FORECAST_SETTINGS_WEIGHTS: 'UPDATE_PROPERTY_FORECAST_SETTINGS_WEIGHTS',
    UPDATE_PROPERTY_SEGMENT_ENTRY: 'UPDATE_PROPERTY_SEGMENT_ENTRY',
    UPDATE_PROPERTY_SEGMENT_MAPPING: 'UPDATE_PROPERTY_SEGMENT_MAPPING',
    UPDATE_PROPERTY_SEGMENT_GROUP_ENTRY: 'UPDATE_PROPERTY_SEGMENT_GROUP_ENTRY',
    UPDATE_PROPERTY_ROOM_TYPE_DEFAULT_BAR: 'UPDATE_PROPERTY_ROOM_TYPE_DEFAULT_BAR',
    UPDATE_PROPERTY_ROOM_TYPE_ENTRY: 'UPDATE_PROPERTY_ROOM_TYPE_ENTRY',
    UPDATE_PROPERTY_ROOM_TYPE_MAPPING: 'UPDATE_PROPERTY_ROOM_TYPE_MAPPING',
    UPDATE_PROPERTY_CUSTOM_DASHBOARD: 'UPDATE_PROPERTY_CUSTOM_DASHBOARD',
    UPDATE_PROPERTY_NOTE_ENTRY: 'UPDATE_PROPERTY_NOTE_ENTRY',
    UPDATE_PROPERTY_GROUP_ENTRY: 'UPDATE_PROPERTY_GROUP_ENTRY',
    UPDATE_PROPERTY_GROUP_STATUS: 'UPDATE_PROPERTY_GROUP_STATUS',
    UPDATE_PROPERTY_GROUP_ACTIVE_ENTRY: 'UPDATE_PROPERTY_GROUP_ACTIVE_ENTRY',
    UPDATE_PROPERTY_GROUP_INACTIVE_ENTRY: 'UPDATE_PROPERTY_GROUP_INACTIVE_ENTRY',
    UPDATE_PROPERTY_GROUP_PROPERTY_LIST: 'UPDATE_PROPERTY_GROUP_PROPERTY_LIST',
    UPDATE_PROPERTY_GROUP_FORECAST_SETTINGS_VARIABLE_SETTINGS: 'UPDATE_PROPERTY_GROUP_FORECAST_SETTINGS_VARIABLE_SETTINGS',
    UPDATE_PROPERTY_GROUP_FORECAST_SETTINGS_WEIGHTS: 'UPDATE_PROPERTY_GROUP_FORECAST_SETTINGS_WEIGHTS',
    UPDATE_ADMIN_CONSOLE_SECTION_PREDEFINED_LISTS: 'UPDATE_ADMIN_CONSOLE_SECTION_PREDEFINED_LISTS',
    UPDATE_ADMIN_CONSOLE_SECTION_MESSAGE_LOGS: 'UPDATE_ADMIN_CONSOLE_SECTION_MESSAGE_LOGS',
    UPDATE_ADMIN_CONSOLE_SECTION_ROLES_AND_USERS: 'UPDATE_ADMIN_CONSOLE_SECTION_ROLES_AND_USERS',
    UPDATE_ADMIN_CONSOLE_SEGMENT_ENTRY: 'UPDATE_ADMIN_CONSOLE_SEGMENT_ENTRY',
    UPDATE_ADMIN_CONSOLE_INTEGRATION_PARTNER_ENTRY: 'UPDATE_ADMIN_CONSOLE_INTEGRATION_PARTNER_ENTRY',
    UPDATE_ADMIN_CONSOLE_ROOM_TYPE_ENTRY: 'UPDATE_ADMIN_CONSOLE_ROOM_TYPE_ENTRY',
    UPDATE_ADMIN_CONSOLE_BOOKING_SOURCE_ENTRY: 'UPDATE_ADMIN_CONSOLE_BOOKING_SOURCE_ENTRY',
    UPDATE_ADMIN_CONSOLE_COMPETITOR_ENTRY: 'UPDATE_ADMIN_CONSOLE_COMPETITOR_ENTRY',
    DELETE_ROLE_ENTRY: 'DELETE_ROLE_ENTRY',
    DELETE_USER_ENTRY: 'DELETE_USER_ENTRY',
    DELETE_PROPERTY_ENTRY: 'DELETE_PROPERTY_ENTRY',
    DELETE_PROPERTY_ACTIVE_ENTRY: 'DELETE_PROPERTY_ACTIVE_ENTRY',
    DELETE_PROPERTY_INACTIVE_ENTRY: 'DELETE_PROPERTY_INACTIVE_ENTRY',
    DELETE_PROPERTY_UNMAPPED_ENTRY: 'DELETE_PROPERTY_UNMAPPED_ENTRY',
    DELETE_PROPERTY_ROOM_AVAILABILITY_ENTRY: 'DELETE_PROPERTY_ROOM_AVAILABILITY_ENTRY',
    DELETE_PROPERTY_INTEGRATION_PARTNER_ENTRY: 'DELETE_PROPERTY_INTEGRATION_PARTNER_ENTRY',
    DELETE_PROPERTY_COMPETITOR_ENTRY: 'DELETE_PROPERTY_COMPETITOR_ENTRY',
    DELETE_PROPERTY_SEGMENT_ENTRY: 'DELETE_PROPERTY_SEGMENT_ENTRY',
    DELETE_PROPERTY_SEGMENT_MAPPING: 'DELETE_PROPERTY_SEGMENT_MAPPING',
    DELETE_PROPERTY_SEGMENT_GROUP_ENTRY: 'DELETE_PROPERTY_SEGMENT_GROUP_ENTRY',
    DELETE_PROPERTY_ROOM_TYPE_ENTRY: 'DELETE_PROPERTY_ROOM_TYPE_ENTRY',
    DELETE_PROPERTY_ROOM_TYPE_MAPPING: 'DELETE_PROPERTY_ROOM_TYPE_MAPPING',
    DELETE_PROPERTY_NOTE_ENTRY: 'DELETE_PROPERTY_NOTE_ENTRY',
    DELETE_PROPERTY_GROUP_ENTRY: 'DELETE_PROPERTY_GROUP_ENTRY',
    DELETE_ADMIN_CONSOLE_SEGMENT_ENTRY: 'DELETE_ADMIN_CONSOLE_SEGMENT_ENTRY',
    DELETE_ADMIN_CONSOLE_INTEGRATION_PARTNER_ENTRY: 'DELETE_ADMIN_CONSOLE_INTEGRATION_PARTNER_ENTRY',
    DELETE_ADMIN_CONSOLE_ROOM_TYPE_ENTRY: 'DELETE_ADMIN_CONSOLE_ROOM_TYPE_ENTRY',
    DELETE_ADMIN_CONSOLE_BOOKING_SOURCE_ENTRY: 'DELETE_ADMIN_CONSOLE_BOOKING_SOURCE_ENTRY',
    DELETE_ADMIN_CONSOLE_COMPETITOR_ENTRY: 'DELETE_ADMIN_CONSOLE_COMPETITOR_ENTRY'
};

export const getPermissions = (authUser) => {
    const allowedPermissions = authUser?.role_info?.permissions?? [];
    const permissions = { ...permissionsList };

    Object.keys(permissionsList).forEach(key => {
        permissions[key] = allowedPermissions.includes(key) === true;
    });

    const getPermissionKey = (action, ...resource) => {
        const key = [action, ...resource ].filter(_ => !!_).join('_').toUpperCase();
        return key;
    }

    const getSubPermissions = (permissionKey) => {
        // Get all permissions with same pattern in key.
        // Then check if all of them are enabled or allowed.
        const subPermissions = Object.keys(permissions).filter(key => {
            return key.match(new RegExp(`^${permissionKey}`)) !== null;
        });
        return subPermissions.reduce((obj, key) => {
            return {...obj, [key]: permissions[key]}
        }, {});
    }

    const isAllowed = (key, all = true) => {
        if (permissions[key] === true) return true;

        const subPermissions = getSubPermissions(key);
        const subPermissionsKeys = Object.keys(subPermissions);
        // Return TRUE if all sub permissions are enabled.
        if (all && subPermissionsKeys.length > 0 && 
            subPermissionsKeys.filter(key => subPermissions[key] === true).length >= subPermissionsKeys.length) {
            return true;
        }
        if (!all && subPermissionsKeys.length > 0 && 
            subPermissionsKeys.filter(key => subPermissions[key] === true).length >= 1) {
            return true;
        }
        // Return false even if there is only one disabled.
        return false;
    }

    const canAccess = (...resource) => {
        const key = getPermissionKey('ACCESS', ...resource);
        return isAllowed(key);
    }

    const canView = (...resource) => {
        const key = getPermissionKey('ACCESS', ...resource);
        console.log(resource);
        return isAllowed(key);
    }

    const canCreate = (...resource) => {
        const key = getPermissionKey('CREATE', ...resource);
        return isAllowed(key);
    };

    const canUpdate = (...resource) => {
        const key = getPermissionKey('UPDATE', ...resource);
        return isAllowed(key);
    }

    const canDelete = (...resource) => {
        const key = getPermissionKey('DELETE', ...resource);
        return isAllowed(key);
    }

    return {
        list: permissions,
        constants: permissionsList,
        getPermissionKey,
        isAllowed,
        canAccess,
        canView,
        canCreate,
        canUpdate,
        canDelete
    }
}

export default { ...permissionsList };