import React from "react";
// import moment from "moment";
import dayjs from "dayjs";
import { DatePicker, Radio, Select, Input, Rate, Menu, Dropdown, Button, TimePicker } from "antd";
import OutlinedIcon from "elements/Icon/OutlinedIcon";

import DefaultPickupDropdown from './DefaultPickupDropdown';
import Switch from "../Switch/Switch";

const { TextArea } = Input;



const Field = ({
    type,
    field,
    onChange,
    fieldKey,
    value,
    label,
    inputClassName = "",
    options = [],
    errors,
    onBlur,
    withErrorMessage = true,
    dropDownList = [],
    add = false,
    placeholder = "",
    disabled = false
}) => {

    const menu = (fieldKey) => {
        const getItem = (item, fieldKey) => {
            switch (fieldKey) {
                case "currency":
                    return item.currency;
                case "brand":
                    return item.brand_name;
                case "timezone":
                case "country":
                    return item.name;
                case "province":
                    return item.province;
                case "city":
                return item.name;
                case "chart_days_out":
                case "default_pickup":
                case "default_pace":
                case "default_view":
                case "parser":
                    return item.name
                default:
                    return "";
            }
        };

        const items = dropDownList.map((item, index) => {
            return {
                key: item._id?? `item_${index}`,
                label: getItem(item, fieldKey)
            }
        });

        if (add === true) items.push({
            key: 'Add',
            label: <div className='dropdown-add-btn'>Add</div>
        })

        return {
            items: items,
            onClick: (item) => {
                onChange(fieldKey, item.key);
            }
        }

    };

    switch (type) {
        case "datePicker":
            return (
                <div className={`forms-field ${inputClassName} ${disabled && 'disabled'}`} key={fieldKey}>
                    <p className="field__label">{label}</p>
                    <DatePicker
                        defaultValue={dayjs(value)}
                        onChange={onChange}
                        value={dayjs(value)}
                        disabled={disabled}
                    />
                </div>
            );
        case "number":
            return (
                <div className={`forms-field ${inputClassName} ${disabled && 'disabled'}`} key={fieldKey}>
                    <p className="field__label">
                        {label ? (
                            <span className={errors ? "error" : ""}>{label}</span>
                        ) : (
                                ""
                            )}
                    </p>
                    <input
                        type="number"
                        value={value}
                        onChange={(e) => {
                            const val = e.target.value;
                            onChange(fieldKey, val? parseInt(val): null);
                        }}
                        onBlur={(e) => {
                            if (typeof onBlur === "function") onBlur(fieldKey);
                        }}
                        className={`field__input ${errors ? "error" : ""}`}
                        disabled={disabled}
                    />
                </div>
            );
        case "select":
            const { Option } = Select;
            return (
                <div className={`forms-field ${inputClassName} ${disabled && 'disabled'}`} key={fieldKey}>
                    <p className="field__label">{label}</p>
                    <Select
                        defaultValue={value ? value : ""}
                        value={value}
                        onChange={onChange}
                        style={{ width: "70%" }}
                    >
                        {options.length &&
                            options.map((item) => {
                                return (
                                    <Option key={item} value={item}>
                                        {item}
                                    </Option>
                                );
                            })}
                    </Select>
                </div>
            );
        case "textarea":
            return (
                <div className={`forms-field ${inputClassName} ${disabled && 'disabled'}`} key={fieldKey}>
                    <p className="field__label">{label}</p>
                    <TextArea
                        style={{ resize: "none" }}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        placeholder={placeholder}
                        autosize={{ minRows: 3, maxRows: 5 }}
                        disabled={disabled}
                    />
                </div>
            );
        case "radio":
            const radioStyle = {
                display: "block",
                height: "30px",
                lineHeight: "30px",
                color: "#FFF",
            };

            return (
                <div className={`${inputClassName} ${disabled && 'disabled'}`} key={fieldKey}>
                    <p className="field__label">{label}</p>
                    <Radio.Group
                        defaultValue={value ? value : ""}
                        value={value}
                        onChange={(e) => onChange(fieldKey, e.target.value)}
                        disabled={disabled}
                    >
                        {options.length &&
                            options.map((item) => {
                                return (
                                    <Radio style={radioStyle} key={item.value} value={item.value}>
                                        {item.label}
                                    </Radio>
                                );
                            })}
                    </Radio.Group>
                </div>
            );
        case "switch":
            value = (value === true ? true : false);
            return (
                <div className={`forms-field ${inputClassName} ${disabled && 'disabled'}`} key={fieldKey}>
                    <p className="field__label">
                        <span>{label}</span>
                    </p>
                    <div style={{ display: "inline-flex", marginTop: "auto", marginBottom: "auto" }}>
                        <span style={{ marginRight: 8, fontSize: 14 }}>{value === true ? "Active" : "Inactive"}</span>
                        <Switch checked={value} disabled={disabled} onChange={() => onChange(fieldKey, !value)}></Switch>
                    </div>
                </div>
            );
        case "rating":
            return (
                <div className={`forms-field ${inputClassName} ${disabled && 'disabled'}`} key={fieldKey}>
                    {label && (
                        <p className="field__label">
                            <span className={errors ? "error" : ""}>{label}</span>
                        </p>
                    )}
                    <div className="field__rating">
                        <Rate
                            style={{ marginRight: 10, color: "#f28f3c" }}
                            onChange={(e) => onChange(fieldKey, e)}
                            value={value}
                            disabled={disabled}
                        />{" "}
                        <span>Star</span>
                        <br />
                        {errors && withErrorMessage
                            ? (<span className="field-error">{errors}</span>)
                            : ("")}

                    </div>
                </div>
            );
        case "password":
            return (
                <div className={`forms-field ${inputClassName} ${disabled && 'disabled'}`} key={fieldKey}>
                    <p className="field__label">
                        {label ? (
                            <span className={errors ? "error" : ""}>{label}</span>
                        ) : (
                                ""
                            )}
                        {errors && withErrorMessage ? (
                            <span className="field-error">{errors}</span>
                        ) : (
                                ""
                            )}
                    </p>
                    <input
                        type="password"
                        value={value}
                        name={fieldKey}
                        onChange={(e) => {
                            onChange(fieldKey, e.target.value, field);
                        }}
                        onBlur={(e) => {
                            if (typeof onBlur === "function") onBlur(fieldKey);
                        }}
                        className={`field__input ${errors ? "error" : ""}`}
                        disabled={disabled}
                    />
                </div>
            );
        case "dropdown":
            const getValue = (fieldKey) => {
                switch (fieldKey) {
                    case "currency":
                        if (value.code !== undefined) {
                            return dropDownList.filter((item) => item.currency_code === value.code)[0]?.currency;
                        } else {
                            return dropDownList.filter((item) => item._id === value)[0]?.currency;
                        }
                    case "brand":
                        return value !== "Hotel Brand" && value !== "Add"
                            ? dropDownList.filter((item) => item._id === value)[0]
                                ?.brand_name
                            : value
                    case "country":
                        let countryMatchById = dropDownList.filter((item) => item._id === value)[0];
                        let countryMatchByCode = dropDownList.filter((item) => item.country_code_2 === value)[0];
                        return countryMatchById === undefined
                            ? countryMatchByCode === undefined
                                ? value
                                : countryMatchByCode?.name
                            : countryMatchById?.name;
                    case "timezone":
                        let timezoneMatch = dropDownList.filter((item) => item._id === value)[0]
                        return timezoneMatch === undefined ? value : timezoneMatch?.name
                    case "province":
                        return dropDownList.filter((item) => item._id === value)[0]?.province;
                    case "city":
                        return ((value !== undefined) && value.includes("item"))
                            ? dropDownList[Number(value.replace(/\D/g, ''))]?.name
                            : value
                    case "chart_days_out":
                    case "default_pickup":
                        return dropDownList.find(item => item._id == value)?.name
                    case "default_pace":
                    case "default_view":
                    case "integration_partner":
                    case "parser":
                        return dropDownList.find(item => item._id == value)?.name
                    default:
                        return "";
                }
            }

            return (
                <div className={`forms-field ${inputClassName} ${disabled && 'disabled'}`} key={fieldKey}>
                    <p className="field__label">
                        {label ? (
                            <span className={errors ? "error" : ""}>{label}</span>
                        ) : (
                                ""
                            )}
                    </p>
                    <Dropdown
                        menu={menu(fieldKey)}
                        trigger={["click"]}
                        overlayClassName="rms-dropdown"
                        disabled={disabled}
                    >
                        <Button style={errors ? { border: '1px solid #ed474b' } : null}>
                            {getValue(fieldKey)}{" "}
                            <OutlinedIcon type='down' />
                        </Button>
                    </Dropdown>
                </div>
            );
        case "custom_default_pickup": 

            return (
                <DefaultPickupDropdown
                    fieldKey={fieldKey}
                    onChange={(e) => { onChange(fieldKey, e.key)}}
                    value={value}
                    label={label}
                    inputClassName={inputClassName}
                    errors={errors}
                    dropDownList={dropDownList}
                    disabled={disabled}
                />
            )
        case "timepicker": 
                    
            return (
                <div className={`forms-field ${inputClassName} ${disabled && 'disabled'}`} key={fieldKey}>
                    <p className="field__label">
                        {label ? (
                            <span className={errors ? "error" : ""}>{label}</span>
                        ) : (
                                ""
                            )}
                    </p>
                    <TimePicker 
                        allowClear={false}
                        value={value} 
                        onChange={(time) => { onChange(fieldKey, time) }} 
                        style={{width: "100%"}}
                        disabled={disabled}
                    /> 
                </div>
                
            )   
        default:
            return (
                <div className={`forms-field ${inputClassName} ${disabled && 'disabled'}`} key={fieldKey}>
                    {label && (
                        <p className="field__label">
                            <span className={errors ? "error" : ""}>{label}</span>
                        </p>
                    )}
                    <input
                        type="text"
                        value={value || ''}
                        name={fieldKey}
                        onChange={(e) => {
                            onChange(fieldKey, e.target.value, field);
                        }}
                        onBlur={(e) => {
                            if (typeof onBlur === "function") onBlur(fieldKey);
                        }}
                        className={`field__input ${errors ? "error" : ""}`}
                        disabled={disabled}
                    />
                </div>
            );
    }
};

export default Field;
