import React, { useEffect, useState, useRef } from 'react';
import { Modal } from 'antd';
import { composeSortParams } from "utils";
import { ToastsStore } from 'react-toasts';
import { useLocation, useNavigate } from 'react-router-dom';
import { AddFilled, Exit, Edit, Map, Reset } from "Icons";
import dayjs from 'lib/dayjs';
import DataGrid from '../../DataGrid';
import Button from "components/Button/Button";
import SearchInput from "elements/Input/Search";
import MappingList from './MappingList';
import useModal from "hooks/useModal";
import useUsers from "store/actions/usersActions";
import usePropertiesActions from 'store/actions/propertiesActions.js';
import usePropertyGroupActions from 'store/actions/propertyGroupActions.js';
import './Users.scss';
import useLoader from 'hooks/useLoader';
import usePermissions from 'hooks/usePermissions';
import IconButton from 'elements/Button/Icon';

const Users = () => {
    const { canAccess, canCreate, canUpdate, canDelete } = usePermissions('user');
    const location = useLocation();
    const navigate = useNavigate();

    const usersGridRef = useRef();
    const { getUsers, getUser, sendResetPasswordEmail, updateUserProfile } = useUsers();
    const { getProperties } = usePropertiesActions();
    const { getPropertyGroups } = usePropertyGroupActions();
    const { startLoading, doneLoading, loading } = useLoader();
    
    const [searchValue, setSearchValue] = useState();
    const [mappingList, setMappingList] = useState([]);

    const fetchUsers = (reset = false) => {
        const userName = (searchValue?? "") === ""? null: searchValue;
        const { sortVal, sortId, pageNo, pageLimit } = usersGridRef.current.gridInfo;
        startLoading();
        getUsers(userName, composeSortParams(sortVal, sortId), reset === true? 1: pageNo, pageLimit).then(result => {
            if (result.type === 'GET_USERS_SUCCESS') {
                const total = result.response.data.total_count;
                const roles = result.response.data.data?? [];
                usersGridRef.current.loadData(roles.map(role => ({ ...role, role_name: role.role_info?.name })), reset, total);
                doneLoading();
            } else {
                ToastsStore.error(result.error.response?.data?.message);
            }
        }).catch(error => {
            ToastsStore.error(error);
        });
    }

    useEffect(() => {
        fetchUsers(true);
    }, [searchValue]);

    useEffect(() => {
        startLoading();
        Promise.all([
            getProperties(null, 'name', null).then(result => result?.response?.data?.data?? []),
            getPropertyGroups(null, 'name', null).then(result => result.data.data?? [])
        ]).then(([properties, propertyGroups]) => {
            const newMappingList = [];
            Array.isArray(properties) && properties.forEach(item => newMappingList.push(getPropertyItem(item, 'PROPERTY')));
            Array.isArray(propertyGroups) && propertyGroups.forEach(item => newMappingList.push(getPropertyItem(item, 'PROPERTY_GROUP')));
            setMappingList(newMappingList);
        }).finally(() => {
            doneLoading();
        });
    }, [])

    const getPropertyItem = (data, type) => {
        return {
            id: data._id,
            name: data.name,
            code: type === 'PROPERTY'
                ? data.property_code
                : data.code,
            type: type
        }
    }

    const resetModal = useModal({
        id: {},
        name: {},
        email: { validations: { required: true } } 
    }, ({ values }) => {
        startLoading();
        sendResetPasswordEmail(values['email']).then(result => {
            resetModal.hide();
        }).catch(error => {
            console.error('PASSWORD RESET', error);
        }).finally(() => {
            doneLoading();
        });
    });
    
    const mapModal = useModal({
        id: {},
        properties: { default: [] }
    }, ({ values }) => {
        startLoading();
        const getPropertyIds = (items, type) => {
            return items
                .filter(item => item.type === type)
                .map(item => item.id)
        };
        updateUserProfile(values['id'], {
            properties: getPropertyIds(values['properties'], 'PROPERTY'),
            property_groups: getPropertyIds(values['properties'], 'PROPERTY_GROUP')
        }).then(result => {
            if (result.type === 'UPDATE_USER_SUCCESS') {
                ToastsStore.success(`User's properties were successfuly updated`);
                mapModal.hide();
            }
        }).catch(error => {
            ToastsStore.error(`Failed to update user's properties`);
            console.error(error);
        }).finally(() => {
            doneLoading();
        })
    });

    return (
        <div className="admin-user-management">
            <div style={{ width: '1000px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', height: '50px'}}>
                    <div className="add-user" style={{display: 'flex', alignItems: 'center' }}>
                        <h4 style={{ color: 'white', marginRight: '10px', alignContent: 'center', marginBottom: 0 }}>Add User</h4>
                        <IconButton type='add' hasAccess={canCreate()} action={() => {
                            navigate('/users/add', { state: { from: location.pathname }})
                        }} width='25px' />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', width: '250px' }}>
                        <SearchInput
                            handleSearch={val => setSearchValue(val)}
                            isLoading={loading}
                            placeholder="Search for a user"
                        />
                    </div>
                </div>
                
                <DataGrid
                    ref={usersGridRef}
                    isLoading={loading}
                    columns={[{
                        id: 'name',
                        header: 'NAME OF USER',
                        width: 'minmax(300px, 400px)',
                        enableSort: true,
                    }, {
                        id: 'role_info.name',
                        dataField: 'role_info',
                        header: 'ROLE',
                        width: 'minmax(300px, 400px)',
                        enableSort: true,
                        render: (val) => {
                            return val?.name;
                        }
                    }, {
                        id: 'last_login',
                        header: 'LAST LOGIN',
                        width: 'minmax(300px, 400px)',
                        enableSort: true,
                        sortDefault: true,
                        render: (val) => dayjs(val).isValid()? dayjs(val).format('MMM DD, YYYY'): null
                    }, {
                        id: 'actions',
                        header: 'ACTIONS',
                        type: 'custom_actions',
                        width: '70px',
                        actions: [{
                            render: (rowIdx) => {
                                const item = usersGridRef.current?.getRawData(rowIdx);
                                return <>
                                    <IconButton type='edit' hasAccess={canAccess() && canUpdate()} action={() => {
                                        navigate(`/users/${item._id}/edit`, { state: { from: location.pathname }});
                                    }} width='20px' />
                                    <IconButton type='view' hasAccess={canAccess() && !canUpdate()} action={() => {
                                        navigate(`/users/${item._id}/edit`, { state: { from: location.pathname }});
                                    }} width='20px' />
                                </>
                            }
                        }, {
                            render: (rowIdx) => {
                                if (!canUpdate()) return <></>
                                const item = usersGridRef.current?.getRawData(rowIdx);
                                return <Map width="20px" height="20px" className="pointer" onClick={() => {
                                    getUser(item._id).then(result => {
                                        const user = result.response.data.data;
                                        const properties = [
                                            ...(user.properties ?? []).map(item => getPropertyItem(item, 'PROPERTY')),
                                            ...(user.property_groups ?? []).map(item => getPropertyItem(item, 'PROPERTY_GROUP'))
                                        ];
                                        mapModal.clearData();
                                        mapModal.show({ data: {
                                            id: item._id, name: item.name, properties: properties
                                        }})
                                    });
                                }} />
                            }
                        }, {
                            render: (rowIdx) => {
                                if (!canUpdate()) return <></>
                                const item = usersGridRef.current?.getRawData(rowIdx);
                                return <Reset width="20px" height="20px" className="pointer" onClick={() => {
                                    resetModal.show({ data: {
                                        id: item._id, name: item.name, email: item.email
                                    }})
                                }} />
                            }
                        }]
                    }]} 
                    width="1000px"
                    gridEventHandler={ event => fetchUsers() }
                    pagination={ {
                        enabled: true,
                        limit: 5
                    } }
                />
            </div>
            <Modal open={resetModal.visible} width={500} maskClosable={false} footer={null}
                closeIcon={<Exit width="25px" height="25px" className="pointer" />}
                onCancel={() => resetModal.hide() }>

                <div className="rms-modal-content" style={{ paddingTop: '45px' }}>
                    <p>Send <strong>{resetModal.values['name']}</strong> a reset password link?</p>
                    <p>Recipient: <strong>{resetModal.values['email']}</strong></p>
                    <div className="modal-buttons">
                        <Button btnType="submit" type="primary" style={{ marginRight: '8px' }}
                            disabled={loading} onClick={() => { resetModal.handleSubmit() }}>
                            ADD
                        </Button>
                        <Button type="bg-light" onClick={() => resetModal.hide() }>
                            Cancel
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal open={mapModal.visible} width={700} maskClosable={false} footer={null}
                closeIcon={<Exit width="25px" height="25px" className="pointer" />}
                onCancel={() => mapModal.hide() } destroyOnClose={true}>

                <div className="rms-modal-content" style={{ paddingTop: '45px' }}>
                    <h3>Map Properties to {mapModal.values['name']}</h3>
                    <MappingList className='users-property-mapping' allItems={mappingList} selectedItems={mapModal.values['properties']} eventHandler={(e) => {
                        const newSelectedIds = mapModal.values['properties'].map(item => item.id);
                        if (e.action === 'add') {
                            e.value.forEach(item => newSelectedIds.push(item));
                            mapModal.handleValueChanged('properties', mappingList.filter(item => newSelectedIds.includes(item.id)));
                        }
                        if (e.action === 'delete') {
                            mapModal.handleValueChanged('properties', mappingList.filter(item => newSelectedIds.includes(item.id) && e.id !== item.id));
                        }
                    }} />
                    <div className="modal-buttons">
                        <Button type="bg-light" onClick={() => mapModal.hide()}>
                            Cancel
                        </Button>
                        <Button type="primary" onClick={() => mapModal.handleSubmit()}>
                            Submit
                        </Button>
                    </div>
                </div>
                
            </Modal>
        </div>
    )
}

export default React.memo(Users);