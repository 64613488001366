// import moment from "moment";
import dayjs from "dayjs";

import constants from "../../constants";
import { getSameDayOfYear, getPrevYear } from "../../utils";
import { areSelectedSegmentsInActiveProperty } from "../../utils/segments";
import { isRoomTypeInActiveProperty } from "../../utils/roomtypes";
import { getDefaultPropertyLatestAsOf } from "../../utils/timezones";

const ALLSEGMENTS = constants.ALLSEGMENTS;
const ALLROOMTYPES = constants.ALLROOMTYPES;

// Active Hotel should always contain the currently viewed property (individual or group).
const activeHotel = JSON.parse(localStorage.getItem("activeHotel")) || null;
const latest_asof = getDefaultPropertyLatestAsOf(activeHotel);

const initialState = {
    showDevLogsUI: process.env.REACT_APP_SHOWDEVLOGS || false,
    propertyInView: activeHotel,
    segmentsInView: { segments: [], segment_groups: [], is_global: null },
    hotelId: activeHotel ? activeHotel.group ? (activeHotel.properties??[]).map(_ => _._id) : activeHotel._id : "",
    hotelCode: activeHotel ? activeHotel.property_code : "",
    activeHotel: activeHotel ? activeHotel.name : "Select a Hotel",
    latest_as_of: activeHotel ? activeHotel.latest_as_of : dayjs().startOf('day'),
    isGroup: activeHotel ? activeHotel.group: false,
    currency: {},
    daysOut: "",
    chartData: {
        datasets: [],
        labels: [0, 1, 2, 3, 4, 5],
    },
    bgLoading: false,
    segments: {},
    segmentsTotal: true,
    segmentType: "bar",
    dowObject: {
        mon: true,
        tue: true,
        wed: true,
        thu: true,
        fri: true,
        sat: true,
        sun: true,
    },
    dateRanges: [],
    plotType: {
        average_occ: "bar",
        average_rate: "line",
        average_daily_rate: "unplotted",
        average_rev: "unplotted",
        total_occ: "bar",
        total_rev: "unplotted",
        pickup: "unplotted",
    },
    tutorial: {
        avatar: {
            showSpot: false,
            show: false,
            section: "dashboard",
        },
        search: {
            showSpot: false,
            show: false,
            section: "dashboard",
        },
        other: {
            showSpot: false,
            show: false,
            section: "dashboard",
        },
        filter: {
            showSpot: false,
            show: false,
            section: "overviewCalendar",
        },
        dataTypeView: {
            showSpot: false,
            show: false,
            section: "overviewCalendar",
        },
        filter1: {
            showSpot: false,
            show: false,
            section: "overviewCalendar",
        },
        filter2: {
            showSpot: false,
            show: false,
            section: "overviewCalendar",
        },
        filter3: {
            showSpot: false,
            show: false,
            section: "overviewCalendar",
        },
        hotelMenu: {
            showSpot: false,
            show: false,
            section: "overviewCalendar",
        },
        currentDate: {
            showSpot: false,
            show: false,
            section: "overviewCalendar",
        },
        currentDate2: {
            showSpot: false,
            show: false,
            section: "overviewCalendar",
        },
        currentDate3: {
            showSpot: false,
            show: false,
            section: "overviewCalendar",
        },
        pickupDate: {
            showSpot: false,
            show: false,
            section: "overviewCalendar",
        },
        paceDate: {
            showSpot: false,
            show: false,
            section: "overviewCalendar",
        },
        rateDate: {
            showSpot: false,
            show: false,
            section: "overviewCalendar",
        },
        weeklyCard: {
            showSpot: false,
            show: false,
            section: "overviewCalendar",
        },
        monthlyCard: {
            showSpot: false,
            show: false,
            section: "overviewCalendar",
        },
        table: {
            showSpot: false,
            show: false,
            section: "overviewCalendar",
        },
        uploadModal: {
            showSpot: false,
            show: false,
            section: "upload",
        },
        downloadTemplate: {
            showSpot: false,
            show: false,
            section: "upload",
        },
        sidepanel: {
            showSpot: false,
            show: false,
            section: "sidepanel",
        },
        chart: {
            showSpot: false,
            show: false,
            section: "sidepanel",
        },
        chart2: {
            showSpot: false,
            show: false,
            section: "sidepanel",
        },
        chart3: {
            showSpot: false,
            show: false,
            section: "sidepanel",
        },
        chart4: {
            showSpot: false,
            show: false,
            section: "sidepanel",
        },
        pickup: {
            showSpot: false,
            show: false,
            section: "sidepanel",
        },
        pickup2: {
            showSpot: false,
            show: false,
            section: "sidepanel",
        },
        pickup3: {
            showSpot: false,
            show: false,
            section: "sidepanel",
        },
        pace: {
            showSpot: false,
            show: false,
            section: "sidepanel",
        },
        pace2: {
            showSpot: false,
            show: false,
            section: "sidepanel",
        },
        pace3: {
            showSpot: false,
            show: false,
            section: "sidepanel",
        },
        sell_rate: {
            showSpot: false,
            show: false,
            section: "sidepanel",
        },
        sell_rate2: {
            showSpot: false,
            show: false,
            section: "sidepanel",
        },
        sell_rate3: {
            showSpot: false,
            show: false,
            section: "sidepanel",
        },
        otb: {
            showSpot: false,
            show: false,
            section: "sidepanel",
        }
    },
    notesModalState: { 
        visible: false,
        startDate: null,
        endDate: null
    },
    notesContents: [],
    showSidebar: false,
    sidebarData: null,
    comparisonData: null,
    activeCell: null,
    calendar_data: {
        as_of: latest_asof,
        check_in_start: dayjs().utc(true).startOf("month"),
        check_in_end: dayjs().utc(true).endOf("month").startOf("day"),
        pick_up: activeHotel?.default_pickup ? `-${activeHotel.default_pickup}` : "-7",
        pace: getPrevYear(latest_asof, 1, (activeHotel?.default_pace ?? "samedaylastyear") === "samedaylastyear"),
        // pace: getSameDayOfYear(dayjs().format("YYYY-MM-DD"), -1),
        pace_string: activeHotel?.default_pace ?? "samedaylastyear",
        segments: [ALLSEGMENTS],
        segments_string: ALLSEGMENTS,
        roomtype: ALLROOMTYPES,
        roomtype_string: ALLROOMTYPES,
        comparison: "NONE",
        comparison_data: {},
        selectedTab: "overview",
        valueToDisplay: "none",
    },
    calendar_cache: {
        daily: null,
        daily_filter: null,
        weekly: null,
        weekly_filter: null,
        monthly: null,
        monthly_filter: null,
    },
    activeCalendarView: activeHotel?.default_view ? activeHotel.default_view : "daily",
    calendarDailyValues: null,
    calendarWeeklyValues: null,
    appendToStartItem: null,
    calendarMonthlyValues: null,
    sidebarChartData: {
        datasets: [],
        labels: [0, 1, 2, 3, 4, 5],
    },
    sidebarChartDataIsLoading: false,
    isCalendarLoading: false,
    isCalendarSettingsWithChanges: false,
    activeViewType: "cells",
    activeMainData: "overview",
    weeklyData: null,
    monthlyData: null,
    ADRState: true,
    properties: [],
    fieldsError: {},
    users: [],
    uploadModalState: {
        visible: false,
        id: "",
        name: "",
        code: "",
    },
    paceType: "samedaylastyear",
    chartModal: {
        show: false,
        tab: 'bookingChart'
    },
    property: null,
    isSegmentFeaturesEnabled: false,
    isRoomTypeFeaturesEnabled: false,
    visualsAreDisplayed: false,
    visualsTablesAreDisplayed: true,
    visualsSelectedTab: "booking-chart",
    visualsBookingChartSelectedDaysOut: 30,
    visualsSegmentsData: {
        current: null,
        cache: []
    },
    visualsAsofsData: {
        current: null,
        cache: []
    },
    statisticsSegmentsData: {
        loading: false,
        current: null,
        cache: []
    },
    visualsWaterfallSorting: [],
    algorithmIsDisplayed: false,
    listItemInView: null,
    toolsSelectedTab: "algorithm",
    comparisons_enabled: false,
    comparisons: [],
    comparisons_options: [],
};


function reducer(state = initialState, action) {
    const { type, value, loading } = action;
    switch (type) {
        case constants.SET_ISLOADING:
            return {
                ...state,
                [loading]: value,
            };
        case constants.SET_CHART_DATA:
            return {
                ...state,
                chartData: value,
            };
        case constants.ADD_SEGMENT:
            return {
                ...state,
                segments: value,
            };
        case constants.UPDATE_SEGMENTS:
            return {
                ...state,
                segments: value,
            };
        case constants.UPDATE_SEGMENT_TYPE:
            return {
                ...state,
                segmentType: value,
            };
        case constants.ADD_SEGMENT_TO_GRAPH:
            return {
                ...state,
                chartData: {
                    ...state.chartData,
                    datasets: [...state.chartData.datasets, ...value],
                },
            };
        case constants.UPDATE_PLOT_TYPES:
            return {
                ...state,
                plotType: value,
            };
        case constants.UPDATE_DOW:
            return {
                ...state,
                dowObject: {
                    ...state.dowObject,
                    [value]: !state.dowObject[value],
                },
            };
        case constants.UPDATE_DAYS_OUT_VALUE:
            return {
                ...state,
                daysOut: value,
            };
        case constants.ADD_NEW_DATE_RANGE:
            return {
                ...state,
                dateRanges: [...state.dateRanges, value],
            };
        case constants.REMOVE_DATE_RANGE:
            return {
                ...state,
                dateRanges: value,
            };
        case constants.RESET_CHART_FORM:
            return {
                ...state,
                daysOut: "",
                chartData: {
                    datasets: [],
                    labels: [0, 1, 2, 3, 4, 5],
                },
                segments: {},
                segmentType: "bar",
                dowObject: {
                    mon: true,
                    tue: true,
                    wed: true,
                    thu: true,
                    fri: true,
                    sat: true,
                    sun: true,
                },
                dateRanges: [],
                plotType: {
                    average_occ: "bar",
                    average_rate: "line",
                    average_daily_rate: "unplotted",
                    average_rev: "unplotted",
                    pickup: "unplotted",
                },
            };
        case constants.UPDATE_SEGMENTS_TOTALS:
            return {
                ...state,
                segmentsTotal: value,
            };
        case constants.UPDATE_SELECTED_CALENDAR_CELL:
            return {
                ...state,
                activeCell: value,
            };
        case constants.TOGGLE_SIDEBAR:
            return {
                ...state,
                showSidebar: value,
            };
        case constants.UPDATE_SIDEBAR_CONTENT:
            return {
                ...state,
                sidebarData: value,
            };
        case constants.UPDATE_COMPARISON_CONTENT:
            return {
                ...state,
                comparisonData: value,
            };
        case constants.UPDATE_CALENDAR_SETTINGS:
            return {
                ...state,
                calendar_data: { ...value }
            };
        case constants.UPDATE_CALENDAR_CACHE:
            return {
                ...state,
                calendar_cache: value,
            };
        case constants.SET_SIDEBAR_CHART_DATA:
            return {
                ...state,
                sidebarChartData: value,
            };
        case constants.UPDATE_CALENDAR_DAILY:
            return {
                ...state,
                calendarDailyValues: value,
            };
        case constants.UPDATE_CALENDAR_WEEKLY:
            return {
                ...state,
                calendarWeeklyValues: value,
            };
        case constants.APPEND_END_CALENDAR_WEEKLY:
            return {
                ...state,
                calendarWeeklyValues: [...state.calendarWeeklyValues, ...value],
            };
        case constants.APPEND_START_CALENDAR_WEEKLY:
            return {
                ...state,
                appendToStartItemWeekly: value,
            };
        case constants.APPEND_START_CALENDAR_MONTHLY:
            return {
                ...state,
                appendToStartItemMonthly: value,
            };
        case constants.UPDATE_CALENDAR_MONTHLY:
            return {
                ...state,
                calendarMonthlyValues: value,
            };
        case constants.APPEND_CALENDAR_MONTHLY:
            return {
                ...state,
                calendarMonthlyValues: [...state.calendarMonthlyValues, ...value],
            };
        case constants.SET_CALENDAR_VIEW:
            return {
                ...state,
                activeCalendarView: value,
            };
        case constants.SET_CALENDAR_LOADING:
            return {
                ...state,
                isCalendarLoading: value,
            };
        case constants.SET_CALENDAR_WITH_CHANGES:
            return {
                ...state,
                isCalendarSettingsWithChanges: value,
            };
        case constants.TOGGLE_CALENDAR_TABLE_VIEW:
            return {
                ...state,
                activeViewType: value,
            };
        case constants.CHANGE_SHOWN_MAIN_DATA:
            return {
                ...state,
                activeMainData: value,
            };

        case constants.STORE_WEEKLY_DATA:
            return {
                ...state,
                weeklyData: value,
            };
        case constants.STORE_MONTHLY_DATA:
            return {
                ...state,
                monthlyData: value,
            };
        case constants.TOGGLE_ADR_STATE:
            return {
                ...state,
                ADRState: value,
            };

        case constants.GET_PROPERTIES:
            return {
                ...state,
                properties: value.data,
            };

        case constants.ADD_PROPERTIES:
            return {
                ...state,
                properties: [...state.properties, value],
            };
        case constants.GET_USERS:
            return {
                ...state,
                users: value.data,
            };
        case constants.UPDATE_ACTIVE_HOTEL:
            const isGroup = value.group ?? false;
            const activeProperty = value;
            const prevActiveHotel = JSON.parse(localStorage.getItem("activeHotel"));
            const notifications = JSON.parse(localStorage.getItem("notifications") ?? '{}');

            const activeHotel = JSON.stringify(activeProperty);
            localStorage.setItem("activeHotel", activeHotel);

            const selectedSegments = state.calendar_data.segments;
            const displayedSegments = state.calendar_data.segments_string;
            const areSegmentsInActiveProperty = areSelectedSegmentsInActiveProperty(selectedSegments, activeProperty);

            const selectedRoomType = state.calendar_data.roomtype;
            const displayedRoomType = state.calendar_data.roomtype_string;
            const asOf = getDefaultPropertyLatestAsOf(activeProperty);
            const pace = getPrevYear(asOf, 1, (activeProperty?.default_pace ?? "samedaylastyear") === "samedaylastyear");
            
            if (prevActiveHotel && `${prevActiveHotel._id}` !== `${activeProperty?._id}`) {
                Object.keys(notifications)
                    .filter(key => key.includes(prevActiveHotel.property_code + '_'))
                    .forEach(key => delete notifications[key]);
                localStorage.setItem('notifications', JSON.stringify(notifications))
            }

            return {
                ...state,
                propertyInView: activeProperty,
                activeHotel: activeProperty.name,
                hotelCode: isGroup ? activeProperty._id : activeProperty.property_code,
                hotelId: isGroup ? (activeProperty.properties?? []).map(_ => _._id) : activeProperty._id,
                isGroup: isGroup,
                latest_as_of: activeProperty.latest_as_of,
                currency: activeProperty.currency,
                calendar_data: {
                    ...state.calendar_data,
                    as_of: asOf,
                    pace: pace,
                    // pace: getSameDayOfYear(dayjs(activeProperty.latest_as_of), -1),
                    segments: areSegmentsInActiveProperty ? selectedSegments : [ALLSEGMENTS],
                    segments_string: areSegmentsInActiveProperty ? displayedSegments : ALLSEGMENTS,
                    roomtype: isRoomTypeInActiveProperty(selectedRoomType, activeProperty) ? selectedRoomType : ALLROOMTYPES,
                    roomtype_string: isRoomTypeInActiveProperty(selectedRoomType, activeProperty) ? displayedRoomType : ALLROOMTYPES,
                    comparison: "NONE",
                    comparison_data: {},
                    pick_up: `-${activeProperty.default_pickup}`,
                    pace_string: activeProperty?.default_pace ?? "samedaylastyear",
                },
                activeCalendarView: activeProperty.default_view,
            };
        case constants.UPDATE_ACTIVE_HOTEL_CURRENCY:
            return {
                ...state,
                currency: value,
            };
        case constants.SET_NOTES_MODAL:
            return {
                ...state,
                notesModalState: { 
                    ...state.notesModalState, 
                    ...value 
                },
            };
        case constants.SET_UPLOAD_MODAL:
            return {
                ...state,
                uploadModalState: value,
            };

        case constants.TOGGLE_TUTORIAL:
            const newToggleTutorialState = {
                ...state,
            };
            newToggleTutorialState.tutorial[value.type] = value.payload;

            return newToggleTutorialState;
        case constants.TOGGLE_TUTORIAL_SECTION:
            const newToggleTutorialSectionState = {
                ...state,
            };

            Object.keys(newToggleTutorialSectionState.tutorial).map((key) => {
                if (newToggleTutorialSectionState.tutorial[key].section === value.section)
                    newToggleTutorialSectionState.tutorial[key].showSpot = value.payload;
            });

            return newToggleTutorialSectionState;
        case constants.UPDATE_PACE_TYPE:
            return {
                ...state,
                paceType: value,
            };
        case constants.TOGGLE_CHART_MODAL_SECTION:
            return {
                ...state,
                chartModal: value,
            };
        case constants.UPDATE_PROPERTY_DATA:
            return {
                ...state,
                property: value,
            };
        case constants.SHOW_VISUALS:
            return {
                ...state,
                visualsAreDisplayed: value,
            };
        case constants.SHOW_VISUALS_TABLES:
            return {
                ...state,
                visualsTablesAreDisplayed: value,
            };
        case constants.UPDATE_VISUALS_SELECTED_TAB:
            return {
                ...state,
                visualsSelectedTab: value,
            };
        case constants.UPDATE_VISUALS_SEGMENTS_DATA:
            return {
                ...state,
                visualsSegmentsData: value,
            };
        case constants.UPDATE_STATISTICS_SEGMENTS_DATA:
            return {
                ...state,
                statisticsSegmentsData: value,
            };
        case constants.UPDATE_VISUALS_BOOKING_CHART_SELECTED_DAYS_OUT:
            return {
                ...state,
                visualsBookingChartSelectedDaysOut: value,
            };
        case constants.UPDATE_VISUALS_ASOFS_DATA:
            return {
                ...state,
                visualsAsofsData: value,
            };
        case constants.UPDATE_VISUALS_WATERFALL_SORTING:
            return {
                ...state,
                visualsWaterfallSorting: value,
            };
        case constants.SHOW_SEGMENTS_FEATURES:
            return {
                ...state,
                isSegmentFeaturesEnabled: value,
            };
        case constants.SHOW_ROOMTYPE_FEATURES:
            return {
                ...state,
                isRoomTypeFeaturesEnabled: value,
            };
        case constants.SHOW_ALGORITHM:
            return {
                ...state,
                algorithmIsDisplayed: value,
            };
        case constants.UPDATE_PROPERTY_IN_VIEW:
            return {
                ...state,
                propertyInView: value,
            };
        case constants.UPDATE_SEGMENTS_IN_VIEW:
            return {
                ...state,
                segmentsInView: { 
                    segments: value?.segments, 
                    segment_groups: value?.segment_groups,
                    is_global: value?.is_global
                },
            }
        case constants.UPDATE_LIST_ITEM_IN_VIEW:
            return {
                ...state,
                listItemInView: value,
            };
        case constants.UPDATE_TOOLS_SELECTED_TAB:
            return {
                ...state,
                toolsSelectedTab: value,
            };
        case constants.UPDATE_COMPARISONS_ENABLED:
            return {
                ...state,
                comparisons_enabled: value
            };
        case constants.UPDATE_COMPARISONS_LATEST:
            return {
                ...state,
                comparisons: value
            };
        case constants.UPDATE_COMPARISONS_OPTIONS:
            return {
                ...state,
                comparisons_options: value
            };

        default:
            return state;
    }
}

export default reducer;
