import React from "react";
import Switch from "components/Switch/Switch";


/**
 * A basic switch element.
 *
 * @param {String} label Switch label.
 * @param {Object} checked Whether the switch is turned on or off.
 * @param {Function} action Action to happen after clicking the switch.
 * @return {Component} A basic switch element.
*/
const BasicSwitch = ({ label, checked, action = null, type = "default", disabled = false }) => {
    let gridTemplateColumns = "50% 50%";
    if (type !== "default") gridTemplateColumns = type;
    if (label === null) gridTemplateColumns = "100%";
    
    return (
        <div className="light-input-container">
            <div className="light-label">
                <div style={{ display: "grid", gridTemplateColumns: gridTemplateColumns }}>
                    { label && <span>{label}</span> }
                    <Switch checked={checked} disabled={disabled} onChange={action} />
                </div>
            </div>
        </div>
    )
};

export default BasicSwitch;
