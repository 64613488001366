import React from "react";
import { useSelector } from "react-redux";
import { Helmet } from 'react-helmet';

import Daily from "./Daily/Daily";
import Custom from "./Custom/Custom";
import Aggregate from "./Aggregate/Aggregate";
import CalendarFilter from "./Filter";
import DashboardModal from '../DashboardModal/DashboardModal';
import AlgorithmModal from '../Tools/Algorithm/Modal';
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import useCalendar from "./useCalendar";
import usePermissions from "hooks/usePermissions";



export default function Calendar({ toggleSidebar }) {
    const { canAccess: canAccessExport } = usePermissions('property', 'algorithm_modal');
    const state = useSelector((state) => state.mainReducer);
    const { toggleCell } = useCalendar();

    const containerStyles = {
        minWidth: '1089px',
        maxWidth: '1292px',
        width: state.showSidebar && window.innerWidth <= 1900 ? "1089px" : "1292px",
        WebkitTransition: "width 300ms",
        msTransition: "width 300ms",
        overflow: 'hidden'
    };

    const showDaily = state.activeCalendarView === "daily";
    const showWeekly = state.activeCalendarView === "weekly";
    const showMonthly = state.activeCalendarView === "monthly";
    const showCustom = state.activeCalendarView === "custom";

    return (
        <div className="calendar">
            {/* Page Title */}
            <Helmet><title>Rev&You | Dashboard</title></Helmet>

            {/* Loading Spinner */}
            {state.isCalendarLoading && (
                <LoadingSpinner size={"50px"} mask={true} offSetTop={"50px"} />
            )}

            {/* User Filters */}
            <div className="container centered bg-white">
                <div className="row" style={containerStyles}>
                    <div className="calendar-view-settings">
                        <CalendarFilter />
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <div id="calendar" className="calendar-content container centered">
                {/* Visuals Modal */}
                <DashboardModal visible={state.chartModal.show} tab={state.chartModal.tab} />

                {/* Algorithm Modal */}
                {canAccessExport() && <AlgorithmModal visible={state.algorithmIsDisplayed} />}

                {/* Calendar */}
                <div className="row" style={{ ...containerStyles, display: state.chartModal.show ? 'none' : 'flex' }} >
                    {showDaily && <Daily toggleCell={toggleCell} />}
                    {showCustom && <Custom toggleCell={toggleCell} />}
                    {showWeekly && <Aggregate type="week" toggleCell={toggleCell} />}
                    {showMonthly && <Aggregate type="month" toggleCell={toggleCell} />}
                </div>
            </div>
        </div>
    );
}
