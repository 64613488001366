import React from 'react';
import { Select } from 'antd';
import OutlinedIcon from 'elements/Icon/OutlinedIcon';
import FilledIcon from 'elements/Icon/FilledIcon';

const SearchBar = ({ 

    mode = 'multiple', 
    value,
    options, 
    optionRender,
    maxTagCount,
    placeholder = "", 
    suffixIcon = null,
    dropdownClassName = 'search-bar-options-double-span',
    removeIcon = <FilledIcon type='close-circle' />,
    defaultActiveFirstOption = false,
    notFoundContent,
    handleFilter = true, 
    optionLabelProp = 'label', 
    allowClear = false,
    showSearch = true,
    eventHandler = null,
    onChange,
    onAdd,
    disabled = false
}) => {

    return (
        <div className="search-bar">
            <div className="search-bar-input">
                <OutlinedIcon
                    style={{
                        fontSize: "16px",
                        height: "16px",
                        padding: "0 8px 0 8px",
                    }}
                    type="search"
                />
                <Select 
                    disabled={disabled}
                    mode={mode} 
                    value={value}
                    options={options}
                    optionRender={optionRender}
                    placeholder={placeholder} 
                    maxTagCount={maxTagCount}
                    style={{width: "100%"}}
                    suffixIcon={suffixIcon}
                    popupClassName={dropdownClassName}
                    removeIcon={removeIcon}
                    defaultActiveFirstOption={defaultActiveFirstOption}
                    notFoundContent={notFoundContent}
                    filterOption={handleFilter} 
                    optionLabelProp={optionLabelProp} 
                    allowClear={allowClear}
                    onChange={(value, option) => {
                        if (typeof onChange === 'function') {
                            onChange(value, option);
                        }

                        const event = { action: 'change', value: value };
                        typeof eventHandler == "function" && eventHandler(event);
                    }}
                    showSearch={showSearch}
                />
            </div>
            <button disabled={!(value.length > 0)} onClick={() => {
                if (typeof onAdd === 'function') {
                    onAdd(value, options.filter(option => value.includes(option.value)));
                }
                
                const event = { action: 'add', value: value };
                typeof eventHandler == "function" && eventHandler(event);
            }}>Add</button>
        </div>
    );
}

export default SearchBar;