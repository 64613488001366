import React from "react";
import { Modal } from "antd";

import { Exit } from "Icons";
import Button from "../Button/Button";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

import useUsersActions from "../../store/actions/usersActions";



const UserMapping = ({ state, values, handleChange, show }) => {
    const { getUsers } = useUsersActions();
    const [showModal, setShowModal] = React.useState(false);

    React.useEffect(() => {
        getUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleModal = () => {
        setShowModal(!showModal);
    };

    const handleSaveMappedUsers = () => {
        console.log("saving");
    };

    return (
        <div className="forms-section" style={{ display: show ? 'block' : 'none' }}>
            <h3>Map Users to Property</h3>

            {state.users.loading && <LoadingSpinner size={"20px"} />}
            <table className="forms-table">
                <thead>
                    <tr style={{ marginBottom: '8px' }}>
                        <th>Name</th>
                        <th>Email</th>
                    </tr>
                </thead>
                <tbody>
                    {values.users.length ? (
                        values.users.map((user, index) => {
                            if (index < 5) {
                                return (
                                    <tr key={user._id}>
                                        <td>{user.name}</td>
                                        <td>{user.email}</td>
                                    </tr>
                                );
                            }
                        })
                    ) : (
                            <tr>
                                <td>No users Found</td>
                            </tr>
                        )}
                </tbody>
            </table>

            <div>
                {values.users.length > 5 ? (
                    <Button type="primary" onClick={handleModal}>
                        See All
                    </Button>
                ) : (
                        ""
                    )}
            </div>

            <Modal
                open={showModal}
                onCancel={handleModal}
                closeIcon={<Exit width="25px" height="25px" className="pointer" />}
                width={1000}
                footer={null}
                className="rms-modal"
            >
                <div className="rms-modal-content">
                    <div className="rms-modal-header">
                        <h3>Map Users to property</h3>
                    </div>
                    <div className="list-wrapper">
                        <div className="list-body">
                            {state.users.length &&
                                state.users.map((user) => {
                                    return (
                                        <div key={user._id} className="list-item">
                                            <span>{user.name}</span>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>

                    <div className="modal-buttons">
                        <Button key="back" onClick={handleModal}>
                            Cancel
                        </Button>
                        <Button key="submit" onClick={handleSaveMappedUsers}>
                            Save
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default UserMapping;
