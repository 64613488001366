import React, { useState, useEffect, createContext } from "react";
import { useSelector } from "react-redux";
import constants from "../../constants";
import useHelperFunctions from "store/actions/actions";
import { useAuth0 } from "../../react-auth0-spa";
import { Modal, Upload, Radio, message, Menu, Dropdown } from "antd";
import { Exit } from "Icons";
import ActionButton from "elements/Button/Action";
import CustomTabs from '../CustomTabs/CustomTabs';
import usePermissions from "hooks/usePermissions";

const EXPEDIA_RATES_VERSION_4 = "expedia_v4";
const EXPEDIA_RATES_VERSION_3 = "expedia_v3";
const EXPEDIA_RATES_VERSION_2 = "expedia_v2"; // This is the default (daily).
const EXPEDIA_RATES_VERSION_1 = "expedia_v1";

const { Dragger } = Upload;

export default function UploadModal() {
    const { list: userPermissions, canAccess, isAllowed } = usePermissions('property', 'upload');
    const state = useSelector((state) => {
        return { ...state.mainReducer, currentSearchTerm: state.properties.currentSearchTerm };
    });
    const { setShowUploadModal } = useHelperFunctions();
    const { getTokenSilently, isAuthenticated } = useAuth0();
    const [token, setToken] = useState("");
    const [reservationFileType, setReservationFileType] = useState("daily");
    const [rateFileType, setRateFileType] = useState("daily");

    const [confirmState, setConfirmState] = useState({
        message: "",
        visible: false,
        proceed: null,
        cancel: null
    });
    //Get token for file upload
    useEffect(() => {
        async function getToken() {
            const token = await getTokenSilently();
            setToken(token);
        }
        if (isAuthenticated) {
            getToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);
    
    const defaultDraggerProps = {
        name: "file",
        multiple: false,
        showUploadList: {
            showPreviewIcon: false,
            showDownloadIcon: false,
            showRemoveIcon: false,
        },
        headers: {
            Authorization: `Bearer ${token}`
        },
        beforeUpload: (file) => {
            return new Promise(async (resolve, reject) => {
                await new Promise((resolve, reject) => {
                    setConfirmState({
                        ...confirmState,
                        message: <span>Do you want to proceed uploading the file <b>{file.name}</b> <br/>to <b>{state.uploadModalState.name}</b>?</span>,
                        visible: true,
                        proceed: resolve,
                        cancel: reject
                    });
                }).then(() => {
                    console.log("SUCCESS", confirmState);
                    resolve(true);
                }).catch(() => {
                    console.log("FAIL", confirmState);
                    reject();
                }).finally(() => {
                    console.log(confirmState);
                    setConfirmState({ ...confirmState, visible: false })
                })
            })
        }
    };

    const reservationDraggerProps = {
        ...defaultDraggerProps,
        action: `${constants.BASE_URL_2}/import/upload/${state.uploadModalState.id}/reservations`,
        async onChange(info) {
            const { status } = info.file;
            if (status !== "uploading") {
                console.log(info.file, info.fileList);
            }
            if (status === "done") {
                message.success(`${info.file.name} file uploaded successfully.`);
                // let localStoragePropertyAsOfModal = JSON.parse(localStorage.getItem("propertiesLatestAsOfModal"))
                // localStoragePropertyAsOfModal[state.uploadModalState.code] = true;
                // localStorage.setItem('propertiesLatestAsOfModal', JSON.stringify(localStoragePropertyAsOfModal))
            } else if (status === "error") {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    }

    const segmentDraggerProps = {
        ...defaultDraggerProps,
        action: `${constants.BASE_URL_2}/import/upload/${state.uploadModalState.id}/segments`,
        async onChange(info) {
            const { status } = info.file;
            if (status !== "uploading") {
                console.log(info.file, info.fileList);
            }
            if (status === "done") {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === "error") {
                message.error(`${info.file.name} file upload failed.`);
            }
        }
    }

    const ratesDraggerProps = {
        ...defaultDraggerProps,
        action: `${constants.BASE_URL_2}/import/upload/${state.uploadModalState.id}/rates`,
        async onChange(info) {
            const { status } = info.file;

            if (status !== "uploading") {
                console.log(info.file, info.fileList);
            }
            if (status === "done") {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === "error") {
                message.error(`${info.file.name} file upload failed.`);
            }
        }
    }

    const notesDraggerProps = {
        ...defaultDraggerProps,
        action: `${constants.BASE_URL_2}/import/upload/${state.uploadModalState.id}/notes`,
        async onChange(info) {
            const { status } = info.file;

            if (status !== "uploading") {
                console.log(info.file, info.fileList);
            }
            if (status === "done") {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === "error") {
                message.error(`${info.file.name} file upload failed.`);
            }
        }
    }

    const handleCancelUpload = () => {
        setShowUploadModal({ ...state.uploadModalState, visible: false });
    };

    const tabs = [{ 
        key: 'reservations', label: 'RESERVATIONS', hide: !canAccess('reservations')
    }, { 
        key: 'segments', label: 'SEGMENTS', hide: !canAccess('segments') 
    }, {
        key: 'rates', label: 'RATES', hide: !canAccess('rates')
    }, {
        key: 'events', label: 'EVENTS', hide: !canAccess('events')
    }];

    const getStatsUploadText = () => {
        if (reservationFileType == "hotel_stats") return "Hotel Stats";
        if (reservationFileType == "jinjiang_stats") return "Jinjiang Stats";
        return "";
    }

    const getRatesUploadText = () => {
        switch(rateFileType) {
            case EXPEDIA_RATES_VERSION_4: return "Expedia V4";
            case EXPEDIA_RATES_VERSION_3: return "Expedia V3";
            case EXPEDIA_RATES_VERSION_2: return "Expedia V2";
            case EXPEDIA_RATES_VERSION_1: return "Expedia V1"; 
            default: return "";
        }
    }

    return (
        <>
            <Modal
                title={null}
                open={state.uploadModalState.visible && isAllowed('ACCESS_PROPERTY_UPLOAD', false)}
                onCancel={handleCancelUpload}
                closeIcon={<Exit width="25px" height="25px" className="pointer" />}
                footer={false}
                width={650}
            >
                <div className="rms-modal-content">
                    <div className="rms-modal-header">
                        <h3 id="uploadModalTutorial" style={{ position: "relative" }}>
                            Upload Property Data
                        </h3>
                    </div>
                    <CustomTabs name="uploadModalTabs" className="upload-modal" tabs={tabs}>
                        <CustomTabs.Tab key="reservations">
                            {state.uploadModalState.parser_code != "excel" && <div>
                                <Dragger {...reservationDraggerProps }>
                                    <p className="ant-upload-text">
                                        <span id="upload-click-here">Click here</span> or drag file to this area to upload reservations data for <br/>
                                        <span id="upload-prop-name">{state.uploadModalState.name}</span>
                                    </p>
                                </Dragger>
                            </div> }
                            {state.uploadModalState.parser_code == "excel" && <div>
                                <div className="parser-upload-options">
                                    <button className={`upload-option ${reservationFileType=="daily"? "upload-option-active": ""}`} onClick={() => setReservationFileType("daily")}>
                                        Daily</button>
                                    <button className={`upload-option ${reservationFileType=="bulk"? "upload-option-active": ""}`} onClick={() => setReservationFileType("bulk")}>
                                        Bulk</button>
                                    <Dropdown className={`upload-option ${["hotel_stats", "jinjiang_stats"].includes(reservationFileType)? "upload-option-active": ""}`} trigger={["hover"]} overlay={
                                        <Menu selectedKeys={reservationFileType} className="upload-option-others" mode="vertical">
                                            <Menu.Item key="hotel_stats" onClick={() => setReservationFileType("hotel_stats")}>
                                                Hotel Stats
                                            </Menu.Item>
                                            <Menu.Item key="jinjiang_stats" onClick={() => setReservationFileType("jinjiang_stats")}>
                                                Jinjiang Stats
                                            </Menu.Item>
                                        </Menu>
                                    }>
                                        <button>Others</button>
                                    </Dropdown>
                                </div>
                                <Dragger {...{ ...reservationDraggerProps, data: { reservation_file_type: reservationFileType } } }>
                                    <p className="ant-upload-text">
                                        {["hotel_stats", "jinjiang_stats"].includes(reservationFileType)
                                        ? <><span id="upload-click-here">Click here</span> or drag file ({getStatsUploadText()}) to this area to upload reservations data for <br/></>
                                        : <><span id="upload-click-here">Click here</span> or drag file to this area to upload reservations data for <br/></>
                                        }
                                        <span id="upload-prop-name">{state.uploadModalState.name}</span>
                                    </p>
                                </Dragger>
                            </div>}
                        </CustomTabs.Tab>
                        <CustomTabs.Tab key="segments">
                            <div>
                                <Dragger  {...segmentDraggerProps}>
                                    <p className="ant-upload-text">
                                        <span id="upload-click-here">Click here</span> or drag file to this area to upload segments data for <br/>
                                        <span id="upload-prop-name">{state.uploadModalState.name}</span>
                                    </p>
                                </Dragger>
                            </div>
                        </CustomTabs.Tab>
                        <CustomTabs.Tab key="rates">
                            {state.uploadModalState.parser_code === "integrationpartner" && <div>
                                <Dragger  {...ratesDraggerProps}>
                                    <p className="ant-upload-text">
                                        <span id="upload-click-here">Click here</span> or drag file to this area to upload rates data for <br/>
                                        <span id="upload-prop-name">{state.uploadModalState.name}</span>
                                    </p>
                                </Dragger>
                            </div>}

                            {state.uploadModalState.parser_code !== "integrationpartner" && <div>
                                <div className="parser-upload-options">
                                    <button className={`upload-option ${rateFileType=="daily"? "upload-option-active": ""}`} onClick={() => setRateFileType("daily")}>
                                        Daily</button>
                                    <button className={`upload-option ${rateFileType=="bulk"? "upload-option-active": ""}`} onClick={() => setRateFileType("bulk")}>
                                        Bulk</button>
                                    <Dropdown className={`upload-option ${[EXPEDIA_RATES_VERSION_1, EXPEDIA_RATES_VERSION_2, EXPEDIA_RATES_VERSION_3, EXPEDIA_RATES_VERSION_4].includes(rateFileType)? "upload-option-active": ""}`} trigger={["hover"]} overlay={
                                        <Menu selectedKeys={rateFileType} className="upload-option-others" mode="vertical">
                                            <Menu.Item key={EXPEDIA_RATES_VERSION_3} onClick={() => setRateFileType(EXPEDIA_RATES_VERSION_3)}>
                                                Expedia V3</Menu.Item>
                                            <Menu.Item key={EXPEDIA_RATES_VERSION_2} onClick={() => setRateFileType(EXPEDIA_RATES_VERSION_2)}>
                                                Expedia V2</Menu.Item>
                                            <Menu.Item key={EXPEDIA_RATES_VERSION_1} onClick={() => setRateFileType(EXPEDIA_RATES_VERSION_1)}>
                                                Expedia V1</Menu.Item>
                                        </Menu>
                                    }>
                                        <button>Others</button>
                                    </Dropdown>
                                </div>
                                <Dragger  {...{ ...ratesDraggerProps, data: { rate_file_type: rateFileType } } }>
                                    <p className="ant-upload-text">
                                        {[EXPEDIA_RATES_VERSION_1, EXPEDIA_RATES_VERSION_2, EXPEDIA_RATES_VERSION_3, EXPEDIA_RATES_VERSION_4].includes(rateFileType)
                                        ? <><span id="upload-click-here">Click here</span> or drag file ({getRatesUploadText()}) to this area to upload<br/> rates data for <br/></>
                                        : <><span id="upload-click-here">Click here</span> or drag file to this area to upload rates data for <br/></>
                                        }
                                        <span id="upload-prop-name">{state.uploadModalState.name}</span>
                                    </p>
                                </Dragger>
                            </div>}
                        </CustomTabs.Tab>
                        <CustomTabs.Tab key="events">
                            <div>
                                <Dragger  {...notesDraggerProps}>
                                    <p className="ant-upload-text">
                                        <span id="upload-click-here">Click here</span> or drag file to this area to upload events data for <br/>
                                        <span id="upload-prop-name">{state.uploadModalState.name}</span>
                                    </p>
                                </Dragger>
                            </div>
                        </CustomTabs.Tab>
                    </CustomTabs>
                </div>

                
            </Modal>
            <Modal
                title={null}
                open={confirmState.visible}
                onCancel={confirmState.cancel}
                closeIcon={<Exit width="25px" height="25px" className="pointer" />}
                footer={false}
                >
                <div className="rms-modal-content">
                    <div className="rms-modal-header"><h3>Confirm Upload</h3></div>
                    <p style={{ textAlign: "center"}}>{confirmState.message}</p>

                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                        <ActionButton type="cancel" action={confirmState.cancel} text="No"></ActionButton>
                        <span style={{width: "24px"}}/>
                        <ActionButton type="submit" action={confirmState.proceed} text="Yes"></ActionButton>
                    </div>
                </div>
            </Modal>
        </>
    );
}
